import { ButtonCurrencyType } from './components/button';
import React from 'react';
import {
    Box,
    CircularProgress,
    Divider,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useGlobal } from '@hooks/global';
import { useNavigate } from 'react-router-dom';
import { StoreType } from '@types';
import { sx } from './sx';
import { useLoad } from '@hooks/load';
import { useLayout } from '@hooks/layout';
const CurrencyType: React.FC = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette } = useTheme();
    const { servicesLoad } = useLoad();
    const global = useGlobal();
    const layout = useLayout();
    const navigate = useNavigate();
    const { css } = sx();
    const applicationType = global.app.view.application;

    const setTypeParams = async (type: StoreType) => {
        global.app.view.set(type);
        layout.layout.loading.handle(true);
        await servicesLoad({
            // eslint-disable-next-line max-len
            loadList: ['cart', 'favorites', 'layoutBannersCarrousel', 'popularAds', 'adNews'],
            forceLoad: ['cart', 'favorites'],
            application: {
                isLoaded: true,
                type: type,
            },
        });
        layout.layout.loading.handle(false);
        if (!matches) {
            navigate(-1);
        }
    };

    return (
        <React.Fragment>
            {(!applicationType.isLoaded || (!matches && layout.layout.loading.value)) && (
                <Box sx={css.overlay}>
                    <CircularProgress
                        size={22}
                        sx={{ color: palette.darkGray }}
                    />
                </Box>
            )}

            <ButtonCurrencyType
                title='Ganhar Pontos'
                text='Pague com o cartão de crédito ou outro método de
            pagamento em dinheiro e acumule pontos.'
                onClick={() => setTypeParams('SHOPPING')}
                disabled={!!(applicationType.type === 'SHOPPING')}
            />
            <Divider light />
            <ButtonCurrencyType
                title='Usar Pontos'
                text='Use seus pontos acumulados'
                onClick={() => setTypeParams('REDEMPTION')}
                disabled={!!(applicationType.type === 'REDEMPTION')}
            />
        </React.Fragment>
    );
};

export { CurrencyType };
