import { useUser } from '@hooks/user';
import { BankAccountProps, PixProps } from '@interfaces/Services';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { WithdrawalTypeListProps } from '@interfaces/index';

export const WithdrawalTypeList = ({
    type,
}: WithdrawalTypeListProps) => {
    const { wallet } = useUser();

    const { css } = sx();

    return (
        <Box
            sx={{ ...css.box.wrapper }}
            py={2}>
            <Box sx={{ ...css.box.icon }}>
                {type === 'BANK_ACCOUNT' ?
                    <I icon={['far', 'building-columns']} /> :
                    <I icon={['fab', 'pix']} />
                }
            </Box>
            <Stack
                mr={'0.625rem'}
                width={'100%'}
                direction={'row'}
                justifyContent={'space-between'}>
                <Stack>
                    <Box display={'flex'}>
                        <Box sx={{ ...css.box.hightlight }}>
                            <Typography sx={{ ...css.texts.title }}>
                                {type === 'BANK_ACCOUNT' ?
                                    wallet.withdrawal.bank.list.bank :
                                    'Chave Pix'}
                            </Typography>
                        </Box>
                    </Box>
                    {type === 'BANK_ACCOUNT' ?
                        <>
                            <Box>
                                <Typography
                                    sx={{ ...css.texts.list }}
                                    variant='body1'>
                                    <span>Agência:</span>
                                    {wallet.withdrawal.bank.list.agency}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{ ...css.texts.list }}
                                    variant='body1'>
                                    <span>Conta:</span>
                                    {wallet.withdrawal.bank.list.account}
                                </Typography>
                            </Box>
                        </> :
                        <Box>
                            <Typography
                                sx={{ ...css.texts.list }}
                                variant='body1'>
                                <span>{wallet.withdrawal.pix.list.keyType}:</span> {' '}
                                {wallet.withdrawal.pix.list.key}
                            </Typography>
                        </Box>

                    }
                </Stack>
                <IconButton
                    sx={{ ...css.buttonEdit }}
                    onClick={() => {
                        wallet.withdrawal.bank.handle({} as BankAccountProps);
                        wallet.withdrawal.pix.handle({} as PixProps);
                    }}>
                    <I icon={['fal', 'pen-to-square']} />
                </IconButton>

            </Stack>
        </Box>
    );
};
