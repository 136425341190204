import { useTheme } from '@mui/material';
import { styles } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();
    const css = {
        wrapper: {
            border: '1px solid ' + palette.cityLights,
            backgroundColor: !mediaQuery.lg ? palette.lightBg : palette.common.white,
        },
        wrapperButton: {
            borderBottom: '1px solid ' + palette.cityLights,
        },
    };
    return { css };
};
