import { CopyButton, SearchForm } from '@components';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { rem } from '@utils';
import { topics } from '@static/faq';
import { BoxContact, Topic } from './components';
import { sx } from './sx';
import { useState } from 'react';

const Center: React.FC = () => {
    const { css } = sx();
    const [activeCopy, setActiveCopy] = useState(false);

    const copyEmail = async () => {
        try {
            await navigator.clipboard.writeText('contato@rewardhub.com.br');
            setActiveCopy(true);
        } catch (err) {
        }
        setTimeout(() => {
            setActiveCopy(false);
        }, 3000);
    };

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <Container maxWidth='lg'>
                <Box sx={{ ...css.box.search }}>
                    <Stack
                        spacing={2}
                        width={'100%'}
                        maxWidth={rem(573)}>
                        <Typography
                            mb={rem(20)}
                            textAlign={'center'}
                            sx={{ ...css.texts.title }}
                            variant='h2'>
                            Com qual assunto você quer ajuda?
                        </Typography>
                        <SearchForm fromHelperCenter />
                    </Stack>
                </Box>
                <Grid container spacing={2}>
                    {topics.map((topic) => (
                        <Grid key={topic.url} item md={4} lg={4} sm={6} xs={12}>
                            <Topic
                                keyValue={topic.url}
                                title={topic.topic}
                                legend={topic.description} />
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ ...css.box.contact }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography
                            sx={{ ...css.texts.title }}
                            variant='h2'>
                            Contate-nos
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <BoxContact
                                title={'Email de contato'}
                                legend={'contato@rewardhub.com.br'}
                                icon={['far', 'envelope']}
                                link={''}
                                copyButton={
                                    <CopyButton
                                        active={activeCopy}
                                        action={() => copyEmail()} />
                                } />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export { Center };
