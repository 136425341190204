import { Template } from '@components';
import { Box, Container } from '@mui/material';
import { sx } from './sx';
import { useNavigate } from 'react-router-dom';

const SuccessFeedback: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();
    return (
        <Box sx={css.wrapper}>
            <Container maxWidth='xs'>
                <Template.AuthFeedback
                    title='Cadastro realizado!'
                    button={{
                        text: 'Ir para a home',
                        onClick: () => navigate('/'),
                    }}
                />

            </Container>
        </Box>
    );
};

export { SuccessFeedback };
