import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { typography, palette }= useTheme();
    const css = {
        box: {
            title: {
                mb: rem(30),
            },
            productItem: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            quantityCheck: {
                display: 'flex',
                alignItems: 'center',
            },
        },
        text: {
            title: {
                fontWeight: typography.fontWeightRegular,
                fontSize: rem(14),
            },
            products: {
                fontWeight: typography.fontWeightSemiBold,
                fontSize: rem(12),
            },
        },
        divider: {
            borderColor: palette.clouds,
            height: 'auto',
        },
    };

    return { css };
};
