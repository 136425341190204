import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Image } from '@components';
import { rem } from '@utils';
import { sx } from './sx';

interface PaymentMethodCardProps {
    srcFlag: string;
    nameFlag: string;
    numberCard: string;
    rightChildren?: React.ReactNode;
    borderStyle?: 'bottom' | 'shadow';
}

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
    srcFlag, numberCard, nameFlag, rightChildren, borderStyle,
}: PaymentMethodCardProps) => {
    const { typography } = useTheme();
    const { css } = sx(borderStyle);
    return (
        <Box
            sx={{ ...css.box }}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Box sx={{ width: rem(24), border: 0 }}>
                    <Image
                        src={srcFlag}
                        alt={nameFlag}
                        maxHeight={19}
                        objectFit='contain' />
                </Box>
                <Stack>
                    <Typography sx={{
                        fontSize: rem(13),
                        fontWeight: typography.fontWeightMedium,
                    }}>
                        {nameFlag}
                    </Typography>
                    <Typography sx={{
                        fontSize: rem(14),
                        fontWeight: typography.fontWeightBold,
                    }}>
                        {numberCard}
                    </Typography>
                </Stack>
            </Stack>
            {rightChildren && rightChildren}
        </Box>
    );
};

export { PaymentMethodCard };
