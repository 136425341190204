/* eslint-disable max-len */
import { Container, Box, Skeleton, Grid, Stack } from '@mui/material';
import { styles } from '@utils';
import { sx } from './sx';

export const ProductViewSk = () => {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();

    return (
        <Container>
            <Box sx={{ ...css.box.breadcrumbs }}>
                <Skeleton width={210} variant='text' />
            </Box>
            <Grid container spacing={mediaQuery.md ? 2 : 0} sx={{ ...css.grid.container }}>
                <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Skeleton height={mediaQuery.md ? 378 : 220} variant='rectangular' />
                    <Stack mt={1.5} direction={'row'} spacing={1}>
                        {[0, 1, 2].map((i) => {
                            return (
                                <Skeleton key={`sk-slider-${i}`} width={46} height={46} variant='rounded' />
                            );
                        })}
                    </Stack>
                </Grid>
                <Grid item lg={6} sm={12} md={6} xs={12}>
                    <Stack spacing={2.5}>
                        <Skeleton width={300} height={28} variant='rounded' />
                        <Skeleton width={250} variant='text' />
                        <Stack spacing={1}>
                            <Skeleton width={110} height={25} variant='rounded' />
                            <Skeleton width={110} height={25} variant='rounded' />
                        </Stack>
                        <Stack spacing={1}>
                            <Skeleton width={100} variant='text' />
                            <Stack direction={'row'} spacing={1}>
                                {[0, 1, 2, 3].map((i) => {
                                    return (
                                        <Skeleton key={`sk-var-${i}`} width={46} height={46} variant='rounded' />
                                    );
                                })}
                            </Stack>
                        </Stack>
                        <Stack spacing={1}>
                            <Skeleton width={100} variant='text' />
                            <Stack direction={'row'} spacing={1}>
                                {[0, 1, 2].map((i) => {
                                    return (
                                        <Skeleton key={`sk-item-${i}`} width={66} height={34} variant='rounded' />
                                    );
                                })}
                            </Stack>
                        </Stack>
                        <Stack spacing={1}>
                            <Skeleton width={100} variant='text' />
                            <Skeleton width={132} height={31} variant='rounded' />
                        </Stack>
                        <Box sx={{ ...css.box.divider }}></Box>
                        <Stack spacing={1}>
                            <Skeleton width={350} height={37} variant='rounded' />
                            <Skeleton width={350} height={37} variant='rounded' />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <Box sx={{ ...css.box.divider }}></Box>
            <Grid container>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <Stack mb={3} spacing={1}>
                        <Skeleton width={100} variant='text' />
                        <Skeleton height={114} variant='rounded' />
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};
