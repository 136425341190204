import React from 'react';
import { Image, Price, StatusTag } from '@components';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { rem } from '@utils';
import { ProductInlineProps } from '@interfaces/index';

const ProductInline: React.FC<ProductInlineProps> = ({
    srcImage, srcLogoPartner, title, status, valueType,
    value, discount, alignCenter, quantity, cashback,
}: ProductInlineProps) => {
    const { palette, typography } = useTheme();
    return (
        <Box display={'flex'} alignItems={alignCenter ? 'center' : 'start'}>
            <Box
                sx={{
                    display: 'flex',
                    width: 80,
                    height: 80,
                    border: `1px solid ${palette.cityLights}`,
                    borderRadius: rem(4),
                    mr: rem(8),
                }}>
                <Image
                    src={srcImage}
                    alt={title}
                    objectFit='contain'
                    maxHeight={80}
                />
            </Box>
            <Stack spacing={0.6}>
                <Typography
                    sx={{
                        fontWeight: typography.fontWeightBold,
                        fontSize: rem(15),
                        color: palette.customBlack,
                    }}>
                    {title}
                </Typography>
                {srcLogoPartner &&
                    <Box display={'flex'}>
                        <Image
                            src={srcLogoPartner}
                            alt={title}
                            objectFit='contain'
                            maxHeight={26}
                        />
                    </Box>
                }
                <Price size='small' type={valueType} discount={discount} value={value} />
                {status &&
                    <StatusTag status={status} />
                }
                {quantity &&
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography
                            sx={{
                                fontWeight: typography.fontWeightMedium,
                                fontSize: rem(10),
                                color: palette.darkGray,
                                mr: rem(3),
                            }}>
                            Quantidade:
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: typography.fontWeightBold,
                                fontSize: rem(10),
                                color: palette.darkGray,
                            }}>
                            {quantity}
                        </Typography>
                    </Box>
                }
                {cashback && (
                    <Box mt={rem(4)}>
                        <Typography
                            component='h2'
                            fontSize={rem(10)}
                            color='dark'
                            fontWeight={typography.fontWeightMedium}
                            sx={{
                                '& > span': {
                                    color: palette.mediumVioletRed,
                                    fontWeight: typography.fontWeightSemiBold,
                                },
                            }}
                        >
                            Ganhe <span>{cashback} pontos</span> com esta compra
                        </Typography>
                    </Box>
                )}
            </Stack>
        </Box>
    );
};

export { ProductInline };
