
import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import Typography from '@mui/material/Typography';
import { Content, Icon } from './styles';
interface TemplateSuccessProps {
    backgroundColor?: string;
    maxWidth?: string | number;
    noBorder?: boolean;
    children: React.ReactNode;
}
const TemplateSuccess = ({
    backgroundColor, maxWidth, noBorder, children,
}: TemplateSuccessProps) => {
    const { palette, typography, breakpoints } = useTheme();
    const { pxToRem } = typography;
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Box sx={{
            maxWidth: maxWidth ?? typography.pxToRem(590),
            width: '100%',
            margin: '0 auto',
            [breakpoints.up('sm')]: {
                borderWidth: typography.pxToRem(1),
                borderColor: palette.clouds,
                borderStyle: noBorder ? 'none' : 'solid',
                borderTopLeftRadius: noBorder ? 0 : typography.pxToRem(3),
                borderTopRightRadius: noBorder ? 0 : typography.pxToRem(3),
                boxShadow: '0px 3px 6px #00000029',
            },
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: backgroundColor ?? palette.dark,
                padding: typography.pxToRem(30),
                [breakpoints.up('sm')]: {
                    borderTopLeftRadius: noBorder ? 0 :typography.pxToRem(3),
                    borderTopRightRadius: noBorder ? 0 :typography.pxToRem(3),
                    flexDirection: 'row',
                    padding: typography.pxToRem(40),
                },
            }}>
                <Icon>
                    <I
                        icon={['far', 'check']}
                        fontSize={!matches ? 28 : 36}
                        color={palette.status.accepted}
                    />
                </Icon>
                <Typography
                    variant='h2'
                    component='h2'
                    align={!matches ? 'center' : 'left'}
                    fontSize={!matches ? pxToRem(18) : pxToRem(28)}
                    fontWeight={typography.fontWeightBold}
                    ml={!matches ? 0 : pxToRem(12)}
                    mt={!matches ? pxToRem(12) : 0}
                    color={palette.common.white}>
                    Pedido realizado com sucesso!
                </Typography>
            </Box>

            <Content>
                {children}
            </Content>
        </Box>
    );
};

export { TemplateSuccess };
