import { Box, Skeleton, Stack } from '@mui/material';
import { sx } from './sx';
import { styles } from '@utils';

export const ProductSkeleton = () => {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();

    return (
        <Box sx={{ ...css.box.wrapper, padding: '15px' }}>
            <Skeleton variant='rectangular' height={!mediaQuery.sm ? 97 : 177} />
            <Stack pt={2}>
                <Box height={!mediaQuery.sm ? '78px' : '67px'}>
                    <Skeleton variant='text' />
                    <Skeleton variant='text' />
                </Box>
                <Skeleton width={120} variant='text' />
                <Skeleton width={120} variant='text' />
                <Skeleton height={15} variant='text' />
            </Stack>
            <Stack mt={2} justifyContent={'end'} direction={'row'} spacing={1}>
                <Skeleton width={30} height={30} variant='circular' />
                <Skeleton width={30} height={30} variant='circular' />
            </Stack>
        </Box>
    );
};
