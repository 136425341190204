/* eslint-disable max-len */
const PATHS = {
    AUTH: '/auth',
};

export const routes = {
    auth: {
        login: PATHS.AUTH + '/login',
        createAccount: {
            form: PATHS.AUTH + '/criar-conta',
            successFinished: PATHS.AUTH + '/criar-conta/sucesso',
            sendConfirmationCode: PATHS.AUTH + '/criar-conta/enviar-codigo',
            typeConfirmationCode: PATHS.AUTH + '/criar-conta/validar-conta/:method',
        },
        recoveryPassword: {
            formTypeEmail: PATHS.AUTH + '/recuperar-senha',
            formTypeEmailSuccess: PATHS.AUTH + '/recuperar-senha/sucesso',
            formTypeNewPassword: PATHS.AUTH + '/recuperar-senha/nova-senha',
            formTypeNewPasswordSuccess: PATHS.AUTH + '/recuperar-senha/nova-senha/sucesso',
        },
    },
    common: {
        results: {
            search: '/resultados/busca',
        },
    },
};
