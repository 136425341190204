/* eslint-disable max-len */
import React, { useState } from 'react';
import { AlertColor, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { HeaderPage, Image, Price, OrderStatusTimeline, OrderStatusTimelineItem, MessageBox, Template, Feedback } from '@components';
import { files, findOrderStatus, formatAddress, rem, styles } from '@utils';
import { useUser } from '@hooks/user';
import { OrderProps } from '@interfaces/Services';
import { sx } from './sx';
import { ProductItem } from '../components/product-item';
import { paymentMethods } from '@static/payment-methods';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AdType, OrderStatusType } from '@types';
import { ToggleStaticProps } from '@interfaces/index';
import { CancelOrder, DevolutionOrder } from '../actions';
import { StatusTagOrder } from '../components/status-tag-order';

const cashbackType = {
    MONEY: 'SHOPPING',
    POINTS: 'REDEMPTION',
};

type ButtonModalType = {
    [key: string]: string;
}

const textsButtonModal: ButtonModalType = {
    '1': 'CONTINUAR',
    '2': 'CONTINUAR',
    '3': 'CONTINUAR',
    '4': 'CONFIRMAR DEVOLUÇÃO',
    '5': 'FINALIZAR',
    '6': 'FECHAR',
};

const invoiceTypes = ['PIX', 'SLIP'];

const Details: React.FC = () => {
    const dialogCancelOrder = React.useRef<ToggleStaticProps>(null);
    const dialogDevolutionOrder = React.useRef<ToggleStaticProps>(null);

    const navigate = useNavigate();
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const { purchase } = useUser();
    const param = useParams();

    const [isLoadedOrder, setIsLoadedOrder] = useState<boolean>(false);
    const [order, setOrder] = useState<OrderProps | null>();
    const [openFeedback, setOpenFeedback] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoaded: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const findOrder = async () => {
        if (purchase.orders.list.isLoaded) {
            if (purchase.orders.list.data.length > 0) {
                const order = purchase.orders.list.data.find((order) => order._id === param.id);

                !!order && setOrder(order);
                setIsLoadedOrder(true);
            }
        } else {
            await purchase.orders.load();
        }
    };

    const verifyReturnAction = () => {
        const response = !!order ? order.currentStatus === 'FINISHED' && order.
            statusHistory
            .find((item) => item.status as OrderStatusType === 'FINISHED') : false;

        if (!!(response)) {
            const date1 = new Date(response.date);
            const date2 = new Date();

            const timeDiff = Math.abs(date2.getTime() - date1.getTime());
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

            return diffDays <= 8;
        }

        return false;
    };

    const cancelOrder = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: true,
        });
        const response = await purchase.cancel(order?._id as string);

        setResponseData({
            isLoaded: false,
            type: response?.response?.type,
            message: response?.response.message,
        });
        setOpenFeedback(true);

        setTimeout(() => {
            setOpenFeedback(false);
        }, 1500);
        dialogCancelOrder.current?.hide();

        if (!response?.error) {
            purchase.orders.load();
            setTimeout(() => {
                navigate('/meus-pedidos');
            }, 1500);
        }
    };

    const submitDevolution = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: true,
        });
        const response = await purchase.devolution.submit();

        setResponseData({
            isLoaded: false,
            type: response?.response?.type,
            message: response?.response.message,
        });
        if (response?.error) {
            dialogDevolutionOrder.current?.hide();
            purchase.devolution.clear();
            setOpenFeedback(true);
            setTimeout(() => {
                setOpenFeedback(false);
            }, 2500);
        } else {
            purchase.devolution.handle.tag({
                tagUrl: response?.response.payload?.devolutionTagUrl,
            });
            purchase.devolution.handle.step(5);
        }
    };

    const handleDevolutionStep = () => {
        switch (purchase.devolution.data.activeStep) {
        case 1:
            if (purchase.devolution.data.items.length > 0) {
                purchase.devolution.handle.step(2);
            }
            break;
        case 2:
            if (!!purchase.devolution.data.reason) {
                purchase.devolution.handle.step(3);
            }
            break;
        case 3:
            purchase.devolution.handle.step(4);
            break;
        case 4:
            submitDevolution();
            break;
        case 5:
            purchase.devolution.clear();
            purchase.orders.load();
            dialogDevolutionOrder.current?.hide();
            break;
        case 6:
            purchase.devolution.clear();
            purchase.orders.load();
            dialogDevolutionOrder.current?.hide();
            break;
        default:
            purchase.devolution.clear();
            purchase.orders.load();
            dialogDevolutionOrder.current?.hide();
            break;
        }
    };

    React.useEffect(() => {
        findOrder();
    }, [purchase.orders.list.data]);

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <Box
                width={'100%'}
                top={0}
                position={mediaQuery.lg ? 'relative' : 'fixed'}>
                <HeaderPage
                    onClick={() => navigate(-1)}
                    icon={['far', 'chevron-left']}
                    titleAlign={mediaQuery.lg ? 'default' : 'center'}
                    title={'Detalhe do pedido'}
                    noPadding/>
            </Box>
            {isLoadedOrder ?
                (!!order ?
                    <>
                        <Stack divider={ <Box sx={{ ...css.box.divider }}></Box> }>
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                        Parceiro
                                </Typography>
                                <Box display={'flex'}>
                                    <Image
                                        objectFit='contain'
                                        src={files.load(order.store.images.avatar, 'server')}
                                        alt={order.store.title}
                                        maxHeight={mediaQuery.md ? 36 : 30} />
                                </Box>
                            </Box>
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                        Número do pedido
                                </Typography>
                                <Typography
                                    sx={{ ...css.texts.numberOrder }}
                                    variant='body1'>
                                    {order._id}
                                </Typography>
                            </Box>
                            <Box>
                                {order.currentStatus === 'REFUNDED' || order.currentStatus === 'PARTIALLY_REFUNDED' ?
                                    <Box px={2} my={2}>
                                        <Typography
                                            sx={{
                                                fontSize: rem(12),
                                            }}
                                            variant='caption'>
                                            <I
                                                style={{ marginRight: rem(5) }}
                                                icon={['far', 'circle-info']} />
                                            Sua devolução foi aceita e o reembolso será feito
                                        </Typography>
                                    </Box> :
                                    (
                                        <>
                                            <Typography
                                                sx={{ ...css.texts.statusOrder }}
                                                variant='h4'>
                                                    Status do pedido
                                            </Typography>
                                            {order.currentStatus !== 'CANCELED' ?
                                                <OrderStatusTimeline>
                                                    <OrderStatusTimelineItem
                                                        status={'AWAITING_PAYMENT'}
                                                        date={findOrderStatus('AWAITING_PAYMENT', order).date}
                                                        time={findOrderStatus('AWAITING_PAYMENT', order).time}
                                                        actual={order.currentStatus === 'AWAITING_PAYMENT'}
                                                        active={findOrderStatus('AWAITING_PAYMENT', order).active} />
                                                    <OrderStatusTimelineItem
                                                        status={'PAID'}
                                                        date={findOrderStatus('PAID', order).date}
                                                        time={findOrderStatus('PAID', order).time}
                                                        actual={order.currentStatus === 'PAID'}
                                                        active={findOrderStatus('PAID', order).active} />
                                                    { order.currentStatus === 'AWAITING_PARTNER' && <OrderStatusTimelineItem
                                                        status={'AWAITING_PARTNER'}
                                                        date={findOrderStatus('AWAITING_PARTNER', order).date}
                                                        time={findOrderStatus('AWAITING_PARTNER', order).time}
                                                        actual={order.currentStatus === 'AWAITING_PARTNER'}
                                                        active={findOrderStatus('AWAITING_PARTNER', order).active} /> }
                                                    <OrderStatusTimelineItem
                                                        status={'TAG_GENERATED'}
                                                        date={findOrderStatus('TAG_GENERATED', order).date}
                                                        time={findOrderStatus('TAG_GENERATED', order).time}
                                                        actual={order.currentStatus === 'TAG_GENERATED'}
                                                        active={findOrderStatus('TAG_GENERATED', order).active} />
                                                    <OrderStatusTimelineItem
                                                        status={'POSTED'}
                                                        date={findOrderStatus('POSTED', order).date}
                                                        time={findOrderStatus('POSTED', order).time}
                                                        actual={order.currentStatus === 'POSTED'}
                                                        active={findOrderStatus('POSTED', order).active} />
                                                    <OrderStatusTimelineItem
                                                        status={'FINISHED'}
                                                        date={findOrderStatus('FINISHED', order).date}
                                                        time={findOrderStatus('FINISHED', order).time}
                                                        actual={order.currentStatus === 'FINISHED'}
                                                        active={findOrderStatus('FINISHED', order).active} />
                                                </OrderStatusTimeline> :
                                                <Box px={2} pb={2}>
                                                    <StatusTagOrder status={'CANCELED'} />
                                                </Box>}
                                        </>

                                    )
                                }
                            </Box>
                            {order.cashback.value > 0 &&
                                    <Box px={rem(15)} pb={rem(15)}>
                                        <Typography
                                            sx={{ ...css.texts.section }}
                                            variant='h4'>
                                            Cashback
                                        </Typography>
                                        <Box sx={{ ...css.box.cashback }}>
                                            <Price
                                                size='small'
                                                type={cashbackType[order.cashback.type] as AdType}
                                                value={order.cashback.value} />
                                        </Box>
                                    </Box>
                            }
                            {order.redemption &&
                                    <Box px={rem(15)} pb={rem(15)}>
                                        <Typography
                                            sx={{ ...css.texts.section }}
                                            variant='h4'>
                                            Pontos usados no pedido
                                        </Typography>
                                        <Price size='small' type={'REDEMPTION'} value={order.redemption.totalPoints} />
                                    </Box>
                            }
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                        Total
                                </Typography>
                                <Price size='small' type={'SHOPPING'} value={order.total} />
                            </Box>
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                        Forma de pagamento
                                </Typography>
                                <Stack alignItems={'center'} direction={'row'} spacing={0.6}>
                                    <Typography
                                        sx={{ ...css.texts.numberOrder }}
                                        variant='body1'>
                                        {paymentMethods.find((item) => item.type === order.payment.type)?.title}
                                    </Typography>
                                    <I fontSize={16} icon={paymentMethods.find((item) => item.type === order.payment.type)?.icon as IconProp} />
                                </Stack>
                            </Box>
                            {invoiceTypes.includes(order.payment.type) && order.currentStatus === 'AWAITING_PAYMENT' &&
                                    <Box px={rem(15)} pb={rem(15)}>
                                        <Typography
                                            sx={{ ...css.texts.section }}
                                            variant='h4'>
                                            Fatura
                                        </Typography>
                                        <Link
                                            style={{ ...css.linkInvoice }}
                                            target='_blank'
                                            to={order?.payment?.informations?.invoiceUrl}>
                                            <Stack
                                                alignItems={'center'} direction={'row'} spacing={0.6}>
                                                <Typography
                                                    sx={{ ...css.texts.numberOrder }}
                                                    variant='body1'>
                                                    Visualizar fatura
                                                </Typography>
                                                <I fontSize={16} icon={['fal', 'share']} />
                                            </Stack>
                                        </Link>
                                    </Box>
                            }
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                        Frete
                                </Typography>
                                <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                    <Price size='small' type={'SHOPPING'} value={order.delivery.carrierFreight.price} />
                                    <Stack alignItems={'center'} direction={'row'} spacing={0.6} sx={{ ...css.stack.delivery }}>
                                        <I
                                            fontSize={14} icon={['fal', 'truck-fast']} />
                                        <Typography>
                                            {order.delivery.carrierFreight.name}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                        Endereço da entrega
                                </Typography>
                                <Typography
                                    maxWidth={rem(367)}
                                    sx={{ ...css.texts.numberOrder }}
                                    variant='body1'>
                                    {formatAddress(order.userAddress)}, CEP: {order.userAddress.zipCode}
                                </Typography>
                            </Box>
                            {(order.currentStatus === 'AWAITING_PAYMENT' || order.currentStatus === 'PAID') &&
                                <Box px={rem(15)} pb={rem(15)}>
                                    <Typography
                                        sx={{ ...css.texts.section }}
                                        variant='h4'>
                                        AÇÕES
                                    </Typography>
                                    <Typography
                                        sx={{ ...css.texts.section }}
                                        variant='h4'>
                                        Pedido
                                    </Typography>
                                    <Button
                                        onClick={() => dialogCancelOrder.current?.show()}
                                        variant='contained' size='medium'>
                                        Cancelar pedido
                                    </Button>
                                </Box>
                            }
                            {verifyReturnAction() &&
                                <Box px={rem(15)} pb={rem(15)}>
                                    <Typography
                                        sx={{ ...css.texts.section }}
                                        variant='h4'>
                                        AÇÕES
                                    </Typography>
                                    <Typography
                                        sx={{ ...css.texts.section }}
                                        variant='h4'>
                                        Pedido
                                    </Typography>
                                    <Button
                                        onClick={() => dialogDevolutionOrder.current?.show()}
                                        variant='contained' size='medium'>
                                        Solicitar devolução
                                    </Button>
                                </Box>
                            }
                            {order.currentStatus === 'TAG_GENERATED_DEVOLUTION' &&
                                <Box px={rem(15)} pb={rem(15)}>
                                    <Typography
                                        sx={{ ...css.texts.section }}
                                        variant='h4'>
                                        AÇÕES
                                    </Typography>
                                    <Typography
                                        sx={{ ...css.texts.section }}
                                        variant='h4'>
                                        Etiqueta
                                    </Typography>
                                    <Button variant='contained' size='medium'>
                                        Visualizar etiqueta
                                    </Button>
                                </Box>
                            }
                            <Stack spacing={1} px={2} pb={1}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                        Produtos
                                </Typography>
                                {order.items.map((itemAd, i) => (
                                    <Box key={`${itemAd._id}-${i}`}>
                                        <Link
                                            style={{ ...css.linkProduct }}
                                            to={`/produto/${itemAd.ad._id}?variation=${itemAd.variation._id}&option=${itemAd.variationItem._id}`}>
                                            <ProductItem
                                                srcImage={files.load(itemAd?.variation?.images[0], 'server')}
                                                title={`${itemAd.ad.title}`} />
                                            <I fontSize={14} icon={['far', 'chevron-right']} />
                                        </Link>
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                        <Template.MuiDialog
                            ref={dialogCancelOrder}
                            fullScreen={!mediaQuery.sm}
                            maxWidth='sm'
                            title='Confirmar o cancelamento'
                            showDialogTitle
                            onCancel={!responseData.isLoaded ? {
                                action: () => dialogCancelOrder.current?.hide(),
                            } : undefined}
                            onConfirm={{
                                text: [
                                    'CONFIRMAR',
                                    responseData.isLoaded ? <CircularProgress
                                        size={14}
                                        color='inherit'
                                        sx={{ ml: 1 }}
                                    /> : null,
                                ],
                                disabled: responseData.isLoaded,
                                action: () => cancelOrder(),
                            }}>
                            <CancelOrder />
                        </Template.MuiDialog>
                        <Template.MuiDialog
                            ref={dialogDevolutionOrder}
                            fullScreen={!mediaQuery.sm}
                            maxWidth='sm'
                            title='Devolução do produto'
                            showDialogTitle
                            removeClose={(purchase.devolution.data.activeStep > 4) ||
                                responseData.isLoaded}
                            onCancel={!responseData.isLoaded &&
                                (purchase.devolution.data.activeStep < 5) ? {
                                    action: () => {
                                        purchase.devolution.clear();
                                        dialogDevolutionOrder.current?.hide();
                                    },
                                } : undefined}
                            onConfirm={{
                                text: [
                                    textsButtonModal[String(purchase.devolution.data.activeStep)],
                                    responseData.isLoaded ? <CircularProgress
                                        size={14}
                                        color='inherit'
                                        sx={{ ml: 1 }}
                                    /> : null,
                                ],
                                disabled:
                                    (purchase.devolution.data.activeStep === 1 && purchase.devolution.data.items.length < 1) ||
                                    (purchase.devolution.data.activeStep === 2 && !purchase.devolution.data.reason) ||
                                    (responseData.isLoaded),
                                action: () => handleDevolutionStep(),
                            }}>
                            <DevolutionOrder order={order} />
                        </Template.MuiDialog>
                    </> :
                    <Box p={2}>
                        <MessageBox message={'Não foi possível encontrar o pedido'} type={'info'} />
                    </Box>
                ) :
                <Box display={'flex'} justifyContent={'center'} p={4}>
                    <CircularProgress
                        color='secondary'
                        size={36} />
                </Box>
            }
            <Feedback.MuiSnackbar
                open={openFeedback}
                severity={responseData.type}
                onClose={() => setOpenFeedback(false)}
                message={responseData.message}
            />
        </Box>
    );
};

export { Details };
