import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();

    const handleBorder = {
        solid: `1px solid ${palette.cityLights}`,
        dashed: `1px dashed ${palette.clouds}`,
        error: `1px solid ${palette.status.rejected}`,
    };

    const css = {
        box: {
            wrapper: (borderType: 'dashed' | 'solid' | 'error') => {
                return {
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    backgroundColor: palette.common.white,
                    border: handleBorder[borderType],
                };
            },
            wrapperTexts: {
                margin: `${rem(20)} 0`,
            },
            texts: {
                margin: `0 ${rem(15)}`,
            },
            iconAction: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: rem(15),
            },
            locationIcon: {
                backgroundColor: palette.primaryColor,
                height: rem(32),
                width: rem(32),
                borderRadius: rem(25),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: `${rem(15)} 0 ${rem(10)} ${rem(10)}`,
                padding: `${rem(10)}`,
            },
        },
        icon: {
            location: {
                color: palette.common.white,
                fontSize: rem(16),
            },
            mode: {
                color: palette.common.black,
                fontSize: rem(18),
            },
        },
        typography: {
            title: {
                fontWeight: typography.fontWeightBold,
                fontSize: rem(16),
                marginBottom: rem(3),
            },
            legend: {
                fontWeight: typography.fontWeightMedium,
            },
        },
        sxIconLocation: {
            color: palette.common.white,
            fontSize: rem(16),
        },
        addressTag: {
            wrapper: {
                backgroundColor: palette.darkGray,
                color: palette.common.white,
                padding: `${rem(4)} ${rem(8)}`,
                borderRadius: rem(4),
                display: 'inline-block',
                mb: rem(8),
            },
        },
    };
    return { css };
};
