import { Box, Container, Grid, IconButton, useTheme } from '@mui/material';
import { Image } from '@components';
import { files, rem } from '@utils';
import logo from '../../../../../assets/img/logo.png';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { useFilters } from '@hooks/filters';
import { useAds } from '@hooks/ads';
import { useGlobal } from '@hooks/global';
import { StoreType } from '@types';
import { useCustomTheme } from '@hooks/custom-theme';

const MobileSimpleHeader = () => {
    const { css } = sx();
    const { clearFilters } = useFilters();
    const ad = useAds();
    const global = useGlobal();

    const navigate = useNavigate();
    const location = useLocation();

    const { customTheme } = useCustomTheme();
    const { palette } = useTheme();

    React.useEffect(() => {
        if (!location.pathname.includes('/resultados/busca/')) {
            clearFilters();
            if (ad.categories.list.history.length >= 1) {
                ad.categories.reset({ type: 'history' });
                ad.categories.reset({ type: 'categories' });
            }
        }
    }, [location.search]);

    React.useEffect(() => {
        const prop = localStorage.getItem('storeType');

        if (prop) {
            global.app.view.set(prop as StoreType);
        } else {
            global.app.view.set('SHOPPING');
        }
    }, []);

    const checkPathname = (location.pathname !== 'autenticando');

    return (
        <Box sx={css.header.wrapper}>
            <Container maxWidth='lg' sx={{ height: '100%' }}>
                <Grid container spacing={0} height='100%'>

                    <Grid item xs={!checkPathname ? 6 : 12}>
                        <Box
                            height='100%' width='100%'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'>
                            <Image src={!!customTheme.data.logo ?
                                files.load(customTheme.data.logo, 'server') :
                                logo} alt='Logo' maxHeight={28} />
                        </Box>
                    </Grid>
                    {!checkPathname && (
                        <Grid item xs={6}>
                            <Box
                                height='100%'
                                display='flex'
                                alignItems='center'
                                justifyContent='flex-end'>
                                <IconButton
                                    aria-label="close mobile menu"
                                    onClick={() => navigate(-1)}
                                    sx={{
                                        ...{
                                            backgroundColor: palette.cityLights,
                                            minWidth: rem(34),
                                        },
                                        '&:hover': {
                                            backgroundColor: palette
                                                .cityLights + ' !important',
                                        },
                                    }}>
                                    <I
                                        icon={['far', 'xmark']}
                                        fontSize={rem(18)}
                                        color={palette.dark} />
                                </IconButton>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box >
    );
};

export { MobileSimpleHeader };
