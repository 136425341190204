import { useState } from 'react';
import { PaymentMethodType } from '@types';
import { HandleCreditCardFormProps } from '@interfaces/Services';
import { client } from '@api';
import { useGlobal } from '@hooks/global';
import { DEFAULTS } from './DEFAULTS';
import { ItemDevolutionProps } from '@interfaces/index';


export const userPurchase = () => {
    const { services } = client();
    const global = useGlobal();

    const [orderDevolution, setOrderDevolution] =
        useState(DEFAULTS.USER.PURCHASE.DEVOLUTION);
    const [orders, setOrders] = useState(DEFAULTS.USER.PURCHASE.ORDERS);
    const [ordersDevolution, setOrdersDevolution] =
        useState(DEFAULTS.USER.PURCHASE.ORDERS);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType | undefined>();
    const [creditCardForm, setCreditCardForm] = useState({
        creditCardId: '',
        installmentNumber: 1,
    });

    const handlePaymentMethod = (value: PaymentMethodType | undefined) => {
        setPaymentMethod(value);
    };

    const handleCreditCardForm = (data: HandleCreditCardFormProps) => {
        setCreditCardForm((prev) => prev = {
            ...prev,
            [data.action]: data.action === 'installmentNumber' ?
                Number(data.value) : data.value,
        });
    };

    const create = async () => {
        const response = await services.user.purchase.createPurchase({
            data: {
                paymentType: 'BY_PURCHASE',
                paymentMethod: paymentMethod as PaymentMethodType,
                creditCardId: paymentMethod === 'CARD' ?
                    creditCardForm.creditCardId : undefined,
                installmentNumber: paymentMethod === 'CARD' ?
                    creditCardForm.installmentNumber : undefined,
            },
            purchaseType: global.app.view.application.type,
        });
        return response;
    };

    const loadOrders = async () => {
        setOrders((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        setOrdersDevolution((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        const response = await services.purchase.listOrders();
        const responseDevolution = await services.purchase.listOrdersDevolution();
        setOrdersDevolution((prev) => prev = {
            message: responseDevolution?.response?.message,
            isLoaded: true,
            error: !!(!responseDevolution?.error),
            status: responseDevolution?.response?.status,
            data: responseDevolution?.response?.payload ?
                responseDevolution?.response.payload :
                [],
        });
        setOrders((prev) => prev = {
            message: response?.response?.message,
            isLoaded: true,
            error: !!(!response?.error),
            status: response?.response?.status,
            data: response?.response?.payload ?
                response?.response.payload :
                [],
        });
    };

    // DEVOLUTION

    const handleDevolutionStep = (step: number) => {
        setOrderDevolution((prev) => prev = {
            ...prev,
            activeStep: step,
        });
    };

    const handleDevolutionItens = (items: ItemDevolutionProps[]) => {
        setOrderDevolution((prev) => prev = {
            ...prev,
            items,
        });
    };

    const handleDevolutionReason = ({ reason }: {
        reason: string
    }) => {
        setOrderDevolution((prev) => prev = {
            ...prev,
            reason,
        });
    };

    const handleDevolutionOrder = ({ orderId }: {
        orderId: string
    }) => {
        setOrderDevolution((prev) => prev = {
            ...prev,
            orderId,
        });
    };

    const handleDevolutionTag = ({ tagUrl }: {
        tagUrl: string
    }) => {
        setOrderDevolution((prev) => prev = {
            ...prev,
            tagUrl,
        });
    };

    const clearDevolutionOrder = () => {
        setOrderDevolution(DEFAULTS.USER.PURCHASE.DEVOLUTION);
    };

    const loadReasons = async () => {
        return await services.purchase.loadReasonsReturn();
    };

    const submitDevolution = async () => {
        return await services.purchase.returnPurchase({
            orderId: orderDevolution.orderId,
            data: {
                items: orderDevolution.items,
                reason: orderDevolution.reason,
            },
        });
    };

    const cancelOrder = async (orderId: string) => {
        return await services.purchase.cancelOrder({
            orderId: orderId,
        });
    };

    return {
        create: create,
        paymentMethod: {
            list: paymentMethod,
            handleType: handlePaymentMethod,
        },
        creditCardForm: {
            list: creditCardForm,
            handle: handleCreditCardForm,
        },
        orders: {
            list: orders,
            listDevolution: ordersDevolution,
            load: loadOrders,
        },
        cancel: cancelOrder,
        devolution: {
            data: orderDevolution,
            loadReasons,
            handle: {
                order: handleDevolutionOrder,
                step: handleDevolutionStep,
                items: handleDevolutionItens,
                reason: handleDevolutionReason,
                tag: handleDevolutionTag,
            },
            clear: clearDevolutionOrder,
            submit: submitDevolution,
        },
    };
};
