import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LegendButtonProps } from '@interfaces/index';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { rem } from '@utils';
import { sx } from './sx';

const titlesTooltip = {
    addToCart: 'adicionar ao carrinho',
    edit: 'Editar',
    delete: 'Excluir',
    change: 'Alterar',
    right: '',
};

const LegendButton: React.FC<LegendButtonProps> = ({
    actionFunction, actionType, noLegend, disabled,
}: LegendButtonProps) => {
    const { css } = sx();
    const { palette, typography } = useTheme();

    return (
        <IconButton disabled={disabled} onClick={disabled ? () => { } : actionFunction}
            sx={{ ...css.sxIconButton(disabled) }}>
            <Box>
                <I
                    icon={
                        css.handleIconAction[actionType]
                            .icon as IconProp}
                    color={css.handleIconAction[actionType].color}
                    style={{ fontSize: rem(16) }} />
                {!noLegend &&
                    <Typography
                        variant="body1"
                        display="block"
                        fontWeight={typography.fontWeightMedium}
                        color={palette.darkGray}
                        fontSize={typography.pxToRem(9)}>
                        {titlesTooltip[actionType]}
                    </Typography>
                }
            </Box>
        </IconButton>
    );
};

export { LegendButton };
