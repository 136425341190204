/* eslint-disable max-len */
import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    Box,
    FormControl,
    InputLabel,
    Radio,
    Select,
    Stack,
    MenuItem as MenuItemUi,
    SelectChangeEvent,
    Typography,
    CircularProgress,
} from '@mui/material';
import { HeaderPage, MenuItem, PaymentMethodCard, Template } from '@components';
import { sx } from './sx';
import { money, styles } from '@utils';
import { ToggleStaticProps } from '@interfaces/index';
import { TitleSection } from '../../components/title-section';
import { useUser } from '@hooks/user';
import { cardFlagsIcon } from '@static/card-flags';
import { CreditCardFlagType } from '@types';

export const Card = () => {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const user = useUser();

    const dialogCard = React.useRef<ToggleStaticProps>(null);

    const [limitList, setLimitList] = React.useState<number | undefined>(3);
    const [selectState, setSelectState] = React.useState('1');

    const handleSelect = (event: SelectChangeEvent) => {
        setSelectState(event.target.value as string);
        user.purchase.creditCardForm.handle({
            action: 'installmentNumber',
            value: event.target.value,
        });
    };

    const installmentsValue = () => {
        const list = [];
        const price = user.cart.list.data.total;
        const installmentPrice = (per?: number): number => {
            const num = price ? Number(price.toFixed(2)) : 0;
            return per ? num / per : num;
        };
        const [first] = String(price).split('.');

        if ((String(price).indexOf('.') !== 1) && Number(first) <= 300) {
            const result = [
                { value: 1, title: `1x de R$${money.formatNumberToPriceBRL(+installmentPrice())} sem juros`, total: money.formatNumberToPriceBRL(installmentPrice()) },
            ];
            list.push(...result);
        } else {
            const result = [
                { value: 1, title: `1x de R$${money.formatNumberToPriceBRL(+installmentPrice())} sem juros`, total: 'À vista' },
                { value: 2, title: `2x de R$${money.formatNumberToPriceBRL(+installmentPrice(2).toFixed(2))} sem juros`, total: money.formatNumberToPriceBRL(Number(installmentPrice(2).toFixed(2)) * 2) },
                { value: 3, title: `3x de R$${money.formatNumberToPriceBRL(+installmentPrice(3).toFixed(2))} sem juros`, total: money.formatNumberToPriceBRL(Number(installmentPrice(3).toFixed(2)) * 3) },
            ];
            list.push(...result);
        }
        return list;
    };

    React.useEffect(() => {
        user.paymentForm.current.clear();
        if (!user.paymentForm.list.isLoaded) {
            user.paymentForm.load();
        }
    }, []);

    React.useEffect(() => {
        if (!limitList && user.paymentForm.list.isLoaded && user.paymentForm.list?.data?.length > 0) {
            user.purchase.creditCardForm.handle({
                action: 'creditCardId',
                value: user.paymentForm.list.data[user.paymentForm.list.data.length -1]._id,
            });
        }
    }, [user.paymentForm.list.data]);

    return (
        <Box>
            <Stack sx={{ ...css.stack.paymentOptions }} spacing={1}>
                {user.paymentForm.list.isLoaded ? (
                    user.paymentForm.list.data?.length > 0 &&
                        user.paymentForm.list.data?.slice(0, limitList).map((item, i, list) => (
                            list.length !== i && (
                                <Stack key={item._id}>
                                    <PaymentMethodCard
                                        srcFlag={cardFlagsIcon[item.flag as CreditCardFlagType]}
                                        nameFlag={item.flag}
                                        numberCard={item.number}
                                        rightChildren={
                                            <Radio
                                                onChange={() => user.purchase.creditCardForm.handle({
                                                    action: 'creditCardId',
                                                    value: item._id,
                                                })}
                                                checked={user.purchase.creditCardForm.list.creditCardId === item._id} />
                                        } />
                                </Stack>
                            )
                        ))) : (
                    <Stack py={1} direction={'row'} justifyContent={'center'}>
                        <CircularProgress
                            size={14} color='inherit' />
                    </Stack>
                )}
                {!!limitList && limitList < user.paymentForm.list?.data?.length ?
                    <Stack py={1} direction={'row'} justifyContent={'center'}>
                        <Typography
                            onClick={() => setLimitList(limitList + 3)}
                            sx={{ ...css.texts.showMore }}>
                            Mostrar mais cartões
                        </Typography>

                    </Stack> :
                    !!limitList && !(user.paymentForm.list?.data?.length <= 3) &&
                    <Stack py={1} direction={'row'} justifyContent={'center'}>
                        <Typography
                            sx={{ ...css.texts.showMore }}
                            onClick={() => setLimitList(3)}>
                            Mostrar menos
                        </Typography>
                    </Stack>
                }
                <MenuItem
                    leftChildren={
                        <I
                            style={{ ...css.icon.squarePlus }}
                            icon={['fal', 'square-plus']} />
                    }
                    size={'medium'}
                    title={'Adicionar um novo Cartão'}
                    onClick={() => dialogCard.current?.show()} />
            </Stack>
            <TitleSection name={'Opções de parcelamento'} />
            <Stack sx={{ ...css.stack.paymentOptions, ...css.stack.installments }} spacing={1}>
                <FormControl fullWidth focused>
                    <InputLabel focused
                        id="select-labell">Selecionar parcelas</InputLabel>
                    <Select
                        variant='outlined'
                        defaultValue={installmentsValue()[0].title}
                        label={'Selecionar parcelas'}
                        labelId="select-installment"
                        id="installment"
                        sx={{ ...css.select }}
                        value={selectState}
                        onChange={handleSelect}
                    >
                        {installmentsValue().map((installment) => (
                            <MenuItemUi
                                sx={{
                                    fontSize: '14px',
                                }}
                                key={`installment-${installment.value}`}
                                value={installment.value}>{installment.title}</MenuItemUi>
                        ))}
                        {/* <MenuItemUi value={'Select 2'}>1x de R$ 25,80</MenuItemUi>
                        <MenuItemUi value={'Select 3'}>1x de R$ 25,80</MenuItemUi> */}
                    </Select>
                </FormControl>
            </Stack>
            <Template.MuiDialog
                maxWidth='sm'
                fullScreen={!mediaQuery.md}
                ref={dialogCard}>
                <HeaderPage
                    titleAlign={'center'}
                    noPadding
                    icon={['far', 'chevron-left']}
                    onClick={() => dialogCard.current?.hide()}
                    title={'Adicionar cartão de crédito'} />
                <Template.Forms.PaymentForm
                    toCheckout
                    externalAction={() => {
                        setLimitList(undefined);
                        dialogCard.current?.hide();
                    }}/>
            </Template.MuiDialog>
        </Box>);
};
