/* eslint-disable quotes */
import { useTheme } from '@mui/material';
import { rem } from '@utils';

const sx = () => {
    const { palette } = useTheme();

    const css = {
        header: {
            wrapper: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                height: rem(60),
                backgroundColor: palette.common.white,
            },
        },
        sub: {
            wrapper: {
                boxShadow: '0px 3px 6px #00000029',
                position: 'relative',
                zIndex: 10,
            },
        },
        drawer: {
            wrapper: {
                overflowX: 'hidden',
                p: 0,
                width: '280px',
                minWidth: '280px',
            },
            content: {
                wrapper: {
                    position: 'relative',
                    backgroundColor: palette.common.white,
                    width: rem(300),
                    // p: rem(15),
                    pl: rem(15),
                    pr: rem(15),
                    height: 'calc(100% - 52px)',
                    overflowX: 'auto',
                },
                filter: {

                },
            },
            actions: {
                wrapper: {
                    p: `${rem(8)} ${rem(15)}`,
                    boxShadow: '0px -3px 6px #00000029',
                    position: 'relative',
                },
            },
        },
    };

    return { css };
};

export { sx };
