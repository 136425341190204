export const DEFAULTS = {
    loading: {
        form: false,
    },
    API_OBJ: {
        error: false,
        message: '',
        field: '',
    },
};
