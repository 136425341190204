import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const skeletonSx = () => {
    const { palette } = useTheme();
    const css = {
        sideMenu: {
            wrapper: {
                mt: 1,
                mb: 1,
                pl: 1,
                pr: 1,
            },
            content: {
                wrapper: {
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    mr: 4,
                },
                text: {
                    fontSize: '12px',
                    ml: 1,
                },
            },
        },
        headerDefault: {
            box: {
                wrapperHeader: {
                    borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                },
                wrapperItens: {
                    height: rem(80),
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
                wrapperSub: {
                    boxShadow: `0 ${rem(3)} ${rem(6)} rgba(0, 0, 0, 0.2)`,
                    borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                    position: 'relative',
                    zIndex: '10',
                },
                wrapperItensSub: {
                    height: rem(50),
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
                logo: {
                    mr: rem(30),
                },
                wrapperButtons: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '100%',
                },
                buttons: {
                    minWidth: rem(360),
                    mr: rem(30),
                },
            },
            stack: {
                texts: {
                    p: `${rem(6)} ${rem(8)}`,
                },
            },
        },
        headerMobile: {
            box: {
                wrapperHeader: {
                    borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                    backgroundColor: palette.common.white,
                    height: rem(60),
                },
                iconMenu: {
                    display: 'flex',
                    alignItems: 'center',
                    px: rem(8),
                    height: '100%',
                },
                wrapperSub: {
                    boxShadow: `0 ${rem(3)} ${rem(6)} rgba(0, 0, 0, 0.2)`,
                    borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                    position: 'relative',
                    zIndex: '10',
                },
                wrapperItensSub: {
                    height: rem(50),
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
                logo: {
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                },
            },
            stack: {
                sub: {
                    height: '100%',
                    width: '100%',
                    p: rem(8),
                },
            },
        },
    };

    return { css };
};
