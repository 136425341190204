/* eslint-disable camelcase */
/* eslint-disable max-len */
import { MountURLParamsForResultsProps, ResponseMountURLParamsForResultsProps } from '@interfaces/Utils';
import { filterPageOptions } from '@static/menus';
import { QuicklinksFiltersParamsKeysType } from '@types';

export const mountURLParamsForResults = ({
    type, filterType, _id, filters,
}: MountURLParamsForResultsProps): ResponseMountURLParamsForResultsProps => {
    let query = '';
    const objectParams = {} as QuicklinksFiltersParamsKeysType;
    let response = {
        query: '',
        params: {} as QuicklinksFiltersParamsKeysType,
    };
    if (filterType === 'filters') {
        if (filters.filterbyKey) {
            // query += '/buscar/' + filters.filterbyKey;
            objectParams.key = filters.filterbyKey;
        }
        if (filters.categoryList) {
            if (filters.categoryList.length > 0) {
                const category = filters.categoryList.map((category) => {
                    const name = category.name.replace(/&/g, '%26');
                    return `${name}|${category._id}`;
                });
                const categoryList = filters.categoryList.map((category) => {
                    const name = category.name.replace(/&/g, '%26');
                    return { name, _id: category._id };
                });
                objectParams.categoryList = categoryList;
                query += '&categoryList=' + category.join(',');
            }
        }
        if (filters.orderBy) {
            const orderBy = filters.orderBy.toString();
            const findOrderBy = filterPageOptions.find((filter) => filter.value === orderBy);
            query += `&orderBy=${findOrderBy?.value}|${findOrderBy?._id}`;
            objectParams.orderBy = orderBy;
        }
        if (filters.points) {
            const [min, max] = filters.points.split('-');
            query += `&valueRange=${min}|f45d4556d,${max}|c45d4f5d6f`;
            objectParams.valueRange = filters.points;
        }
        if (filters.storeUrl) {
            query += `&storeUrl=${filters.storeUrl}`;
            objectParams.storeUrl = filters.storeUrl;
        }
        const verifyQueryStr = query.includes('?');
        if (verifyQueryStr) {
            // return query;
            response = {
                query,
                params: objectParams,
            };
        } else {
            const split = query.split('&');
            const slice = split.slice(1, split.length).join('&');

            // return '?' + slice;
            response = {
                query: slice,
                params: objectParams,
            };
        }
        // return '/resultados'+ query;
        return response;
    };

    if (filterType === 'ads') {
        type localType = 'showcaseId' | 'quickLinkId';
        const setType = (type === 'showcase' && 'showcaseId') ||
            (type === 'quicklink' && 'quickLinkId');
        query = setType + '=' + _id;
        objectParams[setType as localType] = _id;
        response = {
            query,
            params: objectParams,
        };
        // return response;
        // return query;
    }
    return response;
};
