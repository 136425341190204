import { Badge, BadgeProps, styled } from '@mui/material';

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -12,
        top: 1,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        fontSize: '10px',
        backgroundColor: theme.palette.darkGray,
    },
}));
