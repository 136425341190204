import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const root = ReactDOMClient.createRoot(document.getElementById('root')!);

root.render(
    <React.Fragment>
        <CssBaseline />
        <App />
    </React.Fragment>,
);
