import {
    Box,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import { styles } from '@utils';
import { filtersExtractPeriodList } from '@static/extract-filters';
import { useUser } from '@hooks/user';
import { ButtonSelectPeriod } from '@components';

interface WalletPeriodProps {
    currency?: 'POINTS' | 'MONEY';
    type?: 'DEBIT' | 'CREDIT';
}

const ExtractPeriod = ({ currency, type }: WalletPeriodProps) => {
    const { typography } = useTheme();
    const { wallet } = useUser();
    const { pxToRem } = typography;
    const mediaQuery = styles.mediaQueryCSS();

    const [period, setPeriod] = React.useState(30);

    const loadMovements = async () => {
        await wallet.movementHistory.load({ period, currency, type });
    };

    React.useEffect(() => {
        loadMovements();
    }, [period]);


    return (
        <Box m={2}>
            <Typography
                variant='h4'
                fontSize={12}
                fontWeight={typography.fontWeightMedium}
                mt={pxToRem(10)}
                mb={pxToRem(10)}>
                Filtre por período
            </Typography>
            <Box sx={{ overflowX: 'auto' }}>
                <Box sx={{
                    ...{
                        display: 'inline-flex',
                        pb: mediaQuery.md ? 0 : 2,
                    },
                    '& .MuiButton-root': {
                        whiteSpace: 'nowrap',
                    },
                }}>
                    <Stack direction={'row'} spacing={1}>
                        {filtersExtractPeriodList.map(({ id, title, value }) => (
                            <ButtonSelectPeriod
                                key={id}
                                title={title}
                                active={value === period}
                                onClick={() => setPeriod(value)}
                            />
                        ))}
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};

export { ExtractPeriod };
