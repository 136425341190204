import { ContextsProps } from '@interfaces/Contexts';
import { AdsContext } from './context';
import { adsProvider } from '@hooks/ads';

export const AdsProvider = ({ children }: ContextsProps) => {
    const providerValue = adsProvider();
    return (
        <AdsContext.Provider value={providerValue}>
            {children}
        </AdsContext.Provider>
    );
};
