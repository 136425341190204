import { Box, Typography, useTheme } from '@mui/material';
import { rem } from '@utils';

interface ListValuesProps {
    keyTitle: React.ReactNode;
    value: React.ReactNode;
    checkout?: boolean;
    total?: boolean;
}

const ListValues: React.FC<ListValuesProps> = ({
    keyTitle, value, total, checkout,
}: ListValuesProps) => {
    const { typography, palette } = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: rem(10),
                backgroundColor: (total && !checkout) ? palette.snowWhite : 'transparent',
            }}>
            <Typography
                variant='h6'
                sx={{
                    fontSize: total ? rem(16) : rem(14),
                    fontWeight: total ? typography.fontWeightBold :
                        typography.fontWeightMedium,
                }}>
                {keyTitle}
            </Typography>
            {value}
        </Box>
    );
};

export { ListValues };
