import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();

    const css = {
        box: {
            icon: {
                height: rem(38),
                width: rem(38),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: palette.cityLights,
                borderRadius: rem(4),
                fontSize: rem(16),
            },
        },
        stack: {
            wrapper: {
                p: 2,
                position: 'relative',
                width: '100%',
                border: '1px dashed #7D7D7D66',
            },
            value: {
                '.point-label': {
                    display: 'none',
                },
            },
        },
        buttonCredit: {
            'position': 'absolute',
            'right': 15,
            'margin': 'auto 0 !important',
            'backgroundColor': palette.cityLights,
            'height': rem(38),
            'width': rem(38),
            'bottom': 0,
            'top': 0,
            'minWidth': rem(38),
            'padding': 0,
            'borderRadius': 50,
            '&:hover': {
                'backgroundColor': `${palette.cityLights} !important`,
            },
        },
        text: {
            title: {
                fontWeight: typography.fontWeightSemiBold,
                fontSize: rem(14),
            },
            points: {
                fontSize: rem(12),
                fontWeight: typography.fontWeightSemiBold,
            },
        },
    };

    return { css };
};
