import React from 'react';
import { useCustomTheme } from '@hooks/custom-theme';
import { ThemeProvider } from '@mui/material';
import { ContextsProps } from '@interfaces/Contexts';
import { GlobalContext } from './context';
import { globalProvider } from '../../hooks/global';
import { theme } from '../../themes';
import { Display } from '@components';
import { files } from '@utils';


export const GlobalProvider = ({ children }: ContextsProps) => {
    const providerValue = globalProvider();
    const { customTheme } = useCustomTheme();

    React.useEffect(() => {
        if (!customTheme.data.isLoaded) {
            customTheme.load();
        }
    }, []);
    return (
        <GlobalContext.Provider value={providerValue}>
            {customTheme.data.isLoaded ?
                <>
                    <head>
                        <title>{customTheme.data.title}</title>
                        <link
                            rel="icon"
                            // eslint-disable-next-line max-len
                            href={files.load(customTheme.data.logo, 'server')} />
                    </head>
                    <ThemeProvider theme={theme(customTheme.data.colors)}>
                        {children}
                    </ThemeProvider>
                </> :
                <Display.LoadingTheme />
            }
        </GlobalContext.Provider>
    );
};
