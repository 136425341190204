import React from 'react';

import {
    Box,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export interface BoxContactProps {
    title: string;
    legend: string;
    link: string;
    icon: IconProp;
    copyButton?: React.ReactNode;
}

import { sx } from './sx';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const BoxContact: React.FC<BoxContactProps> = ({
    legend, title, icon, link, copyButton,
}: BoxContactProps) => {
    const { css } = sx();
    return (
        <Link to={link} style={{
            ...css.sxLink,
        }}>
            <Box sx={{ ...css.sxBox }}>
                <IconButton sx={{ ...css.sxIconButton }}>
                    <I icon={icon} />
                </IconButton>
                <Stack
                    sx={{ ...css.sxBoxTexts }}>
                    <Typography
                        sx={{ ...css.sxTitle }}
                        variant='h3'>
                        {title}
                    </Typography>
                    <Typography
                        component={'div'}
                        sx={{ ...css.sxLegend }}
                        variant='body1'>
                        {legend}
                    </Typography>
                </Stack>
                {!!copyButton &&
                    <Box sx={{ ...css.sxCopyBox }}>
                        {copyButton}
                    </Box>
                }
            </Box>
        </Link>
    );
};

export { BoxContact };
