import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    Stack,
    useTheme,
} from '@mui/material';
import { rem } from '@utils';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { FilterCheckboxProps } from '@interfaces';

const FilterCheckbox: React.FC<FilterCheckboxProps> = (
    { label, checkbox, count, isChildren }: FilterCheckboxProps,
) => {
    const { typography, palette } = useTheme();

    return (
        <Box ml={isChildren ? Number(isChildren) * 1 : 0}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <FormControlLabel
                    control={<Checkbox
                        size='small'
                        {...checkbox}
                    />}
                    label={`${label}${count ? ' (' + count + ')' : ''}`}
                    sx={{
                        '& > .MuiTypography-root.MuiFormControlLabel-label': {
                            fontSize: rem(14),
                            color: palette.darkGray,
                            fontWeight: typography.fontWeightMedium,
                        },
                    }} />
                {checkbox.checked && (
                    <IconButton>
                        <I
                            icon={['fas', 'circle-xmark']}
                            color={palette.mediumGray}
                            fontSize={rem(14)}
                        />
                    </IconButton>
                )}
            </Stack>
        </Box>
    );
};

export { FilterCheckbox };
