import { alpha, useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 99,
            backgroundColor: alpha(palette.common.white, 0.5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        sxBox: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '0.9375rem',
            cursor: 'pointer',
            // width: '100%',
            // position: 'relative',
            // overflow: 'hidden',
        },
        sxIcon: {
            fontSize: '0.75rem',
        },
        sxTitle: {
            padding: '0.875rem 0',
            fontSize: '0.875rem',
            fontWeight: 700,
            marginLeft: '0.5rem',
            maxWidth: '5rem',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };
    return { css };
};
