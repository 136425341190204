import { client } from '@api';
import { DEFAULTS } from './DEFAULTS';
import React from 'react';

export const stores = () => {
    const { services } = client();
    const [listStores, setListStores] = React.useState(DEFAULTS.STORES.LIST);

    const loadStores = async () => {
        const response = await services.ads.loadStores();
        setListStores((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                [],
        });
        return response;
    };

    return {
        load: loadStores,
        list: listStores,
    };
};
