import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxIconButton: {
            default: {
                // 'backgroundColor': palette.common.white,
            },
            small: {
                height: rem(30),
                padding: rem(7),
            },
            medium: {
                padding: rem(11),
            },
        },
        sxIcon: {
            default: {
                color: palette.darkGray,
            },
            small: {
                fontSize: rem(16),
            },
            medium: {
                fontSize: rem(18),
            },
        },
        sxBadge: {
            medium: {
                '.MuiBadge-badge': {
                    fontSize: rem(10),
                    minWidth: rem(16),
                    width: rem(16),
                    height: rem(16),
                    fontWeight: typography.fontWeightSemiBold,
                    backgroundColor: palette.primaryColor,
                },
            },
            small: {
                '.MuiBadge-badge': {
                    fontSize: rem(8),
                    minWidth: rem(14),
                    width: rem(14),
                    height: rem(14),
                    fontWeight: typography.fontWeightSemiBold,
                    backgroundColor: palette.primaryColor,
                },
            },
        },
    };
    return { ...css };
};
