import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        box: {
            wrapper: {
                position: mediaQuery.lg ? 'static' : 'absolute',
                top: 0,
                paddingTop: mediaQuery.lg ? '0' : '58px',
                border:
                    mediaQuery.lg ? `1px solid ${palette.cityLights}` : 'none',
                borderBottom: `1px solid ${palette.cityLights}`,
                backgroundColor: palette.common.white,
                width: '100%',
                zIndex: 10,
                overflowY: 'auto',
            },
            divider: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
            },
            cashback: {
                '& > .MuiStack-root': {
                    '& > .MuiStack-root': {
                        '& > span': {
                            color: palette.mediumVioletRed,
                        },
                    },
                },
            },
        },
        texts: {
            section: {
                pt: rem(15),
                pb: rem(5),
                fontSize: rem(11),
                fontWeight: typography.fontWeightSemiBold,
            },
            statusOrder: {
                fontSize: rem(12),
                fontWeight: typography.fontWeightSemiBold,
                p: rem(15),
            },
            numberOrder: {
                fontSize: rem(14),
                fontWeight: typography.fontWeightSemiBold,
            },
        },
        linkInvoice: {
            'color': palette.mediumVioletRed,
            'textDecoration': 'none',
        },
        linkProduct: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            textDecoration: 'none',
            color: palette.common.black,
        },
        stack: {
            delivery: {
                'position': 'relative',
                'top': '2px',
                'color': palette.darkGray,
                '& > p': {
                    fontSize: rem(11),
                },
            },
        },
    };

    return { css };
};
