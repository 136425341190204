import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        stack: {
            border: '1px solid ' + palette.cityLights,
            borderRadius: rem(4),
            backgroundColor: mediaQuery.lg ?
                palette.common.white : palette.lightBg,
        },
        box: {
            wrapper: {
                margin: mediaQuery.lg ? 0 : `${rem(15)} !important`,
                border: `${rem(1)} solid ${palette.cityLights}`,
                backgroundColor: palette.common.white,
                borderRadius: rem(4),
            },
            password: {
                mt: mediaQuery.lg ? 'auto' : '0 !important',
            },
            row: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                display: 'flex',
                justifyContent: 'space-between',
            },
            avatar: {
                padding: rem(15),
            },
        },
        table: {
            body: {
                '&:last-child': {
                    ':last-child td': { border: 0 },
                },
            },
        },
        link: {
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'end',
            width: rem(50),
            height: rem(50),
        },
    };

    return { css };
};
