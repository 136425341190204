import React from 'react';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    useTheme,
} from '@mui/material';
import { rem } from '@utils';
import { months } from '@static/months';
import { useUser } from '@hooks/user';

export const ExtractMonths = () => {
    const { wallet } = useUser();
    const { typography, palette } = useTheme();
    const { pxToRem } = typography;

    const getActualMonth = () => {
        const month = new Date().getMonth()+1;
        if (month < 10) {
            return `0${month}`;
        } else {
            return `${month}`;
        }
    };

    const [selectState, setSelectState] = React.useState(getActualMonth());

    const handleSelect = (event: SelectChangeEvent) => {
        setSelectState(event.target.value);
    };

    const loadMovementsExpire = async () => {
        await wallet.movementExpire.load({
            month: selectState,
        });
    };

    React.useEffect(() => {
        loadMovementsExpire();
    }, [selectState]);

    return (
        <Box m={2}>
            <Typography
                variant='h4'
                fontSize={12}
                fontWeight={typography.fontWeightMedium}
                mt={pxToRem(10)}
                mb={pxToRem(10)}>
                Filtre por mês
            </Typography>
            <FormControl focused>
                <Select
                    variant='outlined'
                    labelId="select-installment"
                    id="installment"
                    sx={{
                        '& > .MuiSelect-select': {
                            'color': palette.common.white,
                            'padding': `${rem(8)} ${rem(16)}`,
                            'fontSize': `${rem(14)}`,
                            'backgroundColor': palette.mediumVioletRed,
                        },
                        '& > .MuiSvgIcon-root': {
                            color: palette.common.white,
                        },
                        '& > .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                    value={selectState}
                    onChange={handleSelect}
                >
                    {months.map((month) => (
                        <MenuItem
                            key={`${month.name}-${month.value}`}
                            sx={{ fontSize: '0.875rem' }}
                            value={month.value}>
                            {month.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};
