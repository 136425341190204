/* eslint-disable max-len */
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { sx } from './sx';

interface SectionTextProps {
    sectionName: string;
    value: string;
}

const SectionText: React.FC<SectionTextProps> = ({
    sectionName, value,
}: SectionTextProps) => {
    const { css } = sx();
    return (
        <Stack sx={{ ...css.stack }}>
            <Typography
                sx={{ ...css.texts.sectionName }}
                variant='h4'>
                {sectionName}
            </Typography>
            <Typography
                component={'div'}
                sx={{ ...css.texts.value }}
                dangerouslySetInnerHTML={{
                    __html: value,
                }} />
        </Stack>
    );
};

export { SectionText };
