import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { MenuItemProps } from '@interfaces';
import { sx } from './sx';
import { rem } from '@utils';

const MenuItem: React.FC<MenuItemProps> = ({
    size, title, legend, onClick, leftChildren, rightChildren,
}: MenuItemProps) => {
    const { css } = sx();

    return (
        <Box
            onClick={onClick}
            sx={{ ...css.box.wrapper.default, ...css.box.wrapper[size] }}>
            <Box display={'flex'} alignItems={'center'}>
                {leftChildren && leftChildren}
                <Stack direction={'column'}>
                    <Typography
                        sx={{ ...css.texts.title[size] }}
                        variant='caption'>
                        {title}
                    </Typography>
                    {legend &&
                        <Typography
                            sx={{ ...css.texts.legend[size] }}
                            variant='caption'>
                            {legend}
                        </Typography>
                    }
                </Stack>
            </Box>
            {rightChildren ? rightChildren :
                <Box pl={rem(10)}>
                    <I
                        style={{ ...css.icon[size] }}
                        icon={['far', 'chevron-right']} />
                </Box>
            }
        </Box>
    );
};

export { MenuItem };
