/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Header } from '../../header/dekstop/default';
import { MobileHeader } from '../../header/mobile/default';
import { Footer } from '../..';
import { Box } from '@mui/material';
import { useUser } from '@hooks/user';
import { skeleton } from './skeleton';
import { defaultRouteLayout } from '@static/noFooterPaths';
import { useGlobal } from '@hooks/global';
import { useLayout } from '@hooks/layout';
import { useAds } from '@hooks/ads';
import { styles } from '@utils';
import { sx } from './sx';
import { StoreType } from '@types';
import { useLoad } from '@hooks/load';

const noFooterPaths = [
    defaultRouteLayout.checkoutSuccess,
    defaultRouteLayout.cart,
];

export function RouterLayout() {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const { servicesLoad } = useLoad();

    const skel = skeleton();
    const user = useUser();
    const global = useGlobal();

    const isLoadedServices = (
        user.info.isLoaded
    );

    React.useEffect(() => {
        servicesLoad({
            loadList: [
                'user',
                'cart',
                'global',
                // 'adNews',
                // 'popularAds',
                // 'layoutBannersCarrousel',
            ],
            application: global.app.view.application,
        });
    }, []);

    React.useEffect(() => {
        servicesLoad({ loadList: ['favorites'], application: global.app.view.application });
    }, [global.app.view.application.type]);

    return (
        <div className="h-full">
            {mediaQuery.md && !isLoadedServices && skel.headerDefault}
            {!mediaQuery.md && !isLoadedServices && skel.headerMobile}

            {mediaQuery.md && (
                isLoadedServices &&
                <Header />
            )}
            {!mediaQuery.md && (
                isLoadedServices &&
                <MobileHeader
                    user={user.info}
                    cart={user.cart}
                    favorites={user.favorites}
                    global={global.app.view.application}
                />
            )}
            <Box sx={
                !mediaQuery.md ?
                    css.heightSize.mobile :
                    css.heightSize.desktop
            }>
                <Outlet />
            </Box>
            <ScrollRestoration />
            {mediaQuery.md ?
                <Footer.Default /> :
                !(noFooterPaths.find((item) => item === window.location.pathname)) &&
                <Footer.Mobile />
            }
        </div>
    );
}

export default RouterLayout;
