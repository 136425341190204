import { Box, Skeleton, Stack } from '@mui/material';

export const skeleton = () => {
    const listMovement = (
        <Box>
            <Stack spacing={2}>
                <Skeleton variant='rectangular' height={40} />
                <Skeleton variant='rectangular' height={40} />
            </Stack>
        </Box>
    );

    return { listMovement };
};
