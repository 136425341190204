import { client } from '@api';
import { UserContextProps } from '@interfaces/Contexts';
import {
    LoginProps,
    RecoveryPasswordProps,
    RecoveryPasswordTypeNewProps,
} from '@interfaces/Services';
import Cookies from 'universal-cookie';

export const useAuth = () => {
    const { services } = client();
    const cookies = new Cookies(null, { path: '/' });

    const login = async ({ login, password }: LoginProps) => {
        const response = await services.auth.login({ login, password });

        // if (!response?.error) {
        //     cookies.set('session', response?.response.accessToken);
        // }
        return response;
    };
    interface LoggoutProps {
        user: UserContextProps;
    }
    const logout = async ({ user }: LoggoutProps) => {
        const response = await services.auth.logout();

        if (!response?.error) {
            cookies.remove('session');
            cookies.remove('rewardhub-session');
            await user.load({ jwt: '' });
        }
        return response;
    };

    const recoveryPassword = async ({ ...data }: RecoveryPasswordProps) => {
        return await services.auth.recoveryPassword({ ...data });
    };

    const recoveryPasswordTypeNew = async ({ ...data }: RecoveryPasswordTypeNewProps) => {
        return await services.auth.recoveryPasswordTypeNew({ ...data });
    };

    return {
        login,
        logout,
        recoveryPassword: {
            send: recoveryPassword,
            typeNew: recoveryPasswordTypeNew,
        },
    };
};
