/* eslint-disable max-len */
import { Feedback } from '@components';
import {
    AlertColor,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Stack,
    TextField,
    Typography,
    debounce,
    useTheme,
} from '@mui/material';
import { rem } from '@utils';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import $ from 'jquery';
import 'jquery-mask-plugin';
import { statesList } from '@static/locations';
import { DEFAULTS } from './DEFAULTS';
import { useUser } from '@hooks/user';
import {
    AddressByZIPCodeProps,
    ListUserAddressProps,
    UserAddressForm,
} from '@interfaces/Services';
import { sx } from './sx';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';

interface AddressComponentTemplateProps {
    addressID?: string;
    navigateToAfterResponse?: string;
    navigateToWhenCancel?: string;
    fromPayment?: boolean;
    toCheckout?: boolean;
    externalAction?: () => void;
}

export const Address: React.FC<AddressComponentTemplateProps> = (
    { addressID, navigateToAfterResponse, fromPayment, toCheckout, externalAction }
    : AddressComponentTemplateProps,
) => {
    const debounceSave = React.useRef(
        debounce((value: string) => searchByZipCode(value), 1500),
    ).current;
    const { typography } = useTheme();
    const navigate = useNavigate();
    const param = useParams();
    const user = useUser();
    const { css } = sx();

    const paramType = param.type as 'adicionar' | 'editar';

    const [defaultChecked, setDefaultChecked] = React.useState(false);
    const [saveInAddress, setSaveInAddress] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [valueAutocomplete, setValueAutocomplete] =
        React.useState<string | null>('');
    const [isLoading, setIsLoading] = React.useState(DEFAULTS.loading);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm<UserAddressForm>({
        mode: 'all',
    });

    const handleClose = () => setOpen(false);

    const handleAddressCall = async (data: UserAddressForm) => {
        setIsLoading((prev) => prev = {
            ...prev,
            form: true,
        });
        const response = await user.address.handle({
            address: data,
            addressId: addressID,
        });

        setIsLoading(DEFAULTS.loading);
        setResponseData({
            type: response?.response.type,
            message: response?.response.message,
        });
        setOpen(true);

        return response;
    };

    const onSubmit: SubmitHandler<UserAddressForm> = async (data) => {
        if (fromPayment) {
            let response = {} as ResponseAsyncProps | undefined;
            if (saveInAddress) {
                response = await handleAddressCall(data);
            }
            if (!response?.error) {
                setTimeout(() => {
                    navigate('/formas-de-pagamento/adicionar', {
                        state: {
                            billingAddress: saveInAddress ?
                                response?.response.payload : data,
                        },
                    });
                }, saveInAddress ? 1000 : 0);
            }
        } else {
            const response = await handleAddressCall({
                ...data,
                main: toCheckout ? true : data.main ?? false,
            });
            if (!response?.error) {
                {toCheckout && await user.address.load();}
                {navigateToAfterResponse &&
                    setTimeout(() => {
                        navigate(navigateToAfterResponse + `${toCheckout ? '?addressID='+response?.response.payload._id : ''}`);
                    }, 1000);
                }
                {externalAction && externalAction();}
            }
        }
    };

    interface AutoCompleteAddressForm extends Partial<AddressByZIPCodeProps> {
        numero?: string;
        complemento?: string;
        apelido?: string;
        main?: boolean;
    }

    const autoCompleteForm = (address: AutoCompleteAddressForm) => {
        setDefaultChecked(address?.main as boolean);
        setValue('zipCode', address.cep as string);
        setValue('main', address.main as boolean);
        setValue('state', address.uf as string);
        setValueAutocomplete(address.uf as string);
        setValue('city', address.localidade as string);
        setValue('district', address.bairro as string);
        setValue('road', address.logradouro as string);
        setValue('number', address.numero as string);
        setValue('complement', address.complemento as string);
        setValue('nickname', address.apelido as string);
    };

    const loadUser = async () => {
        const response = await user.address.load();
        if (!response?.error) {
            const data = response?.response.payload;
            await loadAddressByID(data);
        }
    };

    const loadAddressByID = async (address: ListUserAddressProps[]) => {
        const addressList = address.find((address) => {
            return address._id === addressID;
        });
        if (addressList) {
            const address = {
                cep: addressList.zipCode,
                uf: addressList.state,
                localidade: addressList?.city,
                bairro: addressList?.district,
                logradouro: addressList?.road,
                numero: addressList.number,
                complemento: addressList.complement,
                apelido: addressList.nickname,
                main: addressList?.main,
            };
            autoCompleteForm(address);
        }
    };

    const searchByZipCode = async (zipCode: string) => {
        setIsLoading((prev) => prev = {
            ...prev,
            address: true,
        });
        const response = await user.address.searchByZipCode({ zipCode });
        if (!response.erro) {
            const address = response;
            reset({
                zipCode: address.cep,
                city: address.localidade,
                state: address.uf,
                road: address.logradouro,
                district: address.bairro,
            });
            setValueAutocomplete(address.uf);
        } else {
            reset({
                zipCode: '',
                city: '',
                state: '',
                road: '',
                district: '',
            });
            setValueAutocomplete('');
            setResponseData({
                type: 'error',
                message: 'CEP inválido',
            });
            setOpen(true);
        }
        setIsLoading(DEFAULTS.loading);
    };

    const handleSearchByZipCode = async (zipCode: string) => {
        await debounceSave(zipCode);
    };

    const inputMasks = () => {
        $('.cep').mask('00000-000');
    };

    React.useEffect(() => {
        inputMasks();
        setValue('state', 'PR');
    }, []);

    React.useEffect(() => {
        if (addressID) {
            loadUser();
        }
    }, [addressID]);
    return (
        <Box sx={{ position: 'relative' }}>
            {paramType && (
                <Box mb={rem(30)}>
                    <Typography
                        variant="body2"
                        component='h1'
                        fontSize={rem(18)}
                        color='darkGray'
                        fontWeight={typography.fontWeightBold}
                        gutterBottom>
                        {DEFAULTS.TEXTS[paramType].title}
                    </Typography>
                    <Typography
                        variant="body2"
                        component='h1'
                        fontSize={rem(14)}
                        color='darkGray'
                        fontWeight={typography.fontWeightMedium}>
                        Preencha os campos abaixo com os dados do endereço.
                    </Typography>
                </Box>
            )}
            {isLoading.address && (
                <Box sx={css.overlay}>
                    <CircularProgress color="inherit" size={20} />
                </Box>
            )}
            <Box component='form' onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        focused
                        label="CEP *"
                        id="cep"
                        helperText=''
                        variant="outlined"
                        error={!!errors.zipCode}
                        {...register('zipCode',
                            { required: true },
                        )}
                        onChange={(e) => handleSearchByZipCode(e.target.value)}
                        inputProps={{
                            className: 'cep',
                        }}
                    />
                    <Stack spacing={2} direction='row'>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            options={(statesList).map(
                                (state) => state.sigla)}
                            value={valueAutocomplete}
                            onChange={(event: any,
                                newValue: string | null) => {
                                setValueAutocomplete(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Estado"
                                    helperText={(!!errors.state?.message &&
                                        errors.state.message)}
                                    error={!!errors.state}
                                    {...register('state',
                                        { required: true },
                                    )}
                                />}
                        />

                        <TextField
                            fullWidth
                            focused
                            id="city"
                            label="Cidade *"
                            helperText=''
                            variant="outlined"
                            error={!!errors.city}
                            {...register('city',
                                { required: true },
                            )} />
                    </Stack>

                    <TextField
                        fullWidth
                        focused
                        id="district"
                        label="Bairro *"
                        helperText=''
                        variant="outlined"
                        error={!!errors.district}
                        {...register('district',
                            { required: true },
                        )} />
                    <Stack spacing={2} direction='row'>
                        <TextField
                            fullWidth
                            focused
                            id="street"
                            label="Rua *"
                            helperText=''
                            variant="outlined"
                            sx={{ maxWidth: '70%' }}
                            error={!!errors.road}
                            {...register('road',
                                { required: true },
                            )} />
                        <TextField
                            fullWidth
                            focused
                            id="number"
                            label="Número *"
                            helperText=''
                            variant="outlined"
                            sx={{ maxWidth: '30%' }}
                            error={!!errors.number}
                            {...register('number',
                                { required: true },
                            )} />
                    </Stack>

                    <TextField
                        fullWidth
                        focused
                        id="complement"
                        label="Complemento"
                        helperText=''
                        variant="outlined"
                        error={!!errors.complement}
                        {...register('complement',
                            { required: false },
                        )} />

                    <TextField
                        fullWidth
                        focused
                        id="nickname"
                        label="Apelido *"
                        helperText=''
                        variant="outlined"
                        error={!!errors.nickname}
                        {...register('nickname',
                            { required: true },
                        )} />
                    {fromPayment &&
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size='small'
                                        onChange={(e) =>
                                            setSaveInAddress(e.target.checked)}
                                    />
                                }
                                label="Salvar em meus endereços"
                            />
                        </FormGroup>
                    }
                    {(!fromPayment || saveInAddress ) && (!toCheckout) && <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={defaultChecked}
                                    size='small'
                                    {...register('main',
                                        { required: false },
                                    )}
                                    onChange={(e) => {
                                        setDefaultChecked(e.target.checked);
                                    }}
                                />
                            }
                            label="Salvar como endereço principal"
                        />
                    </FormGroup>}
                </Stack>
                <Stack
                    spacing={1}
                    justifyContent='space-between'
                    mt={rem(15)}>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        size='large'
                        disabled={isLoading.form}>
                        Salvar
                        {isLoading.form &&
                            <CircularProgress
                                color="inherit"
                                size={14}
                                sx={{ ml: 1 }}
                            />}
                    </Button>
                    <Button
                        fullWidth
                        type='button'
                        variant='text'
                        size='large'
                        onClick={() => fromPayment ?
                            navigate('/formas-de-pagamento/adicionar') :
                            toCheckout ? externalAction && externalAction() :
                                navigate('/meus-enderecos')}>
                        Cancelar
                    </Button>
                </Stack>
            </Box>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box >
    );
};
