import { HeaderPage } from '@components';
import { useLayout } from '@hooks/layout';
import { Box } from '@mui/material';
import { Outlet, ScrollRestoration } from 'react-router-dom';

export function RouterCentralizedLayoutMobile() {
    const { layout } = useLayout();
    return (
        <Box className="h-full" sx={{ overflow: 'hidden' }}>
            <HeaderPage
                icon={['far', 'chevron-left']}
                titleAlign={'center'}
                title={layout.mobile.header.title}
                onClick={layout.mobile.header.onBackAction}
            />
            <Box height='calc(100% - 58px)' sx={{
                overflowX: 'auto',
            }}>

                <Outlet />
                <ScrollRestoration />
            </Box>
        </Box>
    );
}

export default RouterCentralizedLayoutMobile;
