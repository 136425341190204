export const paymentMethods = [
    {
        _id: '63da6540599c',
        title: 'Cartão de crédito',
        icon: ['fal', 'credit-card-front'],
        type: 'CARD',
    },
    {
        _id: '80a7d95a0bfe',
        title: 'Pix',
        icon: ['fab', 'pix'],
        type: 'PIX',
    },
    {
        _id: '0e3930b9bafd',
        title: 'Boleto',
        icon: ['far', 'barcode'],
        type: 'SLIP',
    },
];

export const paymentMethodLabel = {
    CARD: {
        label: 'Pagar com Cartão de Crédito',
        text: '',
    },
    PIX: {
        label: 'Pagar com Pix',
        text: 'Seu código Pix terá validade de 10 minutos',
    },
    SLIP: {
        label: 'Pagar com Boleto',
        text: 'A aprovação do pagamento com o Boleto poderá levar até 3 dias úteis',
    },
};
