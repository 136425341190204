import { Box, Button, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { useUser } from '@hooks/user';

const Step5 = () => {
    const { css } = sx();
    const { purchase } = useUser();
    return (
        <Box p={2}>
            <Stack spacing={2} sx={{ ...css.stack.message }}>
                <Stack alignItems={'center'} spacing={1}>
                    <Box sx={{ ...css.box.check }}>
                        <I icon={['far', 'check']} />
                    </Box>
                    <Typography
                        sx={{ ...css.text.message }}
                        variant='h3'>
                        Devolução solicitada com sucesso!
                    </Typography>
                </Stack>
                <Button
                    size='large'
                    onClick={() => purchase.devolution.handle.step(6)}
                    variant='contained'>
                    Visualizar etiqueta de devolução
                </Button>
            </Stack>
            <Box pt={4}>
                <ul>
                    <li>
                        <Stack mb={3} spacing={1} direction={'row'}>
                            <I fontSize={8} icon={['fas', 'circle']} />
                            <Typography sx={{ ...css.text.p }}>
                                Após receber os seus produtos,
                                informaremos você via e-mail sobre
                                o resultado da sua devolução.
                            </Typography>
                        </Stack>
                    </li>
                    <li>
                        <Stack mb={3} spacing={1} direction={'row'}>
                            <I fontSize={8} icon={['fas', 'circle']} />
                            <Typography sx={{ ...css.text.p }}>
                                Fique de olho na caixa de entrada,
                                lixeira ou spam do seu e-mail.
                            </Typography>
                        </Stack>
                    </li>
                    <li>
                        <Stack mb={3} spacing={1} direction={'row'}>
                            <I fontSize={8} icon={['fas', 'circle']} />
                            <Typography sx={{ ...css.text.p }}>
                                Se sua devolução for aceita,
                                você receberá o estorno do pagamento em sua carteira
                            </Typography>
                        </Stack>
                    </li>
                    <li>
                        <Stack mb={3} spacing={1} direction={'row'}>
                            <I fontSize={8} icon={['fas', 'circle']} />
                            <Typography sx={{ ...css.text.p }}>
                                Caso sua devolução seja recusada,
                                devolveremos o produto para você
                            </Typography>
                        </Stack>
                    </li>
                </ul>
            </Box>
        </Box>
    );
};

export { Step5 };
