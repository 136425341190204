import { BannerProps } from '@interfaces/Services';

const RESPONSE = {
    isLoaded: false,
    message: '',
};


export const DEFAULTS = {
    mobile: {
        pageHeader: {
            title: '',
            onBackAction: () => {},
        },
    },
    banner: {
        carrousel: {
            list: {
                ...RESPONSE,
                data: [] as BannerProps[],
            },
        },
    },
};
