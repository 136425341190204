import React from 'react';
import { UserContext } from '../../contexts/user/context';
import { user } from './user';
import { userAddress } from './address';
import { UserContextProps } from '@interfaces/Contexts';
import { userFavorites } from './favorites';
import { userCart } from './cart';
import { userExtract } from './extract';
import { userPaymentForm } from './payment-form';
import { userPurchase } from './purchase';
import { userWallet } from './wallet';

export const userProvider = (): UserContextProps => {
    const userData = user();
    const address = userAddress();
    const favorites = userFavorites();
    const cart = userCart();
    const extract = userExtract();
    const paymentForm = userPaymentForm();
    const purchase = userPurchase();
    const wallet = userWallet();

    return {
        address,
        extract,
        paymentForm,
        purchase,
        wallet,
        ...userData,
        ...favorites,
        ...cart,
    };
};

export const useUser = () => {
    const context = React.useContext(UserContext);
    return context;
};
