import { useTheme } from '@mui/material';

const rem = (px: number): string => {
    const { typography } = useTheme();


    return typography.pxToRem(px);
};

export { rem };
