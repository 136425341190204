import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { useEffect, useState } from 'react';
import { useUser } from '@hooks/user';
import { MessageBox } from '@components';

interface ReasonProps {
    _id: string;
    reason: string;
}

const Step2 = () => {
    const { css } = sx();
    const { purchase } = useUser();

    const [reasons, setReasons] = useState({
        isLoaded: false,
        data: [] as ReasonProps[],
    });

    const loadReasons = async () => {
        const response = await purchase.devolution.loadReasons();

        if (!response?.error) {
            setReasons({
                isLoaded: true,
                data: response?.response?.payload,
            });
        } else {
            setReasons({
                isLoaded: true,
                data: [] as ReasonProps[],
            });
        }
    };

    useEffect(() => {
        loadReasons();
    }, []);


    return (
        <Box p={2}>
            <Box sx={{ ...css.box.title }}>
                <Typography
                    sx={{ ...css.text.title }}
                    variant='h3'>
                    Qual o motivo da devolução desse produto?
                </Typography>
            </Box>
            {reasons.isLoaded ?
                <>
                    {reasons.data.length > 0 ?
                        <Stack spacing={1}>
                            {reasons.data.map((item) => (
                                <Stack
                                    onClick={() => {
                                        purchase.devolution.handle.reason({
                                            reason: item._id,
                                        });
                                    }}
                                    key={item.reason}
                                    className={item._id === purchase.devolution.data
                                        .reason ? 'active' : ''}
                                    sx={{ ...css.reason.stack }}
                                    spacing={1} direction={'row'}>
                                    <Box
                                        className='box-check'>
                                        <I fontSize={10} icon={['far', 'check']} />
                                    </Box>
                                    <Typography
                                        className='text'
                                        variant='caption'>
                                        {item.reason}
                                    </Typography>
                                </Stack>

                            ))}
                        </Stack> :
                        <Box sx={{ ...css.box.info }}>
                            <MessageBox message={''} type={'error'} />
                        </Box>
                    }
                </> :
                <Box sx={{ ...css.box.info }}>
                    <CircularProgress color='inherit' size={26} />
                </Box>
            }
        </Box>
    );
};

export { Step2 };
