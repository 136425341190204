import {
    LoginProps,
    RecoveryPasswordProps,
    RecoveryPasswordTypeNewProps,
} from '@interfaces/Services';
import { client } from '../..';

export const login = async ({ login, password }: LoginProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/login', {
            login,
            password,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const logout = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/logout');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


export const recoveryPassword = async ({ ...data }: RecoveryPasswordProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/recovery-password/request', {
            ...data,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const recoveryPasswordTypeNew = async (
    { ...data }: RecoveryPasswordTypeNewProps,
) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/recovery-password/change', {
            ...data,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};
