/* eslint-disable max-len */
import React, { useState } from 'react';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { HeaderPage, Image, Price, OrderStatusTimeline, OrderStatusTimelineItem, MessageBox, Template } from '@components';
import { files, findOrderStatus, rem, styles } from '@utils';
import { useUser } from '@hooks/user';
import { OrderProps } from '@interfaces/Services';
import { sx } from './sx';
import { ProductItem } from '../components/product-item';
import { paymentMethods } from '@static/payment-methods';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AdType } from '@types';
import { Step6 } from '../actions/devolution-order/step6';
import { ToggleStaticProps } from '@interfaces/index';

const cashbackType = {
    MONEY: 'SHOPPING',
    POINTS: 'REDEMPTION',
};


const DetailsDevolution: React.FC = () => {
    const dialogTag = React.useRef<ToggleStaticProps>(null);

    const navigate = useNavigate();
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const { purchase } = useUser();
    const param = useParams();

    const [isLoadedOrder, setIsLoadedOrder] = useState<boolean>(false);
    const [order, setOrder] = useState<OrderProps | null>();
    const [recusedItem, setRecusedItem] = useState(false);

    const findOrder = async () => {
        if (purchase.orders.listDevolution.isLoaded) {
            if (purchase.orders.listDevolution.data.length > 0) {
                const order = purchase.orders.listDevolution.data.find((order) => order._id === param.id);
                !!order && setOrder(order);
                !! order && setRecusedItem(!!(order.statusHistory.find((item) => item.status === 'TAG_GENERATED_REFUSAL_DEVOLUTION')));
                setIsLoadedOrder(true);
            }
        } else {
            await purchase.orders.load();
        }
    };

    React.useEffect(() => {
        findOrder();
    }, [purchase.orders.list.data]);

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <Box
                width={'100%'}
                top={0}
                position={mediaQuery.lg ? 'relative' : 'fixed'}>
                <HeaderPage
                    onClick={() => navigate(-1)}
                    icon={['far', 'chevron-left']}
                    titleAlign={mediaQuery.lg ? 'default' : 'center'}
                    title={'Detalhe do pedido'}
                    noPadding/>
            </Box>
            {isLoadedOrder ?
                (!!order ?
                    <>
                        <Stack divider={ <Box sx={{ ...css.box.divider }}></Box> }>
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                    Parceiro
                                </Typography>
                                <Box display={'flex'}>
                                    <Image
                                        objectFit='contain'
                                        src={files.load(order.store.images.avatar, 'server')}
                                        alt={order.store.title}
                                        maxHeight={mediaQuery.md ? 36 : 30} />
                                </Box>
                            </Box>
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                    Número do pedido
                                </Typography>
                                <Typography
                                    sx={{ ...css.texts.numberOrder }}
                                    variant='body1'>
                                    {order._id}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{ ...css.texts.statusOrder }}
                                    variant='h4'>
                                    Status da devolução
                                </Typography>
                                {recusedItem ?
                                    <>
                                        <Box px={2} mb={2}>
                                            <Typography
                                                sx={{
                                                    fontSize: rem(12),
                                                }}
                                                variant='caption'>
                                                <I
                                                    style={{ marginRight: rem(5) }}
                                                    icon={['far', 'circle-info']} />
                                                    Sua devolução foi recusada pelo vendedor
                                            </Typography>
                                        </Box>
                                        <OrderStatusTimeline>
                                            <OrderStatusTimelineItem
                                                status={'TAG_GENERATED_REFUSAL_DEVOLUTION'}
                                                date={findOrderStatus('TAG_GENERATED_REFUSAL_DEVOLUTION', order).date}
                                                time={findOrderStatus('TAG_GENERATED_REFUSAL_DEVOLUTION', order).time}
                                                actual={order.currentStatus === 'TAG_GENERATED_REFUSAL_DEVOLUTION'}
                                                active={findOrderStatus('TAG_GENERATED_REFUSAL_DEVOLUTION', order).active} />
                                            <OrderStatusTimelineItem
                                                status={'POSTED_REFUSAL_DEVOLUTION'}
                                                date={findOrderStatus('POSTED_REFUSAL_DEVOLUTION', order).date}
                                                time={findOrderStatus('POSTED_REFUSAL_DEVOLUTION', order).time}
                                                actual={order.currentStatus === 'POSTED_REFUSAL_DEVOLUTION'}
                                                active={findOrderStatus('POSTED_REFUSAL_DEVOLUTION', order).active} />
                                            <OrderStatusTimelineItem
                                                status={'FINISHED_REFUSAL_DEVOLUTION'}
                                                date={findOrderStatus('FINISHED_REFUSAL_DEVOLUTION', order).date}
                                                time={findOrderStatus('FINISHED_REFUSAL_DEVOLUTION', order).time}
                                                actual={order.currentStatus === 'FINISHED_REFUSAL_DEVOLUTION'}
                                                active={findOrderStatus('FINISHED_REFUSAL_DEVOLUTION', order).active} />
                                        </OrderStatusTimeline>
                                    </> :
                                    <OrderStatusTimeline>
                                        <OrderStatusTimelineItem
                                            status={'TAG_GENERATED_DEVOLUTION'}
                                            date={findOrderStatus('TAG_GENERATED_DEVOLUTION', order).date}
                                            time={findOrderStatus('TAG_GENERATED_DEVOLUTION', order).time}
                                            actual={order.currentStatus === 'TAG_GENERATED_DEVOLUTION'}
                                            active={findOrderStatus('TAG_GENERATED_DEVOLUTION', order).active} />
                                        <OrderStatusTimelineItem
                                            status={'POSTED_DEVOLUTION'}
                                            date={findOrderStatus('POSTED_DEVOLUTION', order).date}
                                            time={findOrderStatus('POSTED_DEVOLUTION', order).time}
                                            actual={order.currentStatus === 'POSTED_DEVOLUTION'}
                                            active={findOrderStatus('POSTED_DEVOLUTION', order).active} />
                                        <OrderStatusTimelineItem
                                            status={'FINISHED_DEVOLUTION'}
                                            date={findOrderStatus('FINISHED_DEVOLUTION', order).date}
                                            time={findOrderStatus('FINISHED_DEVOLUTION', order).time}
                                            actual={order.currentStatus === 'FINISHED_DEVOLUTION'}
                                            active={findOrderStatus('FINISHED_DEVOLUTION', order).active} />
                                    </OrderStatusTimeline>
                                }
                            </Box>
                            {order.cashback.value > 0 &&
                                <Box px={rem(15)} pb={rem(15)}>
                                    <Typography
                                        sx={{ ...css.texts.section }}
                                        variant='h4'>
                                        Cashback
                                    </Typography>
                                    <Box sx={{ ...css.box.cashback }}>
                                        <Price
                                            size='small'
                                            type={cashbackType[order.cashback.type] as AdType}
                                            value={order.cashback.value} />
                                    </Box>
                                </Box>
                            }
                            {order.redemption &&
                                <Box px={rem(15)} pb={rem(15)}>
                                    <Typography
                                        sx={{ ...css.texts.section }}
                                        variant='h4'>
                                        Pontos usados no pedido
                                    </Typography>
                                    <Price size='small' type={'REDEMPTION'} value={order.redemption.totalPoints} />
                                </Box>
                            }
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                    Total
                                </Typography>
                                <Price size='small' type={'SHOPPING'} value={order.total} />
                            </Box>
                            <Box px={rem(15)} pb={rem(15)}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                        Forma de pagamento
                                </Typography>
                                <Stack alignItems={'center'} direction={'row'} spacing={0.6}>
                                    <Typography
                                        sx={{ ...css.texts.numberOrder }}
                                        variant='body1'>
                                        {paymentMethods.find((item) => item.type === order.payment.type)?.title}
                                    </Typography>
                                    <I fontSize={16} icon={paymentMethods.find((item) => item.type === order.payment.type)?.icon as IconProp} />
                                </Stack>
                            </Box>
                            {order.currentStatus === 'TAG_GENERATED_DEVOLUTION' &&
                                    <Box px={rem(15)} pb={rem(15)}>
                                        <Typography
                                            sx={{ ...css.texts.section }}
                                            variant='h4'>
                                            AÇÕES
                                        </Typography>
                                        <Typography
                                            sx={{ ...css.texts.section }}
                                            variant='h4'>
                                            Etiqueta
                                        </Typography>
                                        <Button
                                            onClick={() => dialogTag.current?.show()}
                                            variant='contained' size='medium'>
                                            Visualizar etiqueta
                                        </Button>
                                    </Box>
                            }
                            <Stack spacing={1} px={2} pb={1}>
                                <Typography
                                    sx={{ ...css.texts.section }}
                                    variant='h4'>
                                    Produtos
                                </Typography>
                                {order.devolution.itemsDevolution.map((itemAd, i) => (
                                    <Box key={`${itemAd.variation._id}-${i}`}>
                                        <ProductItem
                                            srcImage={files.load(itemAd?.variation?.images[0], 'server')}
                                            title={`${itemAd.ad.title}`} />
                                    </Box>
                                ))}
                            </Stack>
                        </Stack>
                        <Template.MuiDialog
                            ref={dialogTag}
                            fullScreen={!mediaQuery.sm}
                            maxWidth='sm'
                            title='Etiqueta de envio'
                            showDialogTitle
                            onConfirm={{
                                text: ['Fechar'],
                                action: () => dialogTag.current?.hide(),
                            }}>
                            <Step6 tagUrl={order.devolution.delivery.urlTags} />
                        </Template.MuiDialog>
                    </> :
                    <Box p={2}>
                        <MessageBox message={'Não foi possível encontrar o pedido'} type={'info'} />
                    </Box>
                ) :
                <Box display={'flex'} justifyContent={'center'} p={4}>
                    <CircularProgress
                        color='secondary'
                        size={36} />
                </Box>
            }
        </Box>
    );
};

export { DetailsDevolution };
