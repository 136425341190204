export * as shipping from './shipping';

import { client } from '@api';
import {
    AcceptDevolutionProps,
    CreatePurchaseProps,
    ReturnPurchaseProps,
} from '@interfaces/Services';


export const createPurchase = async ({ purchaseType, data }: CreatePurchaseProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post(`/purchase/${purchaseType}`, data);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const acceptDevolutionPurchase =
async ({ orderId, data }: AcceptDevolutionProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.
            put(`/purchase/accept-devolution/${orderId}`, data);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const returnPurchase =
async ({ orderId, data }: ReturnPurchaseProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.
            post(`/purchase/return/${orderId}`, data);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const loadReasonsReturn = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/reasons');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const listOrders = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/list/orders');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const listOrdersDevolution = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/list/orders?onlyDevolution=true');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const cancelOrder = async ({
    orderId,
}: { orderId: string }) => {
    const { instance, handleErrors, handleResponse } = client();
    try {
        const response = await instance.
            put(`/order/cancel/${orderId}`, {
                description: '',
            });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};
