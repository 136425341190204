/* eslint-disable max-len */
import { Breadcrumbs, Link, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { numbers, rem } from '@utils';
import { FILTERS_KEYS } from '@static/filters';
import { CONSTANTS } from '@static/constants';

interface BreadCrumbsListProps {
    _id: string;
    name: string;
}

interface BreadCrumbsProps {
    type?: 'category';
    list: BreadCrumbsListProps[];
}


const BreadcrumbsList: React.FC<BreadCrumbsProps> = ({
    list, type,
}: BreadCrumbsProps) => {
    const { typography } = useTheme();

    const handleLink = ({ _id, name }: BreadCrumbsListProps) => {
        if (type === 'category') {
            const params = {
                category: `${FILTERS_KEYS.CATEGORY_LIST}=${name.replace(' ', '+')}|${_id}`,
                limit: FILTERS_KEYS.LIMIT + '=' + CONSTANTS.SEARCH.LIMIT,
                aleatory: FILTERS_KEYS.ALEATORY + '=' +
                    numbers.randomNumber({ maxRange: 100 }),
            };

            return `/resultados/busca/?${params.category}&${params.limit}&${params.aleatory}`;
        }
    };

    return (
        <Breadcrumbs
            separator={<I
                icon={['far', 'chevron-right']}
                fontSize={9} />}
            aria-label="breadcrumb">
            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href="/"
                fontSize={12}
                fontWeight={typography.fontWeightSemiBold}
            >
                <I
                    icon={['fas', 'house']}
                    fontSize={12}
                    style={{
                        marginRight: rem(4),
                        position: 'relative',
                        top: '-1px',
                    }}
                />
                Home
            </Link>

            {list.map(({ _id, name }) => (
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href={handleLink({ _id, name })}
                    fontSize={12}
                    fontWeight={typography.fontWeightSemiBold}
                >
                    {name}
                </Link>
            ))}
        </Breadcrumbs>
    );
};

export { BreadcrumbsList };
