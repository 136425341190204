import {
    AddCoupomCallProps,
    AddItemCallProps,
    CartTypeCallProps,
    DeleteItemCallProps,
} from '@interfaces/Services';
import { client } from '../..';

export const load = async ({ cartType }: CartTypeCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(`/cart/${cartType}`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const addItem = async ({ cartType, data }: AddItemCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post(`/cart/${cartType}/item`, data);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const deleteItem = async ({ cartType, data }: DeleteItemCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.delete(`/cart/${cartType}/item`, {
            data: data,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const addCoupon = async ({ cartType, couponCode }: AddCoupomCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put(`/cart/${cartType}/coupon`, { couponCode });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const removeCoupon = async ({ cartType }: CartTypeCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.delete(`/cart/${cartType}/coupon`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const addPoints = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/cart/points');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const removePoints = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.delete('/cart/points');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const addWallet = async ({ cartType }: CartTypeCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put(`/cart/${cartType}/wallet`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const removeWallet = async ({ cartType }: CartTypeCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.delete(`/cart/${cartType}/wallet`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};
