import { client } from '@api';
import {
    UserServiceAddressForm,
    UserServiceDeleteAddress,
    ZIPCodeAddress,
} from '@interfaces/Services';
import axios from 'axios';

export const load = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/address');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


export const handle = async ({ address, addressId }: UserServiceAddressForm) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const id = addressId ? '?addressId=' + addressId : '';
        const response = await instance.put('/address' + id, {
            ...address,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const deleteAddress = async ({ addressId }: UserServiceDeleteAddress) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const id = addressId ? '?addressId=' + addressId : '';
        const response = await instance.delete('/address' + id);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const searchByZipCode = async ({ zipCode }: ZIPCodeAddress) => {
    try {
        const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);

        return response.data;
    } catch (error) {
        return error;
    }
};
