import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { rem } from '@utils';
import { sx } from './sx';

export interface FilterHeaderProps {
    title: string;
    bgColor?: string;
    onClose?: () => void;
}

export const FilterHeader: React.FC<FilterHeaderProps> = (
    { title, bgColor, onClose }: FilterHeaderProps,
) => {
    const { typography, palette } = useTheme();
    const { css } = sx();

    return (
        <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={css.drawer.head(bgColor)}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <I
                    icon={['far', 'sliders']}
                    color={palette.darkGray}
                    fontSize={rem(15)}
                />
                <Typography
                    color="darkGray"
                    fontSize={rem(14)}
                    fontWeight={typography.fontWeightSemiBold}
                    ml={rem(5)}
                >
                    {title}
                </Typography>
            </Box>
            {onClose && (
                <IconButton
                    onClick={onClose}
                    sx={css.drawer.closeButton}>
                    <I
                        icon={['far', 'xmark']}
                        color={palette.darkGray}
                        fontSize={rem(18)}
                    />
                </IconButton>
            )}
        </Stack>
    );
};
