import { client } from '@api';
import { LoadSizeGroupsByIDProps, LoadSizeGroupsProps } from '@interfaces/Services';
import React from 'react';
import { DEFAULTS } from './DEFAULTS';

export const sizes = () => {
    const { services } = client();

    const [sizeGroups, setSizeGroups] = React.useState(DEFAULTS.SIZES.SIZE_GROUPS);
    const [sizeGroupsByID, setSizeGroupsByID] = React.useState(
        DEFAULTS.SIZES.SIZE_GROUPS_BY_ID,
    );

    const loadSizeGroups = async ({ categorizations }: LoadSizeGroupsProps) => {
        const response = await services.ads.loadSizeGroups({ categorizations });
        setSizeGroups((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                [],
        });
        return response;
    };

    const loadSizeGroupByID = async ({ sizeGroupId }: LoadSizeGroupsByIDProps) => {
        const response = await services.ads.loadSizeGroupsByID({ sizeGroupId });
        setSizeGroupsByID((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                [],
        });
        return response;
    };

    return {
        load: {
            sizeGroups: loadSizeGroups,
            sizeGroupsByID: loadSizeGroupByID,
        },
        list: {
            sizeGroups,
            sizeGroupsByID,
        },
    };
};
