import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxBox: {
            'position': 'relative',
            'paddingTop': `${rem(5)}`,
            'paddingBottom': `${rem(10)}`,
            'maxWidth': '100%',
            // eslint-disable-next-line max-len
            '& > .product-pictures > .slick-dots.slick-thumb > li.slick-active > a::after': {
                backgroundColor: palette.primaryColor,
            },
        },
        sxBoxImg: {
            borderRadius: rem(4),
            border: `${rem(1)} solid ${palette.cityLights}`,
        },
        sxBoxDots: {
            display: 'flex',
            width: rem(50),
            height: rem(50),
            borderRadius: rem(4),
            border: `${rem(1)} solid ${palette.cityLights}`,
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
    return {
        ...css,
    };
};
