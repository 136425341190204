import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = (active: boolean) => {
    const { palette } = useTheme();
    const css = {
        sxButton: {
            minWidth: rem(36),
            minHeight: rem(36),
            padding: '0.5rem 0.75rem',
            backgroundColor: active ? '#C8E6C9' : 'transparent',
            color: palette.darkGray,
            fontSize: active ? rem(12) : rem(14),
        },
        sxText: {
            fontSize: active ? rem(12) : rem(14),
        },
        sxIconActive: {
            marginLeft: rem(4),
        },
    };

    return { css };
};
