import React from 'react';

import {
    useParams,
    Link,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    Grid,
    Typography,
    Box,
    IconButton,
    Container,
} from '@mui/material';

import { questionsData } from '@static/faq';
import { MessageBox } from '@components';
import { sx } from './sx';

const Answer: React.FC = () => {
    const { questionId } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const answer = questionsData.find((obj) => obj.id === questionId);
    const { css } = sx();

    return (
        <Container>
            <Box sx={{ ...css.sxBoxContainer }}>
                <Link
                    to={(state && state.fromSearch ?
                        '../central-de-ajuda/resultados' :
                        answer ? '/central-de-ajuda/'+ answer?.urlTopic :
                            '/central-de-ajuda')}
                    onClick={(e) => {
                        if (state && state.fromSearch) {
                            e.preventDefault();
                            navigate(-1);
                        }
                    }}
                    style={{ ...css.sxLink }}>
                    <Typography
                        sx={{ ...css.sxTextLink }} variant='caption'>
                        <IconButton sx={{ ...css.sxIconLinkBack }}>
                            <I icon={['far', 'arrow-left-long']} />
                        </IconButton>
                            Voltar para {state && state.fromSearch ?
                            'resultados' : answer ? answer.topic : 'Central de ajuda'}
                    </Typography>
                </Link>
                <Box sx={{ ...css.sxBoxGrid }}>
                    <Grid container spacing={2}>
                        {answer ?
                            <Grid item md={8} lg={8} sm={12} xs={12}>
                                <Typography sx={{ ...css.sxTitle }} variant='h2'>
                                    {answer?.question}
                                </Typography>
                                <Box
                                    sx={{ ...css.sxBoxAnswer }}>
                                    <Typography
                                        dangerouslySetInnerHTML={{
                                            __html: answer?.answer as string }}
                                        sx={{ ...css.sxAnswerText }}
                                        variant='body1'>
                                    </Typography>
                                </Box>
                                {state && state.fromSearch &&
                                    <Link
                                        to={'/central-de-ajuda/'+ answer?.urlTopic}
                                        style={{ ...css.sxLink }}>
                                        <Typography
                                            sx={{ ...css.sxTextLink }} variant='caption'>
                                            Saiba mais em {answer?.topic}
                                        </Typography>
                                    </Link>
                                }
                            </Grid> :
                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                <MessageBox
                                    message={'Não foi possível encontrar resultados'}
                                    type={'info'} />
                            </Grid>

                        }
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export { Answer };
