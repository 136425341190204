import React from 'react';
import {
    MessageBox,
    WalletExtractTimeline,
    WalletExtractTimelineItem,
} from '@components';
import { Button, Stack } from '@mui/material';
import { useUser } from '@hooks/user';
import { skeleton } from './skeleton';

export const WalletDetails: React.FC = () => {
    const user = useUser();
    const skel = skeleton();

    const [limitList, setLimitList] = React.useState<number>(3);

    return (
        <WalletExtractTimeline>
            {user.wallet.movementHistory.list.isLoaded ?
                (user.wallet.movementHistory.list.data.length > 0 ?
                    user.wallet.movementHistory.list.data.slice(0, limitList)
                        .map((item, i) => (
                            <WalletExtractTimelineItem
                                key={`details-extract-${i}`}
                                title={item.description}
                                type={item.type}
                                currency={item.currency}
                                value={item.value}
                                status={item.status}
                                date={item.date}
                                lastItem={i === limitList -1 ||
                                    i === user.wallet.movementHistory.list.data.length -1
                                } />
                        )) :
                    <WalletExtractTimeline>
                        <MessageBox
                            message={user.wallet.movementHistory.list.error ?
                                user.wallet.movementHistory.list.message :
                                'Nenhum registro encontrado'}
                            type={'info'} />
                    </WalletExtractTimeline>
                ) :
                <WalletExtractTimeline>
                    {skel.listMovement}
                </WalletExtractTimeline>}
            {user.wallet.movementHistory.list.isLoaded &&
            user.wallet.movementHistory.list.data.length > 0 &&
                <Stack justifyContent={'center'}>
                    {limitList < user.wallet.movementHistory.list.data.length ?
                        <Button
                            onClick={() => setLimitList(limitList + 3)}
                            variant='text'>
                            Mostrar mais
                        </Button> :
                        !(user.wallet.movementHistory.list.data.length <= 3) &&
                        <Button
                            onClick={() => setLimitList(3)}
                            variant='text'>
                            Mostrar menos
                        </Button>
                    }
                </Stack>
            }
        </WalletExtractTimeline>
    );
};
