import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

interface HistoryBackMenuItemProps {
    title: string;
    onClick: () => void;
    loading?: boolean;
}

import { sx } from './sx';

export const HistoryBackMenuItem: React.FC<HistoryBackMenuItemProps> = ({
    title, onClick, loading,
}: HistoryBackMenuItemProps) => {
    const { css } = sx();
    const { palette } = useTheme();
    return (
        <Box
            sx={css.sxBox}
            onClick={loading ? () => { } : onClick}>
            {loading && (
                <Box sx={css.overlay} />
            )}
            {!loading ? (
                <I
                    style={css.sxIcon}
                    icon={['far', 'chevron-left']} />
            ) : (
                <CircularProgress
                    size={14}
                    sx={{ color: palette.darkGray }}
                />
            )}
            <Typography
                variant='h3'
                sx={css.sxTitle}>
                {title}
            </Typography>
        </Box>
    );
};
