import { Box, Stack, Typography } from '@mui/material';
import { sx } from './sx';

const Step3 = () => {
    const { css } = sx();
    return (
        <Box p={2}>
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <Typography
                        sx={{ ...css.text.title }}
                        variant='h2'>
                        Informações do reembolso
                    </Typography>
                    <Typography
                        sx={{ ...css.text.p }}
                        variant='body1'>
                        O estorno do pagamento será feito após o
                        recebimento e análise dos produtos devolvidos.
                    </Typography>
                </Stack>
                <Stack spacing={1}>
                    <Typography
                        sx={{ ...css.text.title }}
                        variant='h2'>
                        PIX ou Boleto
                    </Typography>
                    <Typography
                        sx={{ ...css.text.p }}
                        variant='body1'>
                        Se você fez o pagamento via pix ou boleto, o valor do reembolso
                        será creditado na sua carteira de créditos para usar em compras
                        futuras no site ou realizar o saque do valor para sua conta
                        bancária.
                    </Typography>
                </Stack>
                <Stack spacing={1}>
                    <Typography
                        sx={{ ...css.text.title }}
                        variant='h2'>
                        Cartão de crédito
                    </Typography>
                    <Typography
                        sx={{ ...css.text.p }}
                        variant='body1'>
                        Se você realizou o pagamento via cartão de crédito, o estorno
                        será realizado de forma automática em sua fatura.
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    );
};

export { Step3 };
