import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ContentCreditCard = () => {
    const { palette, typography } = useTheme();
    const { pxToRem } = typography;
    const navigate = useNavigate();

    return (
        <Box>
            <Typography
                variant='h2'
                component='p'
                align='center'
                fontSize={pxToRem(16)}
                fontWeight={typography.fontWeightMedium}
                color={palette.dark}
                mb={pxToRem(20)}>
                Assim que o pagamento for aprovado, daremos sequência ao seu pedido!
            </Typography>

            <Box
                display='flex'
                justifyContent='center'
                mt={4}
                mb={4}>
                <Box
                    maxWidth={320}
                    width='100%'
                    justifyContent='center'>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type='submit'
                        onClick={() => navigate('/')}
                    >
                        Continuar comprando
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export { ContentCreditCard };
