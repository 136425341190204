import React from 'react';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { rem } from '@utils';
import { AvatarGroupProps } from '@interfaces';
import {
    customMargin,
    handleBorder,
    avatarSizes,
    fontSizeTitle,
    fontSizeLegend,
    sx,
} from './sx';

const AvatarGroup: React.FC<AvatarGroupProps> = ({
    align,
    legend,
    title,
    size,
    src,
    borderWidth,
    borderColor,
    titleCapitalize,
}: AvatarGroupProps) => {
    const { typography } = useTheme();
    const { css } = sx();
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            ...css.sxAvatarGroup[align],
        }}>
            <Avatar
                alt={title}
                src={src}
                sx={{
                    border: handleBorder({
                        borderColor: borderColor,
                        borderWidth: borderWidth,
                    }),
                    ...avatarSizes[size],
                    ...css.sxAvatarDefault }}
            />
            <Box sx={{
                ...css.sxBoxAvatarInfo.default,
                ...css.sxBoxAvatarInfo[align],
                marginTop: customMargin[align][size],
            }}>
                <Typography
                    variant='h2'
                    textAlign={align !== 'right' ? 'center' : 'start'}
                    fontSize={fontSizeTitle[size]}
                    fontWeight={typography.fontWeightBold}
                    textTransform={titleCapitalize ? 'capitalize' : 'inherit'}>
                    {title}
                </Typography>
                <Typography
                    sx={{
                        maxWidth: rem(568),
                        wordBreak: 'break-word',
                    }}
                    variant='body1'
                    textAlign={align !== 'right' ? 'center' : 'start'}
                    fontSize={fontSizeLegend[size]}
                    fontWeight={typography.fontWeightMedium}>
                    {legend}
                </Typography>
            </Box>
        </Box>
    );
};

export { AvatarGroup };
