import { rem, styles } from '@utils';
import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        box: {
            wrapper: {
                backgroundColor: palette.lightBg,
                pt: rem(mediaQuery.md ? 10 : 15),
                pb: rem(16),
                height: '100%',
                minHeight: mediaQuery.md ?
                    'calc(100vh - 238px)' :
                    'calc(100vh - 255px)',
            },
            sidebar: {
                wrapper: {
                    width: mediaQuery.md ? rem(279) : '100%',
                    backgroundColor: '#fff',
                },
                filters: {
                    backgroundColor: palette.common.white,
                    minWidth: rem(280),
                    p: rem(15),
                    pt: 0,
                    mr: rem(15),
                },
            },
        },
        texts: {
            results: {
                '&>span': {
                    fontWeight: typography.fontWeightMedium,
                },
            },
            filters: {
                '&>span': {
                    display: 'block',
                },
            },
        },
        stack: {
            selectedFilters: {
                overflowX: 'auto',
                pt: rem(15),
                pb: rem(10),
            },
        },
        button: {
            relevants: {
                border: '1px solid ' + palette.mediumVioletRed + ' !important',
                fontSize: '12px',
                color: palette.mediumVioletRed,
                pt: rem(8),
                pb: rem(8),
            },
        },
    };

    return { css };
};
