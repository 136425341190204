import React from 'react';
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { money, numbers, rem } from '@utils';
import { SubmitHandler, useForm } from 'react-hook-form';

import $ from 'jquery';
import 'jquery-mask-plugin';
import { TempFilterProps } from '@interfaces/Filters';
import { useFilters } from '@hooks/filters';
import { useSearchParams } from 'react-router-dom';
import { FILTERS_KEYS } from '@static/filters';
import { FiltersType } from '@types';

interface FiltersPriceForm {
    min: string;
    max: string;
}

export const Price = () => {
    const { typography, palette } = useTheme();
    const { handleFilters, filters, externalActions } = useFilters();

    const [tempPrice, setTempPrice] = React.useState<TempFilterProps[]>([]);
    const [search, setSearch] = useSearchParams();

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm<FiltersPriceForm>({
        mode: 'onSubmit',
    });


    const handlePrice = (sizes: string[]) => {
        if (sizes.length === 0) {
            search.delete(FILTERS_KEYS.VALUE);
        } else {
            search.set(FILTERS_KEYS.VALUE, sizes.join(','));
            search.set(FILTERS_KEYS.ALEATORY,
                numbers.randomNumber({ maxRange: 100 }).toString());
        }
        setSearch(search);
    };

    const onSubmit: SubmitHandler<FiltersPriceForm> = async (data) => {
        const min = money.format(data.min);
        const max = money.format(data.max);
        if (min > max) {
            setError('max', {
                message: '',
            });
            return;
        }

        const priceFilterList = [
            {
                _id: 'f45d4556d',
                name: min.toString(),
                filterType: FILTERS_KEYS.VALUE,
            },
            {
                _id: 'c45d4f5d6f',
                name: max.toString(),
                filterType: FILTERS_KEYS.VALUE,
            },
        ] as TempFilterProps[];

        const query = [`${min}|f45d4556d`, `${max}|c45d4f5d6f`];
        handlePrice(query);
        handleFilters(FILTERS_KEYS.VALUE as FiltersType, priceFilterList);
        externalActions.action({ action: 'valueRange', state: true });
    };

    const priceFiledValue = (filter: TempFilterProps[], _id: string) => {
        return (filter.length > 0) ?
            filter.find((price) => price._id === _id)?.name ?? '' :
            '';
    };

    React.useEffect(() => {
        $('.money').mask('000.000.000.000.000,00', {
            reverse: true,
        });
        externalActions.action({ action: 'valueRange', state: false });
    }, []);

    React.useEffect(() => {
        setTempPrice(filters.valueRange);
    }, [filters.valueRange]);

    React.useEffect(() => {
        setTimeout(() => {
            setValue('min', priceFiledValue(tempPrice, 'f45d4556d'));
            setValue('max', priceFiledValue(tempPrice, 'c45d4f5d6f'));
        }, 100);
    }, [tempPrice]);

    return (
        <Box mt={1} component='form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        focused={true}
                        label='Minimo'
                        id="min-input"
                        helperText=''
                        variant="outlined"
                        size='small'
                        error={!!errors.min}
                        inputProps={{
                            className: 'money',
                        }}
                        {...register('min',
                            { required: true },
                        )}
                        sx={{
                            '& > .MuiInputBase-root > input': {
                                fontSize: 14,
                                fontWeight: typography.fontWeightSemiBold,
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography
                                        variant="h1"
                                        fontSize={rem(13)}
                                        fontWeight={typography.fontWeightSemiBold}
                                        color={palette.dark}
                                        fontFamily='arial'
                                        sx={{
                                            position: 'relative',
                                            top: 1,
                                        }}
                                    >
                                        R$
                                    </Typography>
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
                <Grid item
                    xs={2}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'>
                    <I
                        icon={['fas', 'arrow-right']}
                        color={palette.darkGray}
                        fontSize={rem(14)}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        focused={true}
                        label='Máximo'
                        id="min-input"
                        helperText=''
                        variant="outlined"
                        size='small'
                        error={!!errors.max}
                        inputProps={{
                            className: 'money',
                        }}
                        {...register('max',
                            { required: true },
                        )}
                        sx={{
                            '& > .MuiInputBase-root > input': {
                                fontSize: 14,
                                fontWeight: typography.fontWeightSemiBold,
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography
                                        variant="h1"
                                        fontSize={rem(13)}
                                        fontWeight={typography.fontWeightSemiBold}
                                        color={palette.dark}
                                        fontFamily='arial'
                                        sx={{
                                            position: 'relative',
                                            top: 1,
                                        }}
                                    >
                                        R$
                                    </Typography>
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </Grid>
            <Box mt={rem(15)}>
                <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    size='medium'
                    className='MuiBlack'>
                    Aplicar
                </Button>
            </Box>
        </Box>
    );
};
