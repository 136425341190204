import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        sxLink: {
            textDecoration: 'none',
            color: palette.customBlack,
        },
        sxBox: {
            'height': '100%',
            'backgroundColor': palette.common.white,
            'border': `${rem(1)} solid ${palette.clouds}`,
            'borderRadius': rem(4),
            'padding': rem(15),
            'cursor': 'pointer',
            '&:hover': {
                'span': {
                    textDecoration: 'underline',
                },
            },
        },
        sxBoxTexts: {
            padding: mediaQuery.md ?
                `${rem(8)} ${rem(15)}` : `${rem(4)} ${rem(8)}`,
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            pb: rem(10),
            fontSize: mediaQuery.md ? rem(18) : rem(16),
        },
        sxLegend: {
            fontWeight: typography.fontWeightMedium,
            fontSize: mediaQuery.md ? rem(14) : rem(12),
        },
        sxBoxButton: {
            mt: rem(8),
            py: rem(8),
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
        },
        sxLinkText: {
            color: palette.customBlack,
            fontWeight: typography.fontWeightSemiBold,
            fontSize: mediaQuery.md ? rem(14) : rem(12),
        },
        sxIconButton: {
            padding: '0',
            paddingLeft: rem(7),
            fontSize: rem(14),
            color: palette.customBlack,
        },
    };
    return { css };
};
