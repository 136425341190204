import React from 'react';
import { Money } from './components/money';
import { Points } from './components/points';
import { PriceProps } from '@interfaces';

const Price: React.FC<PriceProps> = ({ type, value, discount, size }: PriceProps) => {
    return (
        <>
            {type === 'SHOPPING' && (
                <Money value={value} discount={discount} size={size} />
            )}

            {type === 'REDEMPTION' && (
                <Points value={value} discount={discount} size={size} />
            )}
        </>
    );
};

export { Price };
