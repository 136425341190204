/* eslint-disable max-len */
export const topics = [
    {
        topic: 'Sobre o Reward Hub',
        url: 'sobre-o-reward',
        description: 'Entenda melhor nossa plataforma',
    },
    {
        topic: 'Resgate de produtos',
        url: 'resgate',
        description: 'Sobre prazo de entrega, processo de resgate e frete.',
    },
    {
        topic: 'Cancelamentos e Devoluções',
        url: 'cancelamentos-e-devoluções',
        description: 'Sobre prazos, condições e procedimento para cancelamentos e devoluções',
    },
];

export const questionsData = [
    {
        id: '72835cd1a63d',
        question: 'O que é o Reward Hub?',
        topic: 'Sobre o Reward Hub',
        urlTopic: 'sobre-o-reward',
        answer: 'O Reward Hub é um catálogo para resgate, você pode utilizar o seu saldo de pontos para resgatar itens do nosso catálogo.',
    },
    {
        id: '2a9acdf732f8',
        question: 'Quais são as opções de frete disponíveis?',
        topic: 'Resgate de produtos',
        urlTopic: 'resgate',
        answer: 'As opções de frete disponíveis hoje são Correios e Transportadora.',
    },
    {
        id: '5e6933ae8440',
        question: 'Por que vejo mais de uma taxa de frete no meu carrinho?',
        topic: 'Resgate de produtos',
        urlTopic: 'resgate',
        answer: 'Nosso modelo de estoque é descentralizado, ou seja, cada parceiro tem o seu estoque próprio. Quando você compra itens de mais de um parceiro, cada um deles enviará o produto do seu endereço de origem e, por isso, você poderá ver no seu carrinho múltiplos valores de fretes - um para cada parceiro.',
    },
    {
        id: 'e1ebc8e34cbf',
        question: 'Como funciona o processo de resgate?',
        topic: 'Resgate de produtos',
        urlTopic: 'resgate',
        answer: 'Você pode usar o saldo de pontos disponíveis na sua carteira para resgatar um produto e caso o valor do produto exceda o valor dos pontos disponíveis, você poderá completar a sua compra com valor em reais pagando através de PIX, BOLETO ou CARTÃO DE CRÉDITO.',
    },
    {
        id: '9097099ab6b7',
        question: 'Como posso acompanhar o status do meu pedido?',
        topic: 'Resgate de produtos',
        urlTopic: 'resgate',
        answer: 'Acesse o site com seu login e senha, clique em “meus pedidos” e você poderá acompanhar o andamento do envio.',
    },
    {
        id: 'dad07c6624bf',
        question: 'Fiz um resgate no site, mas não recebi a confirmação, como vou saber se meu pedido foi efetivado?',
        topic: 'Resgate de produtos',
        urlTopic: 'resgate',
        answer: 'Acesse o site com seu login e senha, clique em “Meus pedidos”. Você irá visualizar o número do pedido e o status do mesmo.',
    },
    {
        id: '50aa4014b7d1',
        question: 'Se eu realizar um resgate e precisar completar meu pedido com reais e não efetuar o pagamento da fatura, o que acontece?',
        topic: 'Resgate de produtos',
        urlTopic: 'resgate',
        answer: 'Caso você não realize o pagamento da fatura, seu pedido será cancelado e seus pontos estornados para sua carteira.',
    },
    {
        id: '0816349e19eb',
        question: 'Qual a diferença entre cancelamento e devolução?',
        topic: 'Cancelamentos e Devoluções',
        urlTopic: 'cancelamentos-e-devoluções',
        answer: 'O cancelamento acontece quando você desiste da compra antes mesmo do item ter sido enviado para você. Já a devolução acontece quando você recebe o item, desiste da compra e envia o produto de volta para o parceiro.',
    },
    {
        id: 'cdc7bd332240',
        question: 'Como faço para cancelar?',
        topic: 'Cancelamentos e Devoluções',
        urlTopic: 'cancelamentos-e-devoluções',
        answer: 'O cancelamento pode ser realizado até 3 dias após sua compra, desde que o produto ainda não tenha sido enviado pelo parceiro. <br /> Acesse “meus pedidos” e escolha a opção de “cancelar pedido”. Após isso, seus pontos serão estornados para a sua carteira e caso tenha usado reais para completar a compra o estorno funciona da seguinte forma: <br /> <br /> Cartão de Crédito - o estorno é realizado em até 30 dias automaticamente em sua fatura (esse prazo pode variar, consulte a sua operadora de cartão).  <br /> <br /> Pix- estornamos o valor para sua carteira de créditos, onde você pode usar o saldos para futuros resgates ou sacar o dinheiro para sua conta bancária.  <br /> <br /> Boleto- estornamos o valor para sua carteira de créditos, onde você pode usar o saldos para futuros resgates ou sacar o dinheiro para sua conta bancária.',
    },
];

