
export const months = [
    {
        value: '01',
        name: 'Janeiro',
    },
    {
        value: '02',
        name: 'Fevereiro',
    },
    {
        value: '03',
        name: 'Março',
    },
    {
        value: '04',
        name: 'Abril',
    },
    {
        value: '05',
        name: 'Maio',
    },
    {
        value: '06',
        name: 'Junho',
    },
    {
        value: '07',
        name: 'Julho',
    },
    {
        value: '08',
        name: 'Agosto',
    },
    {
        value: '09',
        name: 'Setembro',
    },
    {
        value: '10',
        name: 'Outubro',
    },
    {
        value: '11',
        name: 'Novembro',
    },
    {
        value: '12',
        name: 'Dezembro',
    },
];

