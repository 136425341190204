import { client } from '@api';
import { AdType } from '@types';

export const calculateValue = async (cartType: AdType) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(`/shipping/${cartType}`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const generateTag = async (orderId: string) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(`/shipping/order/${orderId}/print/tags`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const trackingUrl = async (orderId: string) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(`/shipping/order/${orderId}/tracking`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};
