import { ContextsProps } from '@interfaces/Contexts';
import { CustomThemeContext } from './context';
import { customThemeProvider } from '@hooks/custom-theme';


export const CustomThemeProvider = ({ children }: ContextsProps) => {
    const providerValue = customThemeProvider();

    return (
        <CustomThemeContext.Provider value={providerValue}>
            {children}
        </CustomThemeContext.Provider>
    );
};
