/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { AddressBox, ButtonIcon, HeaderPage, MenuItem, PaymentMethodCard } from '@components';
import { Box, Button, Container, Skeleton, Stack } from '@mui/material';
import { formatAddress, rem } from '@utils';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useUser } from '@hooks/user';
import React from 'react';
import { sx } from './sx';
import { cardFlagsIcon } from '@static/card-flags';
import { CreditCardFlagType } from '@types';

export const List: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();
    const user = useUser();

    React.useEffect(() => {
        user.paymentForm.current.clear();
        user.paymentForm.load();
    }, []);

    return (
        <Box sx={css.wrapper}>
            <HeaderPage
                noPadding
                onClick={() => navigate(-1)}
                icon={['far', 'map-location-dot']}
                titleAlign={'default'}
                title={'Formas de pagamento'}
                action={
                    user.paymentForm.list.isLoaded &&
                    (user.paymentForm.list?.data?.length > 0) && (
                        <Button
                            variant='contained'
                            size="small"
                            onClick={() => navigate('/formas-de-pagamento/adicionar')}>
                            Adicionar
                            <I
                                fontSize={rem(12)}
                                icon={['fas', 'plus']}
                            />
                        </Button>)
                }
            />
            <Box mt={rem(15)} mb={rem(15)}>
                <Container maxWidth='lg'>
                    {user.paymentForm.list.isLoaded ? (
                        user.paymentForm.list.data?.length > 0 ?
                            user.paymentForm.list.data?.map((item, i, list) => (
                                list.length !== i && (
                                    <Stack key={item._id}>
                                        <PaymentMethodCard
                                            borderStyle='bottom'
                                            srcFlag={cardFlagsIcon[item.flag as CreditCardFlagType]}
                                            nameFlag={item.flag}
                                            numberCard={item.number}
                                            rightChildren={
                                                <ButtonIcon
                                                    onClick={() => {
                                                        user.paymentForm.current.handle({
                                                            _id: item._id,
                                                            cardToken: '',
                                                            cvv: '',
                                                            document: item.document,
                                                            expirationMonth: item.expirationMonth,
                                                            expirationYear: Number(item.expirationYear),
                                                            flag: item.flag,
                                                            name: item.name,
                                                            idKeyCard: '',
                                                            number: item.number,
                                                        });
                                                        navigate('/formas-de-pagamento/editar', {
                                                            state: {
                                                                billingAddress: item.billingAddress,
                                                            },
                                                        });
                                                    }}
                                                    size={'medium'} icon={'chevron-right'}/>
                                            } />
                                    </Stack>
                                )
                            )) : (
                                <MenuItem
                                    leftChildren={
                                        <I
                                            style={{ ...css.icon.squarePlus }}
                                            icon={['fal', 'square-plus']} />
                                    }
                                    size={'medium'}
                                    title={'Adicionar forma de pagamento'}
                                    onClick={() => navigate('/formas-de-pagamento/adicionar')} />
                            )) : (
                        <SkeletonPaymentList />
                    )}
                </Container>
            </Box>
        </Box>
    );
};

const SkeletonPaymentList = () => {
    return (
        <Box>
            <Skeleton height={66} variant='rectangular' />
        </Box>
    );
};
