import { Paper, styled } from '@mui/material';

export const Item = styled(Paper)(({ theme }) => ({
    ...{
        backgroundColor: theme.palette.common.white,
        padding: '0.5rem 0.5625rem',
        textAlign: 'center',
        fontSize: '0.75rem',
        fontWeight: theme.typography.fontWeightSemiBold,
        color: theme.palette.dark,
        textTransform: 'uppercase',
        cursor: 'pointer',
        height: '2.25rem',
        minWidth: '2.125rem',
        whiteSpace: 'nowrap',
        wordBreak: 'keep-all',
        marginRight: '8px',
        marginBottom: '8px',
        boxShadow: 'none',
        border: `1px solid ${theme.palette.clouds}`,
    },
    '&.active': {
        padding: '0.4375rem 0.5rem',
        border: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
        cursor: 'pointer',
        position: 'relative',
        display: 'block',
    },
    '& label > .MuiRadio-root, & label > .MuiCheckbox-root': {
        position: 'absolute',
        left: 0,
        top: -10,
        opacity: 0,
    },
}));
