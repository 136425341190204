import {
    AdCompleteProps,
    AdDataProps,
    CategoriesProps,
    ListStoresProps,
    SizeGroupsListProps,
    UserAddress,
} from '@interfaces/Services';

const RESPONSE = {
    isLoaded: false,
    status: 0,
    message: '',
};

export const DEFAULTS = {
    BYID: {
        LIST: {
            ...RESPONSE,
            data: {} as AdCompleteProps,
        },
    },
    NEW: {
        LIST: {
            ...RESPONSE,
            data: [] as AdDataProps[],
        },
    },
    POPULAR: {
        LIST: {
            ...RESPONSE,
            data: [] as AdDataProps[],
        },
    },
    CATEGORIES: {
        LIST: {
            ...RESPONSE,
            data: [] as CategoriesProps[],
        },
        HISTORY: {
            ...RESPONSE,
            data: [] as CategoriesProps[],
        },
    },
    SIZES: {
        SIZE_GROUPS: {
            ...RESPONSE,
            data: [] as SizeGroupsListProps[],
        },
        SIZE_GROUPS_BY_ID: {
            ...RESPONSE,
            data: [] as SizeGroupsListProps[],
        },
    },
    STORES: {
        LIST: {
            ...RESPONSE,
            data: [] as ListStoresProps[],
        },
    },
    SEARCH_ADS: {
        LIST: {
            ...RESPONSE,
            data: {
                store: {
                    isLoaded: false,
                    address: {
                        data: {} as UserAddress,
                    },
                    bio: '',
                    title: '',
                    url: '',
                    images: {
                        avatar: '',
                        banner: '',
                    },
                },
                ads: [] as AdDataProps[],
            },
        },
    },
};
