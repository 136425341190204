/* eslint-disable max-len */
import { AddressBox, HeaderPage } from '@components';
import { Box, Button, Container, Skeleton, Stack } from '@mui/material';
import { formatAddress, rem } from '@utils';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useUser } from '@hooks/user';
import React, { useState } from 'react';
import { sx } from './sx';

export const List: React.FC = () => {
    const navigate = useNavigate();
    const user = useUser();
    const { css } = sx();

    const [limitList, setLimitList] = useState<number>(4);

    const loadUserAddressList = async () => {
        await user.address.load();
    };

    React.useEffect(() => {
        loadUserAddressList();
    }, []);

    return (
        <Box sx={css.wrapper}>
            <HeaderPage
                noPadding
                onClick={() => navigate(-1)}
                icon={['far', 'map-location-dot']}
                titleAlign={'default'}
                title={'Meus Endereços'}
                action={
                    user.address.list.isLoaded &&
                    (user.address.list.data.length > 0) && (
                        <Button
                            variant='contained'
                            size="small"
                            onClick={() => navigate('/meus-enderecos/adicionar')}>
                            Adicionar
                            <I
                                fontSize={rem(12)}
                                icon={['fas', 'plus']}
                            />
                        </Button>
                    )}
            />
            <Box mt={rem(15)} mb={rem(15)}>
                <Container maxWidth='lg'>
                    {user.address.list.isLoaded ? (
                        user.address.list.data.length > 0 ?
                            <>
                                {user.address.list.data.slice(0, limitList).map((address, i, list) => (
                                    list.length !== i && (
                                        <Box mt={rem(10)} >
                                            <AddressBox
                                                title={address.zipCode}
                                                legend={formatAddress({
                                                    ...address,
                                                })}
                                                mode='edit'
                                                onClick={() => navigate('/meus-enderecos/editar/' + address._id)}
                                                addressTag={`${address.nickname} ${address.main ? ' - Principal' : ''}`}
                                            />
                                        </Box>
                                    )
                                ))}
                                {user.address.list.data.length > 3 &&
                                    <Stack
                                        sx={css.wrapperButton}
                                        justifyContent={'center'} py={2}>
                                        {limitList < user.address.list.data.length ?
                                            <Button
                                                onClick={() => setLimitList(limitList + 4)}
                                                variant='text'>
                                                Mostrar mais
                                            </Button> :
                                            !(user.address.list.data.length <= 4) &&
                                            <Button
                                                onClick={() => setLimitList(4)}
                                                variant='text'>
                                                Mostrar menos
                                            </Button>
                                        }
                                    </Stack>
                                }
                            </> : (
                                <AddressBox
                                    title='Adicionar endereço'
                                    legend='Clique para adicionar um endereço'
                                    mode={'add'}
                                    borderType='dashed'
                                    hideLocationIcon
                                    onClick={() => navigate('/meus-enderecos/adicionar')} />
                            )) : (
                        <SkeletonAddress />
                    )}
                </Container>
            </Box>
        </Box>
    );
};

const SkeletonAddress = () => {
    return (
        <Box>
            <Skeleton height={143} variant='rectangular' />
        </Box>
    );
};
