/* eslint-disable max-len */
import { ButtonSelectedFilter, Template, Image } from '@components';
import { useLayout } from '@hooks/layout';
import { Box, Container } from '@mui/material';
import { rem } from '@utils';
import React from 'react';

const PartnerCategories: React.FC = () => {
    const { layout } = useLayout();

    React.useEffect(() => {
        layout.mobile.header.headerSettings({
            title: 'Categorias do parceiro',
            onBackAction: () => alert('hello!'),
        });
    }, []);

    return (
        <Box pb={rem(20)}>
            <Container maxWidth='lg'>
                <Box pt={rem(15)} pb={rem(10)}>
                    <ButtonSelectedFilter
                        title='Parceiro'
                        value={
                            <Image
                                src='https://carreiras.magazineluiza.com.br/img/magalu-banner.jpg'
                                alt='logo parceiro'
                                maxHeight={21}
                            />
                        } />
                </Box>
            </Container>

            <Template.Categories origin='headerBottomMenu' />
        </Box>
    );
};

export { PartnerCategories };
