import { styled, useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        textField: {
            '& > .MuiInputBase-root': {
                padding: 0,
            },
        },
        icon: {
            adornment: {
                fontSize: rem(16),
                width: rem(46),
                color: palette.darkGray,
            },
        },
    };

    return { css };
};

export const CreditCardFlag = styled('img')`
    max-width: 22px;
    object-fit: contain;
`;
