import React from 'react';
import { Typography, Box, useTheme, Stack } from '@mui/material';
import { rem } from '@utils';
import { sx } from '../../sx';
import { PriceComponentsProps } from '@interfaces/index';
import { sizeDiscountObj, sizeObj } from '../../constants';
import { SizesType } from '@types';


const Points: React.FC<PriceComponentsProps> = (
    { value, discount, size }: PriceComponentsProps,
) => {
    const { typography } = useTheme();
    const { css } = sx();

    return (
        <>
            {discount && (
                <Box sx={css.discount.wrapper}>
                    <Stack direction='row' alignItems='center' spacing={rem(2)}>
                        <Typography
                            component='span'
                            fontSize={sizeDiscountObj[size as SizesType].fontText}
                            color='dark'
                            width={sizeDiscountObj[size as SizesType].widthPoint}
                            fontWeight={typography.fontWeightSemiBold}
                        >
                            De:
                        </Typography>
                        <Stack
                            px={0.5}
                            direction='row'
                            alignItems='flex-end'
                            spacing={rem(2)}
                            position='relative'>
                            <Typography
                                position='relative'
                                component='span'
                                fontSize={sizeDiscountObj[size as SizesType]?.fontSize ??
                                    sizeDiscountObj.medium.fontSize}
                                color='dark'
                                lineHeight={1}
                                fontWeight={typography.fontWeightBold}
                            >
                                {discount}
                            </Typography>
                            <Typography
                                component='span'
                                fontSize={size === 'big' ? '0.625rem' : '8px'}
                                color='dark'
                                fontWeight={typography.fontWeightSemiBold}
                                fontFamily={'Arial'}
                            >
                                Pontos
                            </Typography>
                            <Box sx={css.overline} />
                        </Stack>
                    </Stack>
                </Box>
            )}
            <Stack direction='row' alignItems='center' spacing={rem(2)}>
                {discount && (
                    <Typography
                        component='span'
                        fontSize={sizeDiscountObj[size as SizesType].fontText}
                        width={sizeDiscountObj[size as SizesType].widthPoint}
                        color='dark'
                        fontWeight={typography.fontWeightBold}
                    >
                        Por:
                    </Typography>
                )}
                <Stack direction='row' alignItems='flex-end' spacing={rem(2)}>
                    <Typography
                        position='relative'
                        component='span'
                        fontSize={sizeObj[size as SizesType]?.fontSize ??
                                sizeObj.medium.fontSize}
                        color='dark'
                        lineHeight={1}
                        fontWeight={typography.fontWeightBold}
                    >
                        {value}
                    </Typography>
                    <Typography
                        component='span'
                        fontSize={size === 'big' ? rem(12) : rem(10)}
                        color='dark'
                        fontWeight={typography.fontWeightSemiBold}
                    >
                        Pontos
                    </Typography>
                </Stack>
            </Stack>
        </>
    );
};

export { Points };
