import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { CopyButtonProps } from '@interfaces/index';
import { sx } from './sx';

const CopyButton: React.FC<CopyButtonProps> = ({
    action, active,
}: CopyButtonProps) => {
    const { css } = sx(active);
    return (
        <Button
            className={active ? 'active' : ''}
            sx={{ ...css.sxButton }}
            onClick={action}>
            {active ?
                <>
                    <Typography sx={{ ...css.sxText }}>
                        copiado!
                    </Typography>
                    <I style={{ ...css.sxIconActive }}
                        icon={['far', 'check']} />
                </> :
                <I icon={['far', 'copy']} />
            }
        </Button>
    );
};

export { CopyButton };
