import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        sxBoxContainer: {
            mt: mediaQuery.md ? rem(30) : 0,
            minHeight: rem(545),
        },
        sxLink: {
            display: 'flex',
            padding: mediaQuery.md ? rem(15) : `${rem(15)} 0`,
            textDecoration: 'none',
            marginBottom: rem(10),
        },
        sxTextLink: {
            'fontSize': mediaQuery.md ? rem(15) : rem(14),
            'color': palette.dark,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        sxIconLinkBack: {
            fontSize: rem(18),
            color: palette.dark,
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            fontSize: mediaQuery.md ? rem(28) : rem(18),
            padding: mediaQuery.md ? rem(15) : `${rem(10)} 0`,
        },
        sxBoxAnswer: {
            'padding': mediaQuery.md ? '0.9375rem' : '0.9375rem 0',
        },
        sxAnswerText: {
            'fontSize': '1rem',
            'fontWeight': typography.fontWeightRegular,
            '& > ul': {
                marginLeft: '1.875rem',
            },
            '& > .listdecimal': {
                listStyle: 'decimal',
            },
            '& > .listdisc': {
                listStyle: 'disc',
            },
        },
        sxBoxGrid: {
            display: 'flex',
            marginTop: mediaQuery.md ? '0.625rem' : 0,
        },
    };
    return { css };
};
