
import { MaxTextProps } from '@interfaces/Utils';
import { DEFAULTS } from './constants';

const countWords = (text: string): number => {
    const splitText = text.split(' ');
    const countWords = splitText.length;
    return countWords;
};

const excerpt = (
    { text, excerptMaxTextLength, ellipsis }: MaxTextProps): string => {
    let response: string = '';
    const numWords = excerptMaxTextLength ?? DEFAULTS.EXCERPT_MAX_TEXT;
    const splitText = text.split(' ');
    const countTextWords = splitText.length;
    if (((countTextWords) <= excerptMaxTextLength)) {
        return text;
    }

    if (text) {
        const split = splitText.splice(0, numWords);
        const arr = [...split, ellipsis ? '...' : ''].join(' ');
        return response = arr;
    }
    return response;
};

export { excerpt, countWords };
