import { rem } from '@utils';

export const sx = (borderStyle: 'shadow' | 'bottom' | undefined) => {
    const css = {
        box: {
            padding: `${rem(12)} ${rem(15)}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow: borderStyle !== 'bottom' ? '0px 3px 6px #00000010' : '',
            border: borderStyle !== 'bottom' ? `${rem(1)} solid #ECF0F1` : 'none',
            borderBottom: `${rem(1)} solid #ECF0F1`,
        },
    };
    return { css };
};
