/* eslint-disable max-len */
export const bankAccounts = [
    { _id: 0, value: '001', label: 'Banco do Brasil', ag: '0000-0', cc: '00000000-0', dig_ag: true, dig_cc: true },
    { _id: 1, value: '033', label: 'Santander', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 2, value: '104', label: 'Caixa Econômica', ag: '0000', cc: '00000000000-0', dig_ag: false, dig_cc: true },
    { _id: 3, value: '104', label: 'Caixa Econômica', ag: '0000', cc: '0000000000000-0', dig_ag: false, dig_cc: true },
    { _id: 4, value: '237', label: 'Bradesco', ag: '0000-0', cc: '0000000-0', dig_ag: true, dig_cc: true },
    { _id: 5, value: '237', label: 'Next', ag: '0000-0', cc: '0000000-0', dig_ag: true, dig_cc: true },
    { _id: 6, value: '341', label: 'Itaú', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true },
    { _id: 7, value: '121', label: 'Agibank', ag: '0000', cc: '0000000000', dig_ag: false, dig_cc: false },
    { _id: 8, value: '037', label: 'Banpará', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true },
    { _id: 9, value: '041', label: 'Banrisul', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true },
    { _id: 10, value: '748', label: 'Sicredi', ag: '0000', cc: '0000000', dig_ag: false, dig_cc: false },
    { _id: 11, value: '756', label: 'Sicoob (Bancoob)', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true },
    { _id: 12, value: '077', label: 'Inter', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true },
    { _id: 13, value: '070', label: 'BRB', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true },
    { _id: 14, value: '085', label: 'Via Credi', ag: '0000', cc: '00000000000-0', dig_ag: false, dig_cc: true },
    { _id: 15, value: '655', label: 'Neon', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true },
    { _id: 16, value: '655', label: 'Votorantim', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true },
    { _id: 17, value: '260', label: 'Nubank', ag: '0000', cc: '0000000000-0', dig_ag: false, dig_cc: true },
    { _id: 18, value: '290', label: 'Pagseguro', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 19, value: '212', label: 'Banco Original', ag: '0000', cc: '0000000-0', dig_ag: false, dig_cc: true },
    { _id: 20, value: '422', label: 'Safra', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 21, value: '746', label: 'Modal', ag: '0000', cc: '000000000-0', dig_ag: false, dig_cc: true },
    { _id: 22, value: '021', label: 'Banestes', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 23, value: '136', label: 'Unicred', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 24, value: '274', label: 'Money Plus', ag: '0', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 25, value: '389', label: 'Mercantil do Brasil', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 26, value: '376', label: 'JP Morgan', ag: '0000', cc: '00000000000-0', dig_ag: false, dig_cc: true },
    { _id: 27, value: '364', label: 'Gerencianet Pagamentos do Brasil', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 28, value: '336', label: 'Banco C6', ag: '0000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 29, value: '218', label: 'BS2', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { _id: 30, value: '082', label: 'Banco Topazio', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true },
    { _id: 31, value: '099', label: 'Uniprime', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true },
    { _id: 32, value: '197', label: 'Stone', ag: '0000', cc: '0000000-0', dig_ag: false, dig_cc: true },
    { _id: 33, value: '707', label: 'Banco Daycoval', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { _id: 34, value: '633', label: 'Rendimento', ag: '0000-0', cc: '0000000000', dig_ag: true, dig_cc: false },
    { _id: 35, value: '004', label: 'Banco do Nordeste', ag: '000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { _id: 36, value: '745', label: 'Citibank', ag: '0000', cc: '00000000', dig_ag: false, dig_cc: false },
    { _id: 37, value: '301', label: 'PJBank', ag: '0000', cc: '0000000000-0', dig_ag: false, dig_cc: true },
    { _id: 38, value: '97', label: 'Cooperativa Central de Credito Noroeste Brasileiro', ag: '0000', cc: '0000000-0', dig_ag: false, dig_cc: true },
    { _id: 39, value: '084', label: 'Uniprime Norte do Paraná', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { _id: 40, value: '384', label: 'Global SCM', ag: '0000', cc: '00000000000', dig_ag: false, dig_cc: false },
    { _id: 41, value: '403', label: 'Cora', ag: '0000', cc: '0000000-0', dig_ag: false, dig_cc: true },
    { _id: 42, value: '323', label: 'Mercado Pago', ag: '0000', cc: '0000000000000-0', dig_ag: false, dig_cc: true },
    { _id: 43, value: '003', label: 'Banco da Amazonia', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { _id: 44, value: '752', label: 'BNP Paribas Brasil', ag: '000', cc: '000000-000', dig_ag: false, dig_cc: true },
    { _id: 45, value: '383', label: 'Juno', ag: '0000', cc: '0000000000-0', dig_ag: false, dig_cc: true },
    { _id: 46, value: '133', label: 'Cresol', ag: '0000-0', cc: '00000-0', dig_ag: true, dig_cc: true },
    { _id: 47, value: '173', label: 'BRL Trust DTVM', ag: '000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { _id: 48, value: '047', label: 'Banco Banese', ag: '000', cc: '00000000-0', dig_ag: false, dig_cc: true },
    { _id: 49, value: '208', label: 'Banco BTG Pactual', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true },
    { _id: 50, value: '613', label: 'Banco Omni', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { _id: 51, value: '332', label: 'Acesso Soluções de pagamento', ag: '0000', cc: '00000000', dig_ag: false, dig_cc: false },
    { _id: 52, value: '273', label: 'CCR de São Miguel do Oeste', ag: '0000', cc: '00000', dig_ag: false, dig_cc: false },
    { _id: 53, value: '093', label: 'Polocred', ag: '0000', cc: '000000-0', dig_ag: false, dig_cc: true },
    { _id: 54, value: '355', label: 'Ótimo', ag: '0000', cc: '00000-0', dig_ag: false, dig_cc: true },
];
