import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { MobileSimpleHeader } from '../../../header/mobile/simple';
import { SimpleHeader } from '../../../header';
import { BreakpointsSizesTypes } from '@types';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Footer } from '../../..';
import { simpleHeaderMobile } from '@static/noFooterPaths';

const noFooterPaths = [
    simpleHeaderMobile.userMenu,
    simpleHeaderMobile.unloggedMenu,
];

export function RouterAuthLayoutMobile() {
    const { breakpoints } = useTheme();

    const mediaQuery = (size: BreakpointsSizesTypes) => useMediaQuery(
        `(min-width:${breakpoints.
            values[size as BreakpointsSizesTypes]}px)`,
    );

    return (
        <div className="h-full">
            {mediaQuery('md') && <SimpleHeader />}
            {!mediaQuery('md') && <MobileSimpleHeader />}
            <Box minHeight={mediaQuery('md') ?
                'calc(100vh - 178px)' :
                'calc(100vh - 232px)'}>
                <Outlet />
            </Box>
            <ScrollRestoration />
            {mediaQuery('md') ?
                <Footer.Default /> :
                !(noFooterPaths.find((item) => item === window.location.pathname)) &&
                !window.location.pathname.includes('auth') &&
                <Footer.Mobile />
            }
        </div>
    );
}

export default RouterAuthLayoutMobile;
