import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();

    const css = {
        sizesPanel: {
            wrapper: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                backgroundColor: palette.common.white,
                height: '100%',
            },
        },
    };
    return { css };
};
