import React from 'react';

import {
    Box,
    IconButton,
    Typography,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export interface TopicProps {
    title: string;
    legend: string;
    keyValue: string;
}

import { sx } from './sx';

const Topic: React.FC<TopicProps> = ({
    legend, title, keyValue,
}: TopicProps) => {
    const { css } = sx();
    return (
        <Link to={`/central-de-ajuda/${keyValue}`} style={{
            ...css.sxLink,
        }}>
            <Box sx={{ ...css.sxBox }}>
                <Box sx={{ ...css.sxBoxTexts }}>
                    <Typography
                        sx={{ ...css.sxTitle }}
                        variant='h3'>
                        {title}
                    </Typography>
                    <Typography
                        sx={{ ...css.sxLegend }}
                        variant='body1'>
                        {legend}
                    </Typography>
                </Box>
                <Box
                    sx={{ ...css.sxBoxButton }}>
                    <Typography
                        sx={{ ...css.sxLinkText }}
                        variant='caption'>
                        Saiba mais
                    </Typography>
                    <IconButton sx={{ ...css.sxIconButton }}>
                        <I icon={['far', 'arrow-right-long']} />
                    </IconButton>
                </Box>
            </ Box>
        </Link>
    );
};

export { Topic };
