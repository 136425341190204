import { ContextsProps } from '@interfaces/Contexts';
import { UserContext } from './context';
import { userProvider } from '@hooks/user';

export const UserProvider = ({ children }: ContextsProps) => {
    const providerValue = userProvider();
    return (
        <UserContext.Provider value={providerValue}>
            {children}
        </UserContext.Provider>
    );
};
