import { useTheme } from '@mui/material';
import { PurchaseHistoryStatusType } from '@types';

export const purchaseStatus = (key: PurchaseHistoryStatusType) => {
    const { palette } = useTheme();
    const object = {
        AGUARDANDO_PAGAMENTO: {
            title: 'Aguardando pagamento',
            status: !!(key === 'AGUARDANDO_PAGAMENTO'),
            color: palette.status.pending,
        },
        PAGO: {
            title: 'Pago',
            status: !!(key === 'PAGO'),
            color: palette.status.accepted,
        },
        CANCELADO: {
            title: 'Cancelado',
            status: !!(key === 'CANCELADO'),
            color: palette.status.rejected,
        },
    };
    return object;
};
