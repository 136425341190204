/* eslint-disable max-len */
import React from 'react';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { styles } from '@utils';
import {
    Filters,
    TabPanel,
    HeaderPage,
    Price,
    WithdrawalButton,
    Display,
    Template,
} from '@components';
import { sx } from './sx';
import { useUser } from '@hooks/user';
import { skeleton } from './skeleton';
import { ToggleStaticProps } from '@interfaces/index';
import { WithdrawalRequest } from './sections/withdrawal-request';

function a11yProps(index: number) {
    return {
        'id': 'simple-tab-' + index,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Credits: React.FC = () => {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const skel = skeleton();
    const user = useUser();

    const dialogWithdrawalRequest = React.useRef<ToggleStaticProps>(null);

    const [valueTab, setValueTab] = React.useState(0);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const loadApis = async () => {
        user.wallet.walletValue.load();
    };

    const handleCloseModalWithdrawal = async () => {
        dialogWithdrawalRequest.current?.hide();
        await user.wallet.walletValue.load();
        await user.wallet.movementHistory.load({
            currency: 'MONEY',
            type: valueTab === 0 ? 'CREDIT' : 'DEBIT',
            period: 30,
        });
    };

    React.useEffect(() => {
        loadApis();
    }, []);

    return (
        <Box sx={{ ...css.box.wrapper.container }}>
            <HeaderPage
                noPadding
                icon={['far', 'wallet']}
                titleAlign={'default'} title={'Meus Créditos'} />
            <Box sx={{ ...css.box.wrapper.values }}>
                <Stack
                    direction={mediaQuery.sm ? 'row' : 'column'}
                    spacing={2}
                    alignItems={mediaQuery.sm ? 'center' : 'flex-start'}
                    justifyContent={'space-between'}>
                    <Box width={'100%'}>
                        <Typography sx={{ ...css.texts.balanceTitle }} variant='h3'>
                            Créditos disponíveis
                        </Typography>
                        {user.wallet.walletValue.list.isLoaded ?
                            <Price size='big' type={'SHOPPING'} value={user.wallet.walletValue.list.data.money} /> :
                            skel.value
                        }
                    </Box>
                    <WithdrawalButton
                        disabled={user.wallet.walletValue.list.data.money <= 0}
                        icon={['far', 'arrow-right-arrow-left']}
                        title={'Solicitar reembolso'}
                        subtitle={'Sacar dinheiro'}
                        onClick={() => dialogWithdrawalRequest.current?.show()} />
                </Stack>
            </Box>
            <Box sx={{ ...css.box.info }}>
                {(!user.wallet.movementHistory.list.isLoaded || !user.wallet.movementExpire.list.isLoaded) ?
                    skel.updateText :
                    <Typography sx={{ ...css.texts.info }} variant='body1'>
                        Informações capturadas em {new Date().toLocaleDateString()} às {new Date().toLocaleTimeString()} e sujeitas
                        a alterações até o fim do dia.
                    </Typography>
                }
            </Box>
            <>
                <Box sx={{ ...css.box.wrapper.tabs }}>
                    <Box sx={{ ...css.box.tabs }}>
                        <Tabs
                            value={valueTab}
                            onChange={handleChangeTab}
                            sx={{ ...css.tabs }}>
                            <Tab label="Movimentações " {...a11yProps(0)} />
                            <Tab label="Transferências" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={valueTab} index={0}>
                        <Filters.ExtractPeriod currency='MONEY' />
                        <Display.WalletDetails />
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <Filters.ExtractPeriod currency='MONEY' type='DEBIT' />
                        <Display.WalletDetails />
                    </TabPanel>
                </Box>
            </>
            <Template.MuiDialog
                ref={dialogWithdrawalRequest}
                maxWidth='sm'
                fullScreen={!mediaQuery.sm}>
                <WithdrawalRequest handleClose={() => handleCloseModalWithdrawal()} />
            </Template.MuiDialog>
        </Box>
    );
};

export { Credits };
