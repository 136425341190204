import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxIconButton: (disabled?: boolean) => ({
            padding: rem(10),
            minWidth: rem(46),
            opacity: disabled ? 0.5 : 1,
        }),
        handleIconAction: {
            right: {
                icon: ['far', 'chevron-right'],
                color: palette.darkGray,
            },
            addToCart: {
                icon: ['fal', 'cart-shopping'],
                color: palette.darkGray,
            },
            edit: {
                icon: ['fal', 'pen-to-square'],
                color: palette.darkGray,
            },
            change: {
                icon: ['fal', 'pen-to-square'],
                color: palette.darkGray,
            },
            delete: {
                icon: ['fal', 'trash-can'],
                color: palette.mediumVioletRed,
            },
        },
    };

    return { css };
};
