import { Box, Typography, useTheme } from '@mui/material';
import { OrderStatusType } from '@types';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { purchaseStatusOrder, rem } from '@utils';

interface StatusTagOrderProps {
    status: OrderStatusType;
}

const StatusTagOrder = ({ status }: StatusTagOrderProps) => {
    const { typography, palette } = useTheme();
    const statusOrder = purchaseStatusOrder(status);

    return (
        <Typography
            component='div'
            sx={{
                fontSize: rem(12),
                fontWeight: typography.fontWeightSemiBold,
                color: palette.mediumGray,
                display: 'flex',
            }}>
            <Box mr={typography.pxToRem(4)}>
                <I icon={['fas', 'circle']}
                    color={statusOrder.color}
                    fontSize={10} />
            </Box>
            {statusOrder.title}
        </Typography>
    );
};

export { StatusTagOrder };
