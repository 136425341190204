/* eslint-disable max-len */
import * as React from 'react';
import { Stack, Table, TableBody } from '@mui/material';
import Container from '@mui/material/Container';

import {
    AddressBox,
    AvatarGroup,
    ButtonIcon,
    ControllQuantity,
    MessageBox,
    SearchForm,
    HeaderPage,
    TableList,
    Headline,
    BoxContent,
    CopyButton,
    MenuItem,
} from '@components';

const DesignSystem: React.FC = () => {
    const [activeCopy, setActiveCopy] = React.useState(false);

    return (
        <Container maxWidth="lg">
            <SearchForm />
            <HeaderPage
                icon={['far', 'chevron-left']}
                titleAlign={'default'} title={'Metodo de pagamento'} />
            <ControllQuantity
                value={5}
                limit={0}
                remove={() => null}
                add={() => null} />
            <MessageBox message={'Nada a exibir.'} type={'info'} />
            <AvatarGroup borderWidth='none' align={'bottom'} size={'medium'} title='Amanda Monteiro' src={''} />
            <Table>
                <TableBody>
                    <TableList align={'right'} keyTab={'Nome'} value={'Amanda Monteiro'} />
                    <TableList align={'between'} keyTab={'Nome'} value={'Amanda Monteiro'} />
                    <TableList align={'left'} keyTab={'Nome'} value={'Amanda Monteiro'} />
                </TableBody>
            </Table>
            <Headline size={'medium'} title={'Oportunidades do Sale: Até 70% off para você'} />
            <Headline
                size={'small'}
                title={'Oportunidades do Sale: Até 70% off para você'}
                legend='Compre nossas coleções selecionadas, com tudo escolhido a dedo das melhores marcas independentes do mundo.' />
            <AddressBox
                title='CEP: 85050-030'
                legend='R Bartolomeu bueno da silva, n359 - Bairro ÁguaVerde, Curitiba - Paraná'
                mode={'edit'} />
            <Stack direction={'row'}>
                <ButtonIcon size={'small'} badge={3} active icon={'heart'} />
                <ButtonIcon size={'medium'} badge={9} icon={'heart'} />
            </Stack>
            <Stack>
                <MenuItem size={'small'} title={'Tamanho'} onClick={() => null} />
                <MenuItem size={'tiny'} title={'Homem'} onClick={() => null} />
                <MenuItem size={'tiny'} title={'Mulher'} legend='Mulher / Roupas / Calças' onClick={() => null} />
            </Stack>
            <BoxContent
                actions={[
                    {
                        actionType: 'addToCart',
                        actionFunction: () => null,
                        noLegend: true,
                    },
                    {
                        actionType: 'delete',
                        actionFunction: () => null,
                    },
                ]}>
               Product
            </BoxContent>
            <CopyButton
                active={activeCopy} action={() => {
                    setActiveCopy(!activeCopy);
                }} />
        </Container>
    );
};

export { DesignSystem };
