import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { rem } from '@utils';

interface TitleSectionProps {
    name: string;
}

const TitleSection: React.FC<TitleSectionProps> = ({
    name,
}: TitleSectionProps) => {
    const { typography } = useTheme();
    return (
        <Box sx={{
            p: `${rem(15)}`,
        }}>
            <Typography
                sx={{
                    fontWeight: typography.fontWeightBold,
                    fontSize: rem(14),
                }}
                variant='h3'>
                {name}
            </Typography>
        </Box>
    );
};

export { TitleSection };
