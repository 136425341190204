import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();

    const css = {
        texts: {
            title: {
                fontSize: rem(14),
                fontWeight: 500,
            },
            value: {
                'fontSize': rem(12),
                '& > span': {
                    fontSize: rem(14),
                    fontWeight: typography.fontWeightBold,
                    color: palette.mediumVioletRed,
                },
            },
        },
        switch: {
            '& > .Mui-checked': {
                color: `${palette.primaryColor} !important`,
            },
            '& > .Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#5104E173 !important',
            },
        },
    };

    return { css };
};
