import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();

    const css = {
        sxStack: {
            border: `${rem(1)} solid ${palette.clouds}`,
            borderRadius: rem(4),
            width: rem(132),
        },
        sxDivider: {
            borderColor: palette.clouds,
            height: 'auto',
        },
        sxButton: {
            'borderRadius': '0',
            'width': rem(44),
            'height': rem(34),
            'color': palette.common.black,
            ':disabled': {
                color: palette.clouds,
            },
            'fontSize': rem(14),
        },
        sxValueBox: {
            width: rem(44),
            height: rem(30),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sxNumber: {
            fontWeight: typography.fontWeightBold,
            fontSize: rem(14),
        },
        sxLabel: {
            fontSize: rem(11),
            fontWeight: typography.fontWeightBold,
            mb: rem(5),
        },
    };

    return { css };
};
