import { Box, Button, Stack, Theme, Typography, styled, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { rem } from '@utils';
import { ButtonCurrencyTypeProps } from '@interfaces/index';

const StyledButton = styled(Button)(({ theme }: { theme: Theme }) => ({
    '&.Mui-disabled': {
        backgroundColor: theme.palette.cityLights,
    },
}));

const ButtonCurrencyType: React.FC<ButtonCurrencyTypeProps> = (
    { title, text, disabled, onClick }: ButtonCurrencyTypeProps,
) => {
    const { typography, palette } = useTheme();

    return (
        <StyledButton className='MuiActive'
            type='button' variant='text' onClick={onClick} fullWidth disabled={disabled}>
            <Box pt={rem(20)} pb={rem(20)} pl={rem(20)} pr={rem(20)} width='100%'>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Box>
                        <Typography
                            variant="h1"
                            color="darkGray"
                            component="h1"
                            align='left'
                            textTransform='capitalize'
                            fontSize={rem(18)}
                            fontWeight={typography.fontWeightBold}
                            gutterBottom>
                            {title}
                        </Typography>
                        <Typography
                            variant="body1"
                            component="p"
                            color="mediumGray"
                            fontSize={rem(13)}
                            maxWidth={rem(318)}
                            align='left'
                            textTransform='initial'
                            fontWeight={typography.fontWeightMedium}>
                            {text}
                        </Typography>
                    </Box>
                    <Box width={rem(40)} display='flex' justifyContent='center'>
                        <I
                            icon={['far', 'chevron-right']}
                            fontSize={rem(14)}
                            color={palette.darkGray} />
                    </Box>
                </Stack>
            </Box>
        </StyledButton>
    );
};

export { ButtonCurrencyType };
