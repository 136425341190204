import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        box: {
            wrapper: {
                minHeight: 'calc(100vh - 245px)',
            },
            divider: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
            },
            messageInfo: {
                p: rem(30),
            },
        },
        container: {
            px: mediaQuery.lg ? rem(24) : '0 !important',
        },
    };

    return { css };
};
