import { Box, Grid, Stack, Skeleton, useTheme } from '@mui/material';
import { rem } from '@utils';

export const skeleton = () => {
    const storeInfo = () => {
        const { palette } = useTheme();
        return (
            <Stack direction={'row'} spacing={2} sx={{
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                px: rem(15),
                py: rem(8),
            }}>
                <Skeleton variant='circular' height={38} width={38} />
                <Skeleton variant='text' width={90} />
            </Stack>
        );
    };

    const boxContentProduct = (
        <Box sx={{
            p: '0.9375rem',
            pr: 0,
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            <Box width={'100%'}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <Stack direction={'row'} spacing={1}>
                                <Skeleton variant='rectangular' height={80} width={80} />
                                <Stack spacing={0.6}>
                                    <Skeleton variant='text' width={175} />
                                    <Skeleton
                                        variant='rectangular'
                                        height={24}
                                        width={100} />
                                    <Skeleton variant='text' width={175} />
                                    <Skeleton variant='text' width={130} />
                                    <Skeleton variant='text' width={130} />
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Stack spacing={0.3} p={'0 0.9375rem'}>
                            <Skeleton variant='text' width={70} />
                            <Skeleton variant='rounded' width={132} height={32} />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
                pr={'0.5rem'}>
                <Skeleton variant='circular' height={40} width={40} />
            </Box>
        </Box>
    );

    const resumeCart = (
        <Box>
            <Stack spacing={1}>
                <Skeleton variant='text' />
                <Skeleton variant='text' />
                <Skeleton variant='rounded' height={62} />
                <Skeleton variant='rounded' height={37} />
            </Stack>
        </Box>
    );

    return { storeInfo, boxContentProduct, resumeCart };
};
