import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faSearch,
    faPlus,
    faMinus,
    faChevronRight,
    faChevronLeft,
    faChevronDown as faChevronDownReg,
    faChevronUp,
    faArrowRightLong,
    faHeart,
    faCartShopping,
    faLayerGroup,
    faCopy,
    faCheck,
    faArrowUpArrowDown,
    faListTree,
    faBars,
    faQuestionCircle,
    faRightToBracket,
    faUserCircle,
    faXmark,
    faSquareList,
    faBoxesStacked,
    faEnvelope,
    faArrowLeftLong,
    faCommentLines,
    faCircleMinus,
    faCircleXmark,
    faMemo,
    faClock,
    faHourglass,
    faDollarSign,
    faSliders,
    faBagShopping,
    faStore,
    faMapLocationDot,
    faCircleInfo,
    faCreditCardBlank,
    faBarcode,
    faTicket,
    faTrashCan as trashRegular,
    faWallet as walletRegular,
    faArrowRightArrowLeft,
    faBuildingColumns,
    faTruckFast as truckFastRegular,
    faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faChevronDown,
    faPenToSquare,
    faCircleExclamation,
    faSquarePlus,
    faCartShopping as faCartShoppingLight,
    faTrashCan as faTrashCanLight,
    faUserCircle as faUserCircleSolid,
    faCreditCardFront,
    faShare,
    faTruckFast,
} from '@fortawesome/pro-light-svg-icons';

import {
    faLocationDot,
    faHeart as heartSolid,
    faChevronDown as faChevronDownSolid,
    faChevronUp as faChevronUpSolid,
    faCartShopping as faCartShoppingSolid,
    faPipe,
    faChevronRight as faChevronRightSolid,
    faEye,
    faEyeSlash,
    faCheck as checkSolid,
    faHouse,
    faCircle,
    faCircleXmark as faCircleXmarkSolid,
    faArrowRight,
    faCamera,
    faUser,
    faCalendarStar,
    faLock,
    faCalendarDays,
    faPlus as faPlusSolid,
    faMinus as faMinusSolid,
    faAddressCard,
    faBookmark,
    faCubesStacked,
    faWallet,
    faCheckDouble,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faPix,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    truckFastRegular,
    faShare,
    faMinusSolid,
    faBuildingColumns,
    faArrowRightArrowLeft,
    walletRegular,
    faCubesStacked,
    faWallet,
    faCheckDouble,
    faBookmark,
    trashRegular,
    faBarcode,
    faTicket,
    faPix,
    faCreditCardFront,
    faCreditCardBlank,
    faAddressCard,
    faCircleInfo,
    faMapLocationDot,
    faStore,
    faCalendarDays,
    faUser,
    faLock,
    faCalendarStar,
    faBagShopping,
    faSliders,
    faCamera,
    faArrowRight,
    faDollarSign,
    faHourglass,
    faClock,
    faTruckFast,
    faCircle,
    faMemo,
    checkSolid,
    faCircleMinus,
    faHouse,
    faCircleXmark,
    faCircleXmarkSolid,
    faArrowLeftLong,
    faEnvelope,
    faCommentLines,
    faArrowRightLong,
    faEye,
    faEyeSlash,
    faChevronRightSolid,
    faBoxesStacked,
    faSquareList,
    faXmark,
    faUserCircle,
    faUserCircleSolid,
    faPipe,
    faRightToBracket,
    faQuestionCircle,
    faCheck,
    faCopy,
    faCartShoppingLight,
    faTrashCanLight,
    faChevronLeft,
    faCartShopping,
    faCartShoppingSolid,
    heartSolid,
    faHeart,
    faSearch,
    faChevronDown,
    faChevronDownReg,
    faChevronUp,
    faChevronDownSolid,
    faChevronUpSolid,
    faLocationDot,
    faPlus,
    faPlusSolid,
    faMinus,
    faCircleExclamation,
    faTriangleExclamation,
    faPenToSquare,
    faChevronRight,
    faSquarePlus,
    faLayerGroup,
    faArrowUpArrowDown,
    faListTree,
    faBars,
);
