import { styles } from '@utils';

export const sx = () => {
    const mediaQuery = styles.mediaQueryCSS();
    const css = {
        heightSize: {
            desktop: {
                minHeight: 'calc(100% - 240px)',
                height: 'auto',
            },
            mobile: {
                minHeight: mediaQuery.md ?
                    'calc(100vh - 240px)' :
                    'calc(100vh - 255px)',
                height: 'auto',
            },
        },
    };
    return { css };
};
