/* eslint-disable no-unused-vars */
import React from 'react';
import { client } from '@api';
import { DEFAULTS } from './DEFAULTS';
import { ListAdById, ListHomeAdsProps, SeachAdsProps } from '@interfaces/Services';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';
import { useGlobal } from '@hooks/global';

export const ads = () => {
    const { services } = client();
    const global = useGlobal();
    const applicationType = global.app.view.application.type;
    const [popularAds, setPopularAds] = React.useState(
        DEFAULTS.POPULAR.LIST,
    );
    const [newAds, setNewAds] = React.useState(
        DEFAULTS.NEW.LIST,
    );
    const [listAds, setListAds] = React.useState(
        DEFAULTS.SEARCH_ADS.LIST,
    );
    const [adById, setAdByAd] = React.useState(
        DEFAULTS.BYID.LIST,
    );

    const clearListAds = () => setListAds(DEFAULTS.SEARCH_ADS.LIST);

    const search = async ({
        limit,
        query,
        viewType,
    }: SeachAdsProps): Promise<ResponseAsyncProps> => {
        const objectSend = {
            limit,
            query,
            viewType,
        } as SeachAdsProps;

        if (!(objectSend.limit)) delete (objectSend as any).limit;

        const response = await services.ads.search({
            ...objectSend,
        }) as ResponseAsyncProps;
        if (!response.error) {
            setListAds((prev) => prev = {
                ...prev,
                isLoaded: true,
                message: response.response.message,
                status: response?.response.status,
                data: {
                    store: {
                        isLoaded: !!(response.response?.payload.url),
                        address: {
                            data: response.response?.payload.address ?
                                response.response?.payload.address : {},
                        },
                        bio: response.response?.payload.bio ?? '',
                        images: response.response?.payload.images ?? {},
                        title: response.response?.payload.title ?? '',
                        url: response.response?.payload.url ?? '',
                    },
                    ads: response.response?.payload ?
                        response.response?.payload.ads : DEFAULTS.BYID.LIST.data,
                },
            });
        }
        return response;
    };

    const loadPopularAds = async ({ viewType }: ListHomeAdsProps) => {
        setPopularAds(DEFAULTS.POPULAR.LIST);
        const response = await services.ads.search({
            limit: 8,
            query: 'recent=NUMBER_OF_HITS',
            viewType,
        });
        setPopularAds({
            isLoaded: true,
            message: response?.response.message ?
                response?.response.message :
                '',
            status: response?.response.status,
            data: response?.response?.payload?.ads ?
                response?.response?.payload?.ads : DEFAULTS.POPULAR.LIST.data,
        });
    };

    const loadNewAds = async ({ viewType }: ListHomeAdsProps) => {
        setNewAds(DEFAULTS.NEW.LIST);
        const responseTag = await services.ads.search({
            limit: 12,
            query: 'tag=news',
            viewType,
        });

        if (responseTag?.response?.payload?.ads?.length > 0) {
            setNewAds({
                isLoaded: true,
                message: responseTag?.response.message ?
                    responseTag?.response.message :
                    '',
                status: responseTag?.response.status,
                data: responseTag?.response?.payload?.ads,
            });
        } else {
            const responseFilter = await services.ads.search({
                limit: 12,
                query: 'recent=LAST',
                viewType,
            });

            setNewAds({
                isLoaded: true,
                message: responseFilter?.response.message ?
                    responseFilter?.response.message :
                    '',
                status: responseFilter?.response.status,
                data: responseFilter?.response?.payload?.ads ?
                    responseFilter?.response?.payload?.ads : DEFAULTS.NEW.LIST.data,
            });
        }
    };

    const loadByAd = async ({ idAd }: ListAdById) => {
        setAdByAd(DEFAULTS.BYID.LIST);
        const response = await services.ads.listById({
            idAd,
        });

        if (response) {
            setAdByAd({
                isLoaded: true,
                message: response.response.message,
                status: response?.response.status,
                data: response.response?.payload ?
                    response.response?.payload : DEFAULTS.BYID.LIST.data,
            });
        };
    };

    return {
        ads: {
            search: {
                find: search,
                list: listAds,
                clearList: clearListAds,
            },
            byId: {
                load: loadByAd,
                item: adById,
            },
            popular: {
                load: loadPopularAds,
                list: popularAds,
            },
            news: {
                load: loadNewAds,
                list: newAds,
            },
        },
    };
};
