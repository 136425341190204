import { APIErrorsProps, ResponseErrorsProps } from '@interfaces/Services';

export const responseErrors = (errorsList: ResponseErrorsProps[], keys: string[]) => {
    const filterErrors = errorsList.map((err: any, i: number) => {
        const paramsKeys = err.field.split('.');
        const key = paramsKeys[paramsKeys.length - 1];
        const object = {
            [key]: {
                message: err.message,
                param: err.field,
                error: true,
            },
        };

        if (err.field !== keys[i]) {
            return (object);
        }

        return (object);
    });
    const reduceErrors = filterErrors.reduce<APIErrorsProps>(
        (acc: any, current: any) => ({
            ...acc,
            ...current,
        }),
        {} as APIErrorsProps,
    );
    return reduceErrors;
};
