import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        box: {
            items: {
                display: 'flex',
                alignItems: 'center',
            },
            texts: {
                ml: rem(20),
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
            },
            wrapper: {
                content: {
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                },
                timeline: {
                    'backgroundColor': palette.lightBg,
                    'padding': rem(15),
                    '& .wallet-extract-item:last-child > .divider': {
                        display: 'none',
                    },
                    '& .wallet-extract-item > .last-divider': {
                        display: 'none',
                    },
                },
            },
            icon: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: rem(18),
                height: rem(18),
            },
            dividerV: {
                height: rem(30),
                width: rem(2),
                marginLeft: rem(8),
                backgroundColor: palette.customBlack,
            },
            overline: {
                position: 'absolute',
                height: '1px',
                width: '100%',
                top: 2,
                bottom: 0,
                left: 0,
                margin: 'auto !important',
                backgroundColor: palette.dark,
            },
            price: {
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                px: 0.6,
            },
        },
        texts: {
            title: {
                fontSize: rem(14),
                fontWeight: typography.fontWeightBold,
                color: palette.customBlack,
            },
            legend: {
                fontSize: rem(12),
                fontWeight: typography.fontWeightRegular,
                color: palette.customBlack,
            },
            status: {
                fontSize: rem(10),
                fontWeight: typography.fontWeightRegular,
                color: palette.customBlack,
            },
        },
        icon: {
            fontSize: rem(16),
            color: palette.customBlack,
        },
        iconStatus: {
            fontSize: rem(12),
        },
    };

    return { css };
};
