import React from 'react';
import { rem } from '@utils';
import { useTheme, TableRow, TableCell } from '@mui/material';

interface TableListProps {
    align: 'left' | 'right' | 'between';
    keyTab: JSX.Element | string;
    value?: any;
    customWidthTd?: number;
    noPaddingSide?: boolean;
    children?: React.ReactNode;
}

import { handleFontSize } from './sx';

const TableList: React.FC<TableListProps> = ({
    align,
    keyTab,
    value,
    noPaddingSide,
    customWidthTd,
    children,
}: TableListProps) => {
    const { palette, typography } = useTheme();

    const handleFontWeight = {
        left: {
            keyTab: typography.fontWeightBold,
            value: typography.fontWeightRegular,
        },
        right: {
            keyTab: typography.fontWeightMedium,
            value: typography.fontWeightRegular,
        },
        between: {
            keyTab: typography.fontWeightBold,
            value: typography.fontWeightRegular,
        },
    };

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& > td:first-child': {
                        width:
                            rem(customWidthTd ? customWidthTd : 155),
                    },
                    '&:last-child th': { border: 0 },
                }}>
                <TableCell
                    size='small'
                    sx={{
                        fontSize: handleFontSize[align].keyTab,
                        fontWeight: handleFontWeight[align].keyTab,
                        borderColor: palette.cityLights,
                        padding: `${rem(20)} ${rem(15)}`,
                    }}
                >{keyTab}</TableCell>
                <TableCell
                    align={align !== 'between' ? align : 'right'}
                    sx={{
                        fontSize: handleFontSize[align].value,
                        fontWeight: handleFontWeight[align].value,
                        borderColor: palette.cityLights,
                        padding: align === 'between' ? '0' : rem(15),
                        wordBreak: 'break-word',
                        paddingRight: noPaddingSide ? '0' : rem(15),
                    }}>
                    {value} {children && children}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export { TableList };
