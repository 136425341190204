/* eslint-disable max-len */
import { client } from '@api';
import { FavoritesTypeCallProps, HandleFavoriteActionProps } from '@interfaces/Services';

export const handle = async ({ ...data }: HandleFavoriteActionProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/ad/favorite/addOrRemove', {
            ...data,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const load = async ({ favotireType }: FavoritesTypeCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(`/ad/favorites/type/${favotireType}?limit=100`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};
