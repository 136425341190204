/* eslint-disable max-len */
export const CONSTANTS = {
    SEARCH: {
        LIMIT: 6,
        LIMIT_ON_SCROLL: 6,
    },
    UPLOAD: {
        VIDEO: {
            DURATION_LIMIT: 30, // seconds
        },
    },
};
