import React from 'react';
import { Typography, Box, useTheme, Stack } from '@mui/material';
import { money, rem } from '@utils';
import { sx } from '../../sx';
import { PriceComponentsProps } from '@interfaces/index';
import { SizesType } from '@types';
import { sizeDiscountObj, sizeObj } from '../../constants';


const Money: React.FC<PriceComponentsProps> = (
    { value, discount, size }: PriceComponentsProps,
) => {
    const { typography } = useTheme();
    const { css } = sx();

    return (
        <>
            {discount && (
                <Box sx={css.discount.wrapper}>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <Typography
                            component='span'
                            fontSize={sizeDiscountObj[size as SizesType].fontText}
                            color='dark'
                            width={sizeDiscountObj[size as SizesType].widthText}
                            fontWeight={typography.fontWeightSemiBold}
                        >
                            De:
                        </Typography>
                        <Stack
                            direction='row'
                            px={0.5}
                            spacing={rem(2)}
                            position='relative'>
                            <Typography
                                component='span'
                                fontSize={size === 'big' ? '0.625rem' : '0.5rem'}
                                color='dark'
                                fontWeight={typography.fontWeightSemiBold}
                                fontFamily={'Arial'}
                            >
                                R$
                            </Typography>
                            <Typography
                                position='relative'
                                top={sizeDiscountObj[size as SizesType]?.top}
                                component='span'
                                fontSize={sizeDiscountObj[size as SizesType].fontSize}
                                color='dark'
                                lineHeight={1}
                                fontWeight={typography.fontWeightBold}
                            >
                                {money.formatNumberToPriceBRL(discount)}
                            </Typography>
                            <Box sx={{ ...css.overline }}></Box>
                        </Stack>
                    </Stack>
                </Box>
            )}
            <Stack
                direction='row'
                alignItems='center'
                spacing={1}
                position='relative'
                top={rem(1)}>
                {discount && (
                    <Typography
                        component='span'
                        fontSize={sizeDiscountObj[size as SizesType].fontText}
                        width={sizeDiscountObj[size as SizesType].widthText}
                        color='dark'
                        fontWeight={typography.fontWeightBold}
                    >
                        Por:
                    </Typography>
                )}
                <Stack
                    direction='row'
                    spacing={rem(2)}
                    position='relative'>
                    <Typography
                        component='span'
                        fontSize={size === 'big' ? '0.75rem' : '10px'}
                        color='dark'
                        fontWeight={typography.fontWeightSemiBold}
                        fontFamily={'Arial'}
                    >
                        R$
                    </Typography>
                    <Typography
                        position='relative'
                        top={sizeObj[size as SizesType]?.top ??
                            sizeObj.medium.top}
                        component='span'
                        fontSize={sizeObj[size as SizesType]?.fontSize ??
                            sizeObj.medium.fontSize}
                        color='dark'
                        lineHeight={1}
                        fontWeight={typography.fontWeightBold}
                    >
                        {money.formatNumberToPriceBRL(value)}
                    </Typography>
                </Stack>
            </Stack >
        </>
    );
};

export { Money };
