/* eslint-disable max-len */
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import $ from 'jquery';
import 'jquery-mask-plugin';
import { AddressBox, Feedback, Template } from '@components';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { formatAddress, rem, styles } from '@utils';
import { CreditCardFlag, sx } from './sx';
import React, { useState } from 'react';
import { FormPaymentMethodCreditCard } from '@interfaces/Forms';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CreditCardFlagRegexp, ToggleStaticProps } from '@interfaces/index';
import { useUser } from '@hooks/user';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BillingAddressForm, UserAddress } from '@interfaces/Services';
import { CreditCardFlagType } from '@types';
import { cardFlagsIcon } from '@static/card-flags';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';

interface ErrorsProps {
    [key: string]: {
        error: boolean;
        message: string;
        param: string;
    }
}

interface PaymentFormProps {
    externalAction?: () => void;
    toCheckout?: boolean;
}
export const PaymentForm: React.FC<PaymentFormProps> = ({ externalAction, toCheckout }: PaymentFormProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const param = useParams();
    const user = useUser();
    const [searchParams] = useSearchParams();
    const { typography } = useTheme();
    const { css } = sx();

    const dialogAddAddress = React.useRef<ToggleStaticProps>(null);

    const paramType = {
        type: param.typeAction as 'adicionar' | 'editar',
    };

    // Saber se o formulário está renderizado no checkout ou da listagem
    const [fromList] = useState(window.location.href.includes('formas-de-pagamento'));

    const [open, setOpen] = React.useState(false);
    const [errorAddress, setErrorAddress] = useState(false);
    const [billingAddress, setBillingAddress] = useState<BillingAddressForm | undefined>(
        (location.state && location.state.billingAddress) ? location.state.billingAddress : undefined,
    );
    const [idAddress, setIdAddress] = useState(billingAddress?._id ? billingAddress?._id : !!searchParams.get('addressID') ? searchParams.get('addressID') as string : '');
    const [responseData, setResponseData] = React.useState({
        isLoading: false,
        type: 'success' as AlertColor,
        message: '',
    });
    const [creditCardFlag, setCreditCardFlag] = useState<CreditCardFlagType>('UnknownFlag');
    const [cardErrors, setCardErrors] = React.useState<ErrorsProps>({
        listErrors: {
            error: false,
            message: '',
            param: '',
        },
    });
    const keys = Object.keys(cardErrors);

    const mediaQuery = styles.mediaQueryCSS();

    const { register, handleSubmit, formState: { errors }, setValue, getValues } =
        useForm<FormPaymentMethodCreditCard>({
            mode: 'all',
        });

    const checkCreditCardType = (e: string) => {
        if (e !== undefined && e.length > 3) {
            const cardnumber = e.replace(' ', '').replace(/[^0-9]+/g, '');
            const emptyFields = 16 - cardnumber.length;
            let zeros = '';
            for (let count = 0; count < emptyFields; count++) {
                zeros += '0';
            }

            const cards: CreditCardFlagRegexp = {
                Visa: /\b4\d{3}[ -]*\d{4}[ -]*\d{4}[ -]*\d(?:\d{3})?\b/,
                Mastercard: /\b5[1-5]\d{2}[ -]*\d{4}[ -]*\d{4}[ -]*\d{4}\b/,
                DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                AmericanExpress: /^3[47][0-9]{13}$/,
                Hipercard: /\b(3841|6062)\d{12}\b/,
            };

            for (const flag in cards) {
                if (cards[flag as keyof CreditCardFlagRegexp]
                    .test((cardnumber + zeros))) {
                    return setCreditCardFlag(flag as CreditCardFlagType);
                }
            }

            return setCreditCardFlag('UnknownFlag');
        } else {
            return setCreditCardFlag('UnknownFlag');
        }
    };

    const onSubmit: SubmitHandler<FormPaymentMethodCreditCard> = async (data) => {
        checkCreditCardType(data.cc_number);
        setCardErrors({
            listErrors: {
                error: false,
                message: '',
                param: '',
            },
        });

        if (!(idAddress || billingAddress || searchParams.get('addressID'))) {
            setErrorAddress(true);
            return;
        } else {
            let response = {} as ResponseAsyncProps | undefined;
            setResponseData((prev) => prev = {
                ...prev,
                isLoading: true,
            });
            if (paramType.type === 'editar') {
                response = await user.paymentForm.update({
                    cardId: user.paymentForm.current?.data?._id as string,
                    idAddress: idAddress ?? billingAddress?._id,
                    cardInfos: {
                        billingAddress: billingAddress && billingAddress,
                    },
                });
            } else {
                response = await user.paymentForm.create({
                    cardInfos: {
                        cardToken: '',
                        idAddress: idAddress ?? billingAddress?._id,
                        billingAddress: billingAddress && billingAddress,
                        idKeyCard: '',
                        name: data.cc_name,
                        document: data.cc_cpf,
                        number: data.cc_number,
                        flag: creditCardFlag,
                        cvv: data.cc_cpf,
                        expirationMonth: data.cc_expires_month,
                        expirationYear: data.cc_expires_year,
                    },
                });
            }
            setResponseData({
                isLoading: false,
                type: response?.response.type,
                message: response?.response.message,
            });
            setOpen(true);
            if (response?.response?.validationErros) {
                const filterErrors = response.response.validationErros.
                    map((err: any, i: number) => {
                        const paramsKeys = err.field.split('/');
                        const key = paramsKeys[paramsKeys.length - 1];
                        const object = {
                            [key]: {
                                message: err.message,
                                field: key,
                                error: true,
                            },
                        };

                        if (key !== keys[i]) {
                            return (object);
                        }

                        return (object);
                    });

                const reduceErrors = filterErrors.reduce(
                    (acc: any, current: any) => ({
                        ...acc,
                        ...current,
                    }),
                    {},
                );
                setCardErrors((prev) => prev = reduceErrors);
            }
            if (!response?.error) {
                if (fromList) {
                    user.paymentForm.current.clear();
                    setTimeout(() => {
                        navigate('/formas-de-pagamento');
                    }, 1000);
                };
                if (toCheckout) {
                    await user.paymentForm.load();
                }
                externalAction && externalAction();
            }
        }
    };

    const loadUserAddressList = async () => {
        await user.address.load();
    };

    const findAddressSelected = () => {
        const address = user.address.list.isLoaded &&
            user.address.list.data.length > 0 &&
            user.address.list.data.find((item) => item._id === idAddress);

        if (address) return address;
        if (!!billingAddress) return billingAddress;

        return {} as UserAddress;
    };

    const handleAddAddress = () => {
        const data = getValues();
        user.paymentForm.current.handle({
            cardToken: '',
            cvv: data.cc_cvv,
            document: data.cc_cpf,
            expirationMonth: data.cc_expires_month,
            expirationYear: data.cc_expires_year,
            name: data.cc_name,
            number: data.cc_number,
            idKeyCard: '',
            flag: creditCardFlag,
        });
    };

    const inputMasks = () => {
        $('.number').mask('ZZZZ ZZZZ ZZZZ ZZZZ', {
            translation: {
                'Z': {
                    pattern: /[0-9*]/,
                },
            },
        });
        $('.cpf').mask('000.000.000-00');
        $('.month').mask('00');
        $('.year').mask('0000');
        $('.cvv').mask('ZZZ', {
            translation: {
                'Z': {
                    pattern: /[0-9*]/,
                },
            },
        });
    };

    React.useEffect(() => {
        inputMasks();
        if (billingAddress) {
            if (billingAddress._id) {
                setIdAddress(billingAddress._id);
            } else {
                setIdAddress('');
            }
            setErrorAddress(false);
        }

        if (user.paymentForm?.current?.data?.number) {
            checkCreditCardType(user.paymentForm.current.data.number);
        }
    }, []);

    React.useEffect(() => {
        if (fromList) {
            loadUserAddressList();
        }
    }, [fromList]);

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container maxWidth='md'>
                    <Box px={rem(15)} py={rem(20)}>
                        <Typography
                            variant="body2"
                            component='h1'
                            fontSize={rem(14)}
                            color='darkGray'
                            textAlign={'center'}
                            fontWeight={typography.fontWeightMedium}>
                            {paramType.type === 'editar' ?
                                'Você está visualizando um cartão já cadastrado, não é possível editar as informações' :
                                'Preencha os campos abaixo com os dados do seu cartão de crédito'
                            }
                        </Typography>
                    </Box>
                    <Stack spacing={3} pb={rem(20)}>
                        <TextField
                            fullWidth
                            focused
                            placeholder='0000 0000 0000 0000'
                            label="Número do cartão"
                            id="number"
                            disabled={paramType.type === 'editar'}
                            defaultValue={user.paymentForm.current.data.number}
                            helperText={(!!errors.cc_number?.message &&
                                errors.cc_number.message)}
                            error={!!errors.cc_number}
                            variant="outlined"
                            sx={{ ...css.textField }}
                            inputProps={{
                                className: 'number',
                                onBlur: (e) => {
                                    setValue('cc_number', e.target.value);
                                    checkCreditCardType(e.target.value);
                                },
                            }}
                            InputProps={{
                                readOnly: paramType.type === 'editar',
                                startAdornment: (
                                    <IconButton sx={{ ...css.icon.adornment }}>
                                        <CreditCardFlag
                                            src={cardFlagsIcon[creditCardFlag]}
                                            alt={'credit-card'}
                                            height={16}
                                            /* objectFit='cover'*/ />
                                    </IconButton>
                                ),
                            }}
                            {...register('cc_number',
                                { required: !(paramType.type === 'editar') },
                            )}
                            onChange={(e) => {
                                setValue('cc_number', e.target.value);
                                checkCreditCardType(e.target.value);
                            }}
                            onSelect={(e) => {
                                if (!getValues('cc_number')) {
                                    checkCreditCardType('');
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            focused
                            label="Nome do titular como no cartão"
                            id="name"
                            disabled={paramType.type === 'editar'}
                            defaultValue={user.paymentForm.current.data.name}
                            helperText={(!!errors.cc_name?.message &&
                                    errors.cc_name.message)}
                            error={!!errors.cc_name}
                            sx={{ ...css.textField }}
                            variant="outlined"
                            InputProps={{
                                readOnly: paramType.type === 'editar',
                                startAdornment: (
                                    <IconButton sx={{ ...css.icon.adornment }}>
                                        <I icon={['fas', 'user']} />
                                    </IconButton>
                                ),
                            }}
                            {...register('cc_name',
                                { required: !(paramType.type === 'editar') },
                            )}
                        />
                        <TextField
                            fullWidth
                            focused
                            label='CPF do titular'
                            placeholder={paramType.type === 'editar' ? '***.***.***-**' : '000.000.000-00'}
                            id="cpf"
                            disabled={paramType.type === 'editar'}
                            defaultValue={paramType.type === 'editar' ? '***.***.***-**' : user.paymentForm.current.data.document}
                            helperText={
                                (cardErrors.hasOwnProperty('document') &&
                                    cardErrors.document.message) ||
                                (!!errors.cc_cpf?.message &&
                                    errors.cc_cpf.message)}
                            error={!!errors.cc_cpf}
                            sx={{ ...css.textField }}
                            variant="outlined"
                            inputProps={{
                                className: 'cpf',
                            }}
                            InputProps={{
                                readOnly: paramType.type === 'editar',
                                startAdornment: (
                                    <IconButton sx={{ ...css.icon.adornment }}>
                                        <I icon={['fas', 'address-card']} />
                                    </IconButton>
                                ),
                            }}
                            {...register('cc_cpf',
                                { required: !(paramType.type === 'editar') },
                            )}
                        />
                        <Stack direction={'row'} spacing={3}>
                            <TextField
                                fullWidth
                                focused
                                label="Mês"
                                placeholder='MM'
                                id="month"
                                disabled={paramType.type === 'editar'}
                                defaultValue={user.paymentForm.current.data.expirationMonth}
                                sx={{ ...css.textField }}
                                helperText={(!!errors.cc_expires_month?.message &&
                                    errors.cc_expires_month.message)}
                                error={!!errors.cc_expires_month}
                                variant="outlined"
                                inputProps={{
                                    className: 'month',
                                }}
                                InputProps={{
                                    readOnly: paramType.type === 'editar',
                                    startAdornment: (
                                        <IconButton sx={{ ...css.icon.adornment }}>
                                            <I icon={['fas', 'calendar-star']} />
                                        </IconButton>
                                    ),
                                }}
                                {...register('cc_expires_month',
                                    { required: !(paramType.type === 'editar') },
                                )}
                            />
                            <TextField
                                fullWidth
                                focused
                                label="Ano"
                                placeholder='AAAA'
                                id="year"
                                disabled={paramType.type === 'editar'}
                                defaultValue={user.paymentForm.current.data.expirationYear}
                                sx={{ ...css.textField }}
                                helperText={(!!errors.cc_expires_year?.message &&
                                    errors.cc_expires_year.message)}
                                error={!!errors.cc_expires_year}
                                variant="outlined"
                                inputProps={{
                                    className: 'year',
                                }}
                                InputProps={{
                                    readOnly: paramType.type === 'editar',
                                    startAdornment: (
                                        <IconButton sx={{ ...css.icon.adornment }}>
                                            <I icon={['fas', 'calendar-days']} />
                                        </IconButton>
                                    ),
                                }}
                                {...register('cc_expires_year',
                                    { required: !(paramType.type === 'editar') },
                                )}
                            />
                            <TextField
                                fullWidth
                                focused
                                label="CVV"
                                disabled={paramType.type === 'editar'}
                                placeholder='XXX'
                                id="cvv"
                                defaultValue={user.paymentForm.current.data.cvv}
                                sx={{ ...css.textField }}
                                helperText={(!!errors.cc_cvv?.message &&
                                    errors.cc_cvv.message)}
                                error={!!errors.cc_cvv}
                                variant="outlined"
                                inputProps={{
                                    className: 'cvv',
                                }}
                                InputProps={{
                                    readOnly: paramType.type === 'editar',
                                    startAdornment: (
                                        <IconButton sx={{ ...css.icon.adornment }}>
                                            <I icon={['fas', 'lock']} />
                                        </IconButton>
                                    ),
                                }}
                                {...register('cc_cvv',
                                    { required: !(paramType.type === 'editar') },
                                )}
                            />
                        </Stack>
                        {(fromList) &&
                            (Object.keys(findAddressSelected()).length > 0 ?
                                <AddressBox
                                    title={findAddressSelected().zipCode}
                                    legend={formatAddress({
                                        ...findAddressSelected(),
                                    })}
                                    mode='edit'
                                    onClick={() => dialogAddAddress.current?.show()}
                                    addressTag={findAddressSelected().nickname}
                                /> :
                                <AddressBox
                                    title='Adicionar Endereço de Cobrança'
                                    legend='Clique para adicionar um endereço'
                                    mode={'add'}
                                    borderType={errorAddress ? 'error' : 'dashed'}
                                    onClick={() => dialogAddAddress.current?.show()} />
                            )}
                        <Button
                            type='submit'
                            disabled={responseData.isLoading}
                            variant='contained'>
                            Salvar
                            {responseData.isLoading &&
                                <CircularProgress
                                    color="inherit"
                                    size={14}
                                    sx={{ ml: 1 }}
                                />}
                        </Button>
                    </Stack>
                    {fromList &&
                        <Template.MuiDialog
                            ref={dialogAddAddress}
                            maxWidth='sm'
                            fullScreen={!mediaQuery.md}
                            title='Endereço de cobrança'
                            showDialogTitle>
                            <Container>
                                <Stack
                                    sx={{
                                        maxHeight: mediaQuery.lg ? '620px' : 'auto',
                                    }}
                                    spacing={2} my={2}>
                                    {user.address.list.isLoaded ? (
                                        user.address.list.data.length > 0 &&
                                        user.address.list.data.map((address, i, list) => (
                                            list.length !== i && (
                                                <AddressBox
                                                    key={address._id}
                                                    title={address.zipCode}
                                                    legend={formatAddress({
                                                        ...address,
                                                    })}
                                                    mode='edit'
                                                    noIcon
                                                    radio={{
                                                        checked: (!!(idAddress &&
                                                            idAddress === address._id)),
                                                        id: address._id,
                                                        name: 'selected_address',
                                                        onChange: () => {
                                                            setErrorAddress(false);
                                                            setIdAddress(address._id);
                                                            setBillingAddress(undefined);
                                                        },
                                                    }}
                                                    addressTag={address.nickname}
                                                />
                                            )
                                        ))) : (
                                        'Carregando...'
                                    )}
                                    <Stack pb={2} spacing={1}>
                                        {idAddress &&
                                            <Button
                                                fullWidth
                                                type='button'
                                                variant='contained'
                                                size='large'
                                                onClick={() => {
                                                    dialogAddAddress.current?.hide();
                                                }}>
                                                Salvar
                                            </Button>
                                        }
                                        <Button
                                            fullWidth
                                            type='button'
                                            variant={idAddress ? 'outlined' : 'contained'}
                                            size='large'
                                            onClick={() => {
                                                window.history.replaceState({}, '');
                                                handleAddAddress();
                                                navigate({
                                                    pathname: '/meus-enderecos/adicionar',
                                                    search: '?action=pagamento',
                                                });
                                            }}>
                                            Adicionar um novo endereço
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Container>
                        </Template.MuiDialog>
                    }
                </Container>
            </form>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={() => setOpen(false)}
                message={responseData.message}
            />
        </Box>
    );
};
