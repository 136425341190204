import { useTheme } from '@mui/material';
import { AdType } from '@types';
import { rem, styles } from '@utils';

interface SxProps {
    viewType: AdType;
}

export const sx = ({ viewType }: SxProps) => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        boxContentOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 99,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
        },
        box: {
            wrapper: {
                position: 'relative',
                minHeight: 'calc(100vh - 245px)',
            },
            wrapperStore: {
                border: `${rem(1)} solid ${palette.cityLights}`,
            },
            divider: {
                borderTop: `${rem(1)} solid ${palette.cityLights}`,
                marginBottom: rem(15),
            },
            resumeValues: {
                p: rem(15),
                width: '100%',
                position: mediaQuery.lg ? 'relative' : 'fixed',
                bottom: mediaQuery.lg ? 'auto' : '0',
                backgroundColor: palette.common.white,
                boxShadow: mediaQuery.lg ? 'none' : '0px -4px 6px #00000029;',
            },
            total: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'center',
            },
            quantity: {
                p: mediaQuery.md ? `0 ${rem(15)}` : rem(15),
            },
            continue: {
                mt: 1,
            },
            storeHeader: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                px: rem(15),
                py: rem(8),
            },
        },
        container: {
            px: mediaQuery.lg ? rem(24) : '0 !important',
            pb: 2,
        },
        stack: {
            items: {
                mt: rem(15),
                mb: mediaQuery.lg ? 0 : (viewType === 'SHOPPING' ?
                    rem(236) :
                    rem(332)),
            },
        },
        modal: {
            title: {
                fontWeight: typography.fontWeightBold,
                fontSize: rem(20),
                mb: rem(15),
            },
            titleSection: {
                fontSize: rem(14),
                fontWeight: typography.fontWeightBold,
            },
            noStockText: {
                color: palette.status.rejected,
                fontSize: rem(12),
                fontWeight: typography.fontWeightMedium,
            },
            box: {
                wrapper: {
                    minHeight: rem(465),
                    display: mediaQuery.md ? 'flex' : 'block',
                    height: '100%',
                },
                col1: {
                    minWidth: mediaQuery.md ? '40%' : '100%',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: palette.lightBg,
                    justifyContent: 'flex-start',
                    px: mediaQuery.md ? rem(30) : rem(15),
                    py: mediaQuery.md ? 0 : rem(15),
                },
                itemPicture: {
                    display: mediaQuery.md ? 'block' : 'flex',
                },
                itemTexts: {
                    ml: mediaQuery.md ? 0 : rem(10),
                    mt: rem(10),
                },
                col2: {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    py: rem(30),
                    px: mediaQuery.md ? rem(30) : rem(15),
                },
            },
            image: mediaQuery.md ? 244 : 80,
            iconButton: {
                close: {
                    position: 'absolute',
                    top: rem(5),
                    right: rem(10),
                },
            },
        },
    };

    return { css };
};
