import { OrderProps } from '@interfaces/Services';
import { useUser } from '@hooks/user';
import { Step1 } from './step1';
import { Step2 } from './step2';
import { Step3 } from './step3';
import { Step4 } from './step4';
import { Step5 } from './step5';
import { Step6 } from './step6';

interface DevolutionOrderProps {
    order: OrderProps;
}

const DevolutionOrder = ({ order }: DevolutionOrderProps) => {
    const { purchase } = useUser();

    return (
        <>
            {purchase.devolution.data.activeStep === 1 &&
                <Step1 order={order} />
            }
            {purchase.devolution.data.activeStep === 2 &&
                <Step2 />
            }
            {purchase.devolution.data.activeStep === 3 &&
                <Step3 />
            }
            {purchase.devolution.data.activeStep === 4 &&
                <Step4 />
            }
            {purchase.devolution.data.activeStep === 5 &&
                <Step5 />
            }
            {purchase.devolution.data.activeStep === 6 &&
                <Step6 />
            }
        </>
    );
};

export { DevolutionOrder };
