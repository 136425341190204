import { Button } from '@mui/material';
import { sx } from './sx';

interface ButtonSelectPeriodProps {
    active: boolean,
    title: string;
    onClick: () => void;
};

const ButtonSelectPeriod = ({ active, title, onClick }: ButtonSelectPeriodProps) => {
    const { css } = sx();

    return (
        <Button
            variant="contained"
            size="medium"
            sx={active ?
                {
                    ...css.filters.buttons.default,
                    ...css.filters.buttons.active,
                } :
                css.filters.buttons.default}
            onClick={onClick}
        >
            {title}
        </Button>
    );
};

export { ButtonSelectPeriod };
