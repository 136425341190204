import { Button, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface WithdrawalButtonProps {
    disabled?: boolean;
    icon: IconProp;
    title: string;
    subtitle: string;
    onClick: () => void;
}

const WithdrawalButton: React.FC<WithdrawalButtonProps> = ({
    icon, title, subtitle, disabled, onClick,
}: WithdrawalButtonProps) => {
    const { css } = sx();

    return (
        <Button
            fullWidth
            disabled={disabled}
            onClick={onClick}
            sx={{ ...css.buttonWithdrawal }}>
            <I fontSize={'1.125rem'} icon={icon} />
            <Stack pl={2} alignItems={'flex-start'}>
                <Typography
                    textAlign={'start'}
                    sx={{ ...css.texts.withdrawal.title }}
                    variant='h4'>
                    {title}
                </Typography>
                <Typography
                    sx={{ ...css.texts.withdrawal.subtitle }}
                    variant='body1'>
                    {subtitle}
                </Typography>
            </Stack>
        </Button>
    );
};

export { WithdrawalButton };
