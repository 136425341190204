import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        box: {
            wrapper: {
                border: mediaQuery.lg ?
                    `${rem(1)} solid ${palette.cityLights}` :
                    'none',
            },
            wrapperButton: {
                p: rem(20),
                display: 'flex',
                justifyContent: 'center',
            },
            buttonSubmit: {
                maxWidth: rem(244),
                width: '100%',
            },
        },
        textfield: {
            '& > .MuiOutlinedInput-root': {
                '& > .MuiInputBase-input': {
                    'padding': `${rem(10)} ${rem(20)}`,
                    'fontSize': `${rem(14)}`,
                },
            },
        },
    };

    return { css };
};
