import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const mediaQuery = styles.mediaQueryCSS();
    const { typography } = useTheme();

    const css = {
        buttonWithdrawal: {
            'maxWidth': mediaQuery.sm ? '100%' : '210px',
            'backgroundColor': '#EFEFF3',
            'justifyContent': 'start',
            'px': 2,
            'py': 1.5,
            '&:hover': {
                'backgroundColor': '#EFEFF3 !important',
            },
            ':disabled': {
                'opacity': 0.7,
            },
        },
        texts: {
            withdrawal: {
                title: {
                    fontSize: rem(14),
                    fontWeight: typography.fontWeightBold,
                },
                subtitle: {
                    fontSize: rem(12),
                },
            },
        },
    };

    return { css };
};
