export const handleSetURLParams = (key: string, value: string | undefined) => {
    const urlSearch = new URLSearchParams(window.location.search);

    if (!value) {
        urlSearch.delete(key);
    } else if (urlSearch.get(key)) {
        urlSearch.set(key, value);
    } else {
        urlSearch.append(key, value);
    }

    // eslint-disable-next-line max-len
    const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + urlSearch.toString();
    return window.history.pushState({ path: newurl }, '', newurl);
};

