export const sizeObj = {
    tiny: {
        fontSize: 12,
        top: 2.1,
    },
    small: {
        fontSize: 16,
        top: 1,
    },
    medium: {
        fontSize: 20,
        top: -0.5,
    },
    big: {
        fontSize: 24,
        top: -1,
    },
};

export const sizeDiscountObj = {
    tiny: {
        widthPoint: 28,
        widthText: 22,
        fontText: 10,
        fontSize: 12,
        top: 0,
    },
    small: {
        widthPoint: 28,
        widthText: 22,
        fontText: 12,
        fontSize: 14,
        top: 0,
    },
    medium: {
        widthPoint: 32,
        widthText: 28,
        fontText: 14,
        fontSize: 16,
        top: -0.5,
    },
    big: {
        widthPoint: 36,
        widthText: 32,
        fontText: 16,
        fontSize: 18,
        top: 0,
    },
};


