import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        sxBoxContainer: {
            mt: mediaQuery.md ? rem(30) : 0,
            minHeight: rem(545),
        },
        sxLink: {
            display: 'flex',
            padding: mediaQuery.md ? rem(15) : `${rem(15)} 0`,
            textDecoration: 'none',
            marginBottom: rem(10),
        },
        sxTextLink: {
            'fontSize': mediaQuery.md ? rem(15) : rem(14),
            'color': palette.dark,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        sxIconChevron: {
            marginLeft: rem(30),
        },
        sxIconLinkBack: {
            fontSize: rem(18),
            color: palette.dark,
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            fontSize: mediaQuery.md ? rem(28) : rem(18),
            padding: mediaQuery.md ? rem(15) : `${rem(10)} 0`,
        },
        sxDivider: {
            borderColor: palette.clouds,
        },
        sxBoxQuestion: {
            'padding': rem(15),
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'center',
            'cursor': 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        sxQuestionText: {
            fontSize: mediaQuery.md ? rem(16) : rem(14),
            fontWeight: typography.fontWeightRegular,
        },
        sxLinkQuestion: {
            textDecoration: 'none',
            color: palette.dark,
        },
        sxBoxGrid: {
            display: 'flex',
            marginTop: mediaQuery.md ? rem(10) : 0,
        },
    };
    return { css };
};
