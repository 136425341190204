import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        stack: {
            wrapperMobile: {
                backgroundColor: palette.common.white,
                p: rem(25),
            },
            wrapperDesk: {
                backgroundColor: palette.lightBg,
            },
        },
        link: {
            'textDecoration': 'none',
        },
        texts: {
            link: {
                'color': palette.mediumGray,
                'fontSize': rem(13),
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
            divisor: {
                px: rem(5),
            },
            copyright: {
                'color': palette.mediumGray,
                'fontSize': rem(13),
                'textAlign': 'center',
            },
        },
    };

    return { css };
};
