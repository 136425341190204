import { Box, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import React from 'react';
import { sx } from './sx';

 interface ShippingInfoProps {
    name: string;
    days: number;
 }

const ShippingInfo: React.FC<ShippingInfoProps> = ({
    name, days,
}: ShippingInfoProps) => {
    const { css } = sx();
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            sx={{ ...css.stack.shipping }}>
            <Box>
                <I icon={['fal', 'truck-fast']} />
            </Box>
            <Box>
                <Typography variant='h5' sx={{ ...css.text.shipping.title }}>
                    Entrega via <strong>{name}</strong>
                </Typography>
                <Typography variant='body1' sx={{ ...css.text.shipping.subtitle }}>
                    Receba em até <span>{days} dias</span> úteis
                </Typography>
            </Box>
        </Stack>
    );
};

export { ShippingInfo };
