import {
    Box,
    IconButton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { rem, styles } from '@utils';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import React from 'react';
import { StyledBadge } from './styles';
import { FilterFilterHeadProps } from '@interfaces/index';

const FilterHead: React.FC<FilterFilterHeadProps> = (
    { title, active, activeFilters, onClick }: FilterFilterHeadProps,
) => {
    const mediaQuery = styles.mediaQueryCSS();
    const { typography, palette } = useTheme();

    return (
        <Box>
            <Box pt={rem(6)} pb={rem(6)} onClick={onClick} sx={{
                cursor: 'pointer',
                height: rem(42),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            color="darkGray"
                            fontSize={rem(14)}
                            fontWeight={typography.fontWeightBold}
                        >
                            {title}
                        </Typography>
                        {activeFilters && (
                            <StyledBadge badgeContent={activeFilters} color="info" />
                        )}
                    </Box>
                    {!mediaQuery.md &&
                        <IconButton>
                            <I
                                icon={['fas', !active ? 'chevron-down' : 'chevron-up']}
                                color={palette.primaryColor}
                                fontSize={rem(14)}
                            />
                        </IconButton>
                    }
                </Stack>
            </Box>
        </Box>
    );
};

export { FilterHead };
