import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { typography, palette } = useTheme();
    const css = {
        text: {
            title: {
                fontWeight: typography.fontWeightSemiBold,
                fontSize: rem(16),
            },
            p: {
                fontSize: rem(14),
            },
            mail: {
                fontWeight: typography.fontWeightBold,
                fontSize: rem(14),
                color: palette.mediumVioletRed,
            },
        },
        link: {
            textDecoration: 'none',
        },
    };

    return { css };
};
