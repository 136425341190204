import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BreakpointsSizesTypes } from '@types';

export const mediaQuery = (size: BreakpointsSizesTypes) => {
    const { breakpoints } = useTheme();

    return useMediaQuery(`(min-width:${breakpoints.
        values[size as BreakpointsSizesTypes]}px)`);
};

export const mediaQueryCSS = () => {
    const { breakpoints } = useTheme();
    return {
        xs: useMediaQuery(breakpoints.up('xs')),
        sm: useMediaQuery(breakpoints.up('sm')),
        md: useMediaQuery(breakpoints.up('md')),
        lg: useMediaQuery(breakpoints.up('lg')),
        xl: useMediaQuery(breakpoints.up('xl')),
    };
};
