/* eslint-disable max-len */
import React from 'react';
import { AlertColor, Box, Button, CircularProgress, Container, Grid, Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    ButtonIcon,
    ControllQuantity,
    Feedback,
    Image, MessageBox, Price, VariationItem,
} from '@components';
import { ProductSlider } from './components/product-slider';
import { sx } from './sx';
import { SectionText } from './components/section-text';
import { BreadcrumbsList } from '../../components/display';
import { files, styles } from '@utils';
import { Item } from './styles';
import { useAds } from '@hooks/ads';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectedAdItemProps } from '@interfaces/index';
import { useUser } from '@hooks/user';
import { ProductViewSk } from './product-view-sk';
import { useGlobal } from '@hooks/global';

const ProductView: React.FC = () => {
    const { css } = sx();
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const urlSearch = new URLSearchParams(window.location.search);
    const params = useParams();
    const navigate = useNavigate();

    const { ads } = useAds();
    const global = useGlobal();
    const { cart, favorites } = useUser();

    const applicationType = global.app.view.application;

    const [selectedAdItem, setSelectedAdItem] = React.useState(
        {} as SelectedAdItemProps,
    );
    const [openSnack, setOpenSnack] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoadedFavorite: false,
        isLoadedCart: false,
        isLoadedBuyNow: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const handleClose = () => setOpenSnack(false);

    const handleAction = async (action: 'cart' | 'favorite' | 'buy-now') => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoadedCart: action === 'cart',
            isLoadedFavorite: action === 'favorite',
            isLoadedBuyNow: action === 'buy-now',
        });

        const response = action === 'favorite' ? await favorites.handle({
            idAd: selectedAdItem.idAd,
            idVariation: selectedAdItem.idVariation,
            variationItemId: selectedAdItem.variationItemId,
            typeAd: applicationType.type,
        }) :
            await cart.handleItem.add({
                idAd: selectedAdItem.idAd,
                idVariation: selectedAdItem.idVariation,
                variationItemId: selectedAdItem.variationItemId,
                quantity: selectedAdItem.quantity,
            });
        ;

        if (!response?.error) {
            setResponseData({
                isLoadedFavorite: false,
                isLoadedCart: false,
                isLoadedBuyNow: false,
                type: 'success',
                message: response?.response.message,
            });
            setOpenSnack(true);
            setTimeout(() => {
                handleClose();
            }, 1500);
            if (action === 'favorite') {
                await favorites.load({
                    favotireType: applicationType.type,
                });
            }
            if (action === 'cart') {
                await cart.load({
                    cartType: applicationType.type,
                });
            }
            if (action === 'buy-now') {
                if (cart.list.isLoaded && !(cart.list.data?.stores?.length > 1)) {
                    navigate('/checkout/endereco');
                } else {
                    navigate('/meu-carrinho');
                }
            }
        } else {
            setResponseData({
                isLoadedFavorite: false,
                isLoadedCart: false,
                isLoadedBuyNow: false,
                type: response?.response?.type,
                message: response?.response.message,
            });
            setOpenSnack(true);
            setTimeout(() => {
                handleClose();
            }, 1500);
        }
    };

    React.useEffect(() => {
        if (!(ads.byId.item.isLoaded && ads.byId.item.data._id === params.id)) {
            ads.byId.load({
                idAd: params.id as string,
            });
        };
    }, []);

    React.useEffect(() => {
        if (ads.byId.item.isLoaded && ads.byId.item.data._id) {
            setSelectedAdItem((prev) => prev = {
                ...prev,
                idAd: params.id as string,
                idVariation: urlSearch.get('variation') as string,
                variationItemId: urlSearch.get('option') as string,
                stockQuantity: ads.byId.item.data.variations.find((variation) => variation._id === urlSearch.get('variation') as string)?.
                    items.find((item) => item._id === urlSearch.get('option') as string)?.stockQuantity ?? 1,
                colorNameVariation: ads.byId.item.data.variations.find((variation) => variation._id === urlSearch.get('variation') as string)?.colorName,
                variationItemName: ads.byId.item.data.variations.find((variation) => variation._id === urlSearch.get('variation') as string)?.
                    items.find((item) => item._id === urlSearch.get('option') as string)?.name ?? '',
                quantity: 1,
            });
        }
    }, [ads.byId.item, window.location.href]);

    return (
        <>
            {ads.byId.item.isLoaded && !ads.byId.item.data._id &&
                <Box py={'0.9375rem'}>
                    <MessageBox
                        type={'info'}
                        message='Não foi possível encontrar o anúncio'
                    />
                    <Stack
                        direction='row'
                        spacing={2}
                        justifyContent='center'>
                        <Button
                            type='submit'
                            variant='contained'
                            size='large'
                            onClick={() => navigate('/')}>
                            Voltar para a home
                        </Button>
                    </Stack>
                </Box>
            }
            {!ads.byId.item.data._id && <ProductViewSk />}
            {ads.byId.item.isLoaded && ads.byId.item.data._id &&
                <Container>
                    <Box sx={{ ...css.box.breadcrumbs }}>
                        <BreadcrumbsList
                            type='category'
                            list={ads.byId.item.data.categorizations} />
                    </Box>
                    <Grid container spacing={mediaQuery.md ? 2 : 0} sx={{ ...css.grid.container }}>
                        <Grid item lg={6} sm={12} md={6} xs={12}>
                            {!mediaQuery.md &&
                                <Typography
                                    sx={{ ...css.texts.title }}
                                    variant='h1'>
                                    {ads.byId.item.data.title}
                                </Typography>
                            }
                            <ProductSlider imagesSlider={ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.images ?? ['']}>
                                <Box sx={{ ...css.box.favoriteBtn }}>
                                    <ButtonIcon
                                        onClick={() => handleAction('favorite')}
                                        size='small'
                                        active={
                                            !!(favorites?.list?.data?.favorites?.find((item) =>
                                                item.variation.items._id === selectedAdItem.variationItemId))}
                                        customColor={!!(favorites?.list?.data?.favorites?.find((item) =>
                                            item.variation.items._id === selectedAdItem.variationItemId)) ?
                                            palette.primaryColor : undefined}
                                        icon='heart' />
                                </Box>
                            </ProductSlider>
                        </Grid>
                        <Grid item lg={6} sm={12} md={6} xs={12}>
                            {mediaQuery.md &&
                                <Typography
                                    sx={{ ...css.texts.title }}
                                    variant='h1'>
                                    {ads.byId.item.data.title}
                                </Typography>
                            }
                            <Box sx={{ ...css.box.deliferyInfo }}>
                                <Typography
                                    sx={{ ...css.texts.deliferyInfo }}>
                                    Vendido e entregue por
                                </Typography>
                                <Image
                                    src={files.load(ads.byId.item.data.store.images.avatar, 'server')}
                                    alt={''}
                                    objectFit='contain'
                                    maxHeight={mediaQuery.md ? 32 : 22} />
                            </Box>
                            {global.app.view.application.type === 'SHOPPING' ?
                                <Price
                                    type={'SHOPPING'}
                                    size={'big'}
                                    discount={ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.oldValue}
                                    value={ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.actualValue ?? 0} /> :

                                <Price
                                    type={'REDEMPTION'}
                                    size={'big'}
                                    discount={ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.oldValue}
                                    value={ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.pointValue ?? 0} />
                            }
                            <Stack my={2} spacing={3}>
                                <Stack spacing={1.2}>
                                    <Stack spacing={0.5} direction={'row'}>
                                        <Typography
                                            variant="body2"
                                            sx={{ ...css.texts.titleSection }}>
                                            Variação:
                                        </Typography>
                                        <Typography
                                            variant="body2">
                                            {selectedAdItem.colorNameVariation}
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={2}>
                                        <Stack spacing={1} direction={'row'}>
                                            {ads.byId.item.data.variations.map((variation) => (
                                                <VariationItem
                                                    onClick={() => {
                                                        navigate(`/produto/${ads.byId.item.data._id}?variation=${variation._id}&option=${variation?.items[0]?._id}`);
                                                    }}
                                                    active={variation._id === selectedAdItem.idVariation}
                                                    srcImage={files.load(variation.images[0], 'server')} />
                                            ))}
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack spacing={1.2}>
                                    <Stack spacing={0.5} direction={'row'}>
                                        <Typography
                                            variant="body2"
                                            sx={{ ...css.texts.titleSection }}>
                                            Opções:
                                        </Typography>
                                        <Typography
                                            variant="body2">
                                            {selectedAdItem?.variationItemName}
                                        </Typography>
                                    </Stack>
                                    <Stack direction={'row'} flexWrap={'wrap'}>
                                        {ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.
                                            items.map((item, i) => (
                                                <Item
                                                    key={`item-var-${i}`}
                                                    onClick={() => {
                                                        navigate(`/produto/${ads.byId.item.data._id}?variation=${selectedAdItem.idVariation}&option=${item._id}`);
                                                    }
                                                    }
                                                    className={selectedAdItem.variationItemId === item._id ? 'active' : ''}>
                                                    <label>
                                                        {item.name}
                                                    </label>
                                                </Item>
                                            ))}
                                    </Stack>
                                </Stack>
                                {selectedAdItem.stockQuantity > 0 ?
                                    <Stack mt={'16px !important'} spacing={1}>
                                        <Typography
                                            variant="body2"
                                            sx={{ ...css.texts.titleSection }}>
                                            Quantidade:
                                        </Typography>
                                        <ControllQuantity
                                            value={selectedAdItem.quantity}
                                            limit={selectedAdItem.stockQuantity}
                                            add={() => setSelectedAdItem(
                                                (prev) => prev = {
                                                    ...prev, quantity: prev.quantity + 1,
                                                },
                                            )}
                                            remove={() => setSelectedAdItem(
                                                (prev) => prev = {
                                                    ...prev, quantity: prev.quantity - 1,
                                                },
                                            )}
                                        />
                                    </Stack> :
                                    <Stack mb={2}>
                                        <Typography
                                            sx={{ ...css.texts.noStockText }}
                                            variant='caption'>
                                            <I
                                                style={{ marginRight: '5px' }}
                                                icon={['far', 'circle-info']} />
                                            Item sem estoque no momento
                                        </Typography>
                                    </Stack>
                                }
                            </Stack>
                            <Stack
                                sx={{ ...css.stack.buttons }}
                                spacing={1}>
                                <Button
                                    disabled={responseData.isLoadedBuyNow}
                                    onClick={() => handleAction('buy-now')}
                                    variant='contained'>
                                    Comprar
                                    {responseData.isLoadedBuyNow &&
                                        <CircularProgress
                                            color="inherit"
                                            size={14}
                                            sx={{ ml: 1 }}
                                        />}
                                </Button>
                                <Button
                                    disabled={responseData.isLoadedCart}
                                    sx={{ ...css.button.addToCart }}
                                    onClick={() => handleAction('cart')}
                                    variant='contained'>
                                    Adicionar ao carrinho
                                    {responseData.isLoadedCart &&
                                        <CircularProgress
                                            color="inherit"
                                            size={14}
                                            sx={{ ml: 1 }}
                                        />}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Box sx={{ ...css.box.divider }}></Box>
                    <Grid container>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            {ads.byId.item.data.description &&
                                <SectionText
                                    sectionName={'Descrição'}
                                    value={ads.byId.item.data.description} />
                            }
                            {ads.byId.item.data.about &&
                                <SectionText
                                    sectionName={'Sobre'}
                                    value={ads.byId.item.data.about} />
                            }
                            {ads.byId.item.data.others &&
                                <SectionText
                                    sectionName={'Outras informações'}
                                    value={ads.byId.item.data.others} />
                            }
                        </Grid>
                    </Grid>
                    <Feedback.MuiSnackbar
                        open={openSnack}
                        severity={responseData.type}
                        onClose={handleClose}
                        message={responseData.message}
                    />
                </Container>}
        </>
    );
};

export { ProductView };
