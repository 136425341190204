import { Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { cancelInfo } from '@static/cancelInfo';

const CancelOrder = () => {
    const { palette } = useTheme();
    return (
        <Stack py={2} px={2}>
            <ul>
                {cancelInfo.map((item, i) => (
                    <li key={`info-${i}`}>
                        <Stack mb={3} spacing={1} direction={'row'}>
                            <I
                                color={palette.primaryColor}
                                fontSize={10} icon={['fas', 'circle']} />
                            <Typography
                                fontSize={'0.875rem'}
                                mt={'-0.375rem !important'}>
                                {item}
                            </Typography>
                        </Stack>
                    </li>
                ))}
            </ul>
        </Stack>
    );
};

export { CancelOrder };
