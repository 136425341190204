/* eslint-disable quotes */
import { rem } from '@utils';

const sx = () => {
    const css = {
        header: {
            wrapper: {
                height: rem(90),
            },
        },
    };

    return { css };
};

export { sx };
