/* eslint-disable max-len */
import { CategorizationProps, ListAdById, LoadSizeGroupsByIDProps, LoadSizeGroupsProps, QuicklinksProps, SeachAdsProps, ShowcasesProps } from '@interfaces/Services';
import { client } from '../..';

export const search = async ({
    limit, query, viewType,
}: SeachAdsProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const domain = `/ad/search?${limit ? 'limit=' + limit : ''}&storeType=${viewType}${query ? '&' + query : ''}`;
        const response = await instance.get(domain);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const loadSizeGroups = async ({ categorizations }: LoadSizeGroupsProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/ad/size-groups?categorizations=' + categorizations);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const loadSizeGroupsByID = async ({ sizeGroupId }: LoadSizeGroupsByIDProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(`/ad/size-groups/${sizeGroupId}/sizes`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const listById = async ({ idAd }: ListAdById) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/ad/' + idAd);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const loadStores = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/stores');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const categorization = async ({ _id, direction }: CategorizationProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/ad/categorizations' +
            `${_id ? '?category=' + _id : ''}` +
            `${direction ? '&direction=' + direction : ''}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const quicklinks = async ({ storeType }: QuicklinksProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/quicklinks/' + storeType);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const showcases = async ({ storeType }: ShowcasesProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/showcases/' + storeType);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};
